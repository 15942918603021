import { getSystemAlertsAction } from "../store/alert/actions"
import { useDispatch } from "react-redux";
export const useAlert = () =>
{
    const dispatch = useDispatch();

    const handleGetSystemAlerts = (request)=>{
        return dispatch(getSystemAlertsAction(request));
    };

    return {
        handleGetSystemAlerts
    }   
}
