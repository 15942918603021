const TAG = "FILTER_";
export const SAVE_FILTER = TAG + "SAVE";
export const CLEAR_FILTER = TAG + "CLEAR";
export const ASSESSMENT_FILTER = TAG + "ASSESSMENT";
export const CREATED_ASSESSMENT_FILTER = TAG + "CREATED_ASSESSMENT";
export const PREMADE_ASSESSMENT_FILTER = TAG + "PREMADE_ASSESSMENT";
export const SHARED_ASSESSMENT_FILTER = TAG + "SHARED_ASSESSMENT";
export const ASSIGNMENT_FILTER = TAG + "ASSIGNMENT";
export const BUNDLE_FILTER = TAG + "BUNDLE";
export const ROASTER_CLASS_FILTER = TAG + "CLASS_ROASTER";
export const ROASTER_STUDENT_FILTER = TAG + "STUDENT_ROASTER";
export const ROASTER_TEACHER_FILTER = TAG + "TEACHER_ROASTER";
export const TAG_MANAGER_FILTER = TAG + "TAG_MANAGER";
export const ORG_DISTRICT_FILTER = TAG + "ORG_DISTRICT";
export const ORG_DISTRICT_ADMIN_FILTER = TAG + "ORG_DISTRICT_ADMIN";
export const ORG_SCHOOL_FILTER = TAG + "ORG_SCHOOL";
export const ORG_SCHOOL_ADMIN_FILTER = TAG + "ORG_SCHOOL_ADMIN";
export const LICENSE_FILTER = TAG + "LICENSE";
