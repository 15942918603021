import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import * as IoIcons from "react-icons/io";
import { ReactComponent as NtnLogoWhite } from "../../assets/images/logo/logo-white.svg";
import { FaBell } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import { ImCross } from "react-icons/im";
import {
  NavDropdown,
  Navbar,
  Nav,
  Container,
  OverlayTrigger,
  Popover,
  ListGroup,
  Badge,
  Button,
} from "react-bootstrap";
import { useAuth, useUser } from "../../hooks";
import UserInitials from "./UserInitials";
import { loadSidebar } from "../../store/global";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const sidebar = useSelector((state) => state.global.sidebar);
  const alertsState = useSelector((state) => state.alert);
  const alertTarget = useRef(null);
  const { handleLogout } = useAuth();
  const { userProfileData } = useUser();
  const [badgeCount, setBadgeCount] = useState(null);
  const [alertMessages, setAlertMessages] = useState([]);
  const logoutHandler = () => {
    handleLogout();
  };
  const dispatch = useDispatch();
  const handleSidebar = () => {
    dispatch(loadSidebar());
  };
  useEffect(() => {
    let totalCount = 0;
    let messages = [];
    if (alertsState.data?.alerts) {
      alertsState.data.alerts.map((alerts) => {
        totalCount += alerts.alertCount;
        if (alerts.displayMessage) {
          messages.push(alerts.displayMessage);
        }
      });
      setBadgeCount(totalCount);
      setAlertMessages(messages);
    }
  }, [alertsState.data]);
  const handleDrawerClose = () => {
    if (alertTarget.current) {
      alertTarget.current.ownerDocument.body.click();
    }
  };

  const popover = (
    <Popover id="notification-popover" className="notification-popover">
      <Popover.Title as="h3">
        Notifications
        <Button
          size="sm"
          variant="link"
          className="mobile-only"
          onClick={handleDrawerClose}
        >
          <ImCross></ImCross>
        </Button>
      </Popover.Title>
      <Popover.Content className="p-0">
        <ListGroup as="ol" variant="flush">
          {alertMessages?.map((u, i) => {
            return (
              <ListGroup.Item
                key={i}
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">{u}</div>
              </ListGroup.Item>
            );
          })}
          {alertMessages?.length === 0 ? (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">No notifications found!</div>
            </ListGroup.Item>
          ) : null}
        </ListGroup>
      </Popover.Content>
    </Popover>
  );

  return (
    <Navbar className="header" bg="light" expand={false}>
      <Container fluid>
        <div className="logo-container">
          <Button
            size="sm"
            variant="link"
            className="hamburger-menu-icon mobile-only"
            onClick={handleSidebar}
          >
            {sidebar ? (
              <CgClose />
            ) : (
              <GiHamburgerMenu className="mobile-only" />
            )}
          </Button>
          <Navbar.Brand href="#" className="">
            <NtnLogoWhite className="logo-size" />
          </Navbar.Brand>
        </div>
        <Nav className="justify-content-end">
          <NavDropdown
            className="profile-items"
            title={
              <>
                <UserInitials value={userProfileData?.displayName} />
              </>
            }
          >
            <NavDropdown.Item href="#">Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => logoutHandler()}>
              <i className="fa fa-sign-out"></i> Logout
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link className="desktop-only" href="#">
            <IoIcons.IoIosHelpCircle className="header-icon" />
          </Nav.Link>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={popover}
          >
            <Nav.Link className="desktop-only badge-container" href="#">
              <FaBell className="header-icon" />
              {badgeCount ? (
                <Badge bg="secondary">
                  {badgeCount > 99 ? `99+` : badgeCount}
                </Badge>
              ) : null}
            </Nav.Link>
          </OverlayTrigger>
          <NavDropdown
            className="more-items mobile-only"
            title={<BiDotsVerticalRounded />}
          >
            <OverlayTrigger
              target={alertTarget}
              trigger="click"
              placement="bottom"
              rootClose
              overlay={popover}
            >
              <NavDropdown.Item ref={alertTarget} href="#">
                Notifications
              </NavDropdown.Item>
            </OverlayTrigger>
            <NavDropdown.Item href="#">Help</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default Header;
