import React from "react";
import Spinner from 'react-bootstrap/Spinner'
import Backdrop  from "./Backdrop";
import './loader.scss'
const Loader = ({ isLoading, loaderText="Please wait..." }) => {
  return (
    <Backdrop open={isLoading}>
      <Spinner className="ui-loader-spinner" animation="border" variant="primary"/>
      {loaderText?<div>{loaderText}</div>:null}
    </Backdrop>
  );
};

export default Loader;