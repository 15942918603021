import {
  questionsListAction,
  storeQuestions,
  _removeQuestions,
  _addUpdateSelectedQuestion,
  _clearSelectedQuestionsList,
  _addSelectedQuestion,
  _updateSelectedQuestion,
  _removeSelectedQuestion,
  _clearSelectedQuestions,
  _replaceAllSelectedQuestions,
  _clearQuestionStore,
} from "../store/question/actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
export const useQuestion = () => {
  const questionData = useSelector((state) => state.question);

  const dispatch = useDispatch();

  const [questionsList, setQuestionsList] = useState([]);

  const [selectedQuestions, setSelectedQuestions] = useState(null);

  const handleQuestionsList = async (request) => {
    return await dispatch(questionsListAction(request));
  };

  useEffect(() => {
    if (questionData?.questions) {
      setQuestionsList(questionData.questions);
      setSelectedQuestions(questionData.selectedQuestions);
    }
  }, [questionData]);

  const storeQuestionsToRedux = (question) => {
    dispatch(storeQuestions(question));
  };

  const removeQuestions = (request) => {
    dispatch(_removeQuestions(request));
  };

  const addSelectedQuestion = (request) => {
    dispatch(_addSelectedQuestion(request));
  };

  const updateSelectedQuestion = (request) => {
    dispatch(_updateSelectedQuestion(request));
  };

  const replaceAllSelectedQuestions = (request) => {
    dispatch(_replaceAllSelectedQuestions(request));
  };

  const removeSelectedQuestion = (request) => {
    dispatch(_removeSelectedQuestion(request));
  };

  const addUpdateSelectedQuestion = (request) => {
    dispatch(_addUpdateSelectedQuestion(request));
  };

  const clearSelectedQuestions = () => {
    dispatch(_clearSelectedQuestions());
  };

  const clearQuestionsStore = () => {
    dispatch(_clearQuestionStore());
  };
  return {
    questionsList,
    selectedQuestions,
    addSelectedQuestion,
    updateSelectedQuestion,
    addUpdateSelectedQuestion,
    removeSelectedQuestion,
    clearSelectedQuestions,
    storeQuestionsToRedux,
    handleQuestionsList,
    removeQuestions,
    updateSelectedQuestion,
    replaceAllSelectedQuestions,
    clearQuestionsStore,
  };
};
