import * as types from "./actionTypes";

export const apiLoadingStart = () => ({ type: types.API_LOADING_START });
export const loadSidebar = () => ({ type: types.CONSTANT_SIDEBAR });
export const apiLoadingStop = () => ({ type: types.API_LOADING_STOP });
export const noInternetConnected = (isConnected) => ({
  type: types.IS_INTERNET_CONNECTED,
  payload: isConnected,
});

////contant
export const contantApiLoadingStart = () => ({
  type: types.CONSTANT_API_LOADING_START,
});
export const contantApiLoadingStop = () => ({
  type: types.CONSTANT_API_LOADING_STOP,
});

export const imageDialogAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.CONSTANT_IMAGE_DIALOG,
      payload: request,
    });
  };
};
