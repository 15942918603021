import HttpMethod from "./HttpMethod";

class HttpApis {
  login(params) {
    return HttpMethod._post("auth/login", params);
  }

  getAccount() {
    return HttpMethod._get("account");
  }

  updateAccount(params) {
    return HttpMethod._put("account", params);
  }

  deleteAccount(params) {
    return HttpMethod._delete("account", params);
  }

  checkAccountSecurity() {
    return HttpMethod._get("Security");
  }

  getAssessment(params) {
    return HttpMethod._post("Assignment/AssignedList", params);
  }

  async getAssessmentFilter(params) {
    return await HttpMethod._post("Assignment/FilterLists", params);
  }
  async getAssignmentDetail(params) {
    return await HttpMethod._post(`Assignment/AssignedDetails`, params);
  }
  async saveDueDate(params) {
    return HttpMethod._post("Assignment/SaveDueDate", params);
  }
  async deleteAssignment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Assessment/Delete?${queryString}`);
  }
  async unassignAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Assessment/UnAssign?${queryString}`);
  }
  async saveReassignAssignment(params) {
    return HttpMethod._post("Assignment/Reassign", params);
  }
  getPremadeAssessment(params) {
    return HttpMethod._post("Assessment/PremadeList", params);
  }

  async getPremadeAssessmentFilter(params) {
    return await HttpMethod._post("Assessment/PremadeFilters", params);
  }

  getMyAssessment(params) {
    return HttpMethod._post("Assessment/ByMeList", params);
  }

  async getMyAssessmentFilter(params) {
    return await HttpMethod._post("Assessment/ByMeFilters", params);
  }

  async getShareTeachers(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Assessment/GetShareTeachers?${queryString}`);
  }

  async saveShareTeachers(params) {
    return await HttpMethod._post("Assessment/SaveShareTeachers", params);
  }

  async getShareList(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Assessment/SharedList?${queryString}`);
  }

  async acceptAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Assessment/ShareAccepted?${queryString}`);
  }

  async getAssessmentDetail(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Assessment/Assessment?${queryString}`);
  }
  async AssignModalDetails(params) {
    return await HttpMethod._post(`Assignment/AssignFilters`, params);
  }
  async Assign(params) {
    return await HttpMethod._post(`Assignment/Assign`, params);
  }
  async addNewStudents(params) {
    console.log(params);
    return await HttpMethod._post(`Assignment/AddNewStudents`, params);
  }
  //GET api
  async deleteSharedAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Assessment/ShareDelete?${queryString}`);
  }

  //POST api
  async questionFiltersList(params) {
    return await HttpMethod._post(`Question/FilterLists`, params);
  }

  //POST api
  async questionsList(params) {
    return await HttpMethod._post(`Question/QuestionList`, params);
  }

  async createAssessment(params) {
    return await HttpMethod._post(`Assessment/Assessment`, params);
  }

  async deleteAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Assessment/Delete?${queryString}`);
  }

  async cancelAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Assessment/Cancel?${queryString}`);
  }

  async getAssignmentEvaluation(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Assignment/GetEvaluateStudentAssignment?${queryString}`
    );
  }

  async saveAssignmentEvaluation(params) {
    return await HttpMethod._post(
      `Assignment/EvaluateStudentAssignment`,
      params
    );
  }

  async getStudentAssignedAssessments(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Assignment/GetStudentAssignedAssessments?${queryString}`,
      params
    );
  }
  async getStudentSubmittedAssignments(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Assignment/GetStudentSubmittedAssignments?${queryString}`,
      params
    );
  }
  async getStudentGradedAssessments(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Assignment/GetStudentGradeddAssessments?${queryString}`,
      params
    );
  }
  async saveStudentAssessment(params) {
    return await HttpMethod._post(`Assignment/SaveStudentAssessment`, params);
  }

  async uploadStudentAssessmentAttachment(params) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await HttpMethod._post(
      `Assignment/UploadFileToBlob`,
      params,
      headers
    );
  }

  async deleteStudentAssessmentAttachment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Assignment/DeleteBlobFile?${queryString}`);
  }

  async getStudentAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Assignment/GetStudentAssessment?${queryString}`
    );
  }

  async systemNotification() {
    return await HttpMethod._get(`System/Alerts`);
  }

  async getReportEmbedToken(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`System/GetReportEmbedToken?${queryString}`);
  }
  async getReportIdEmbedToken(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`System/GetReportIdEmbedToken?${queryString}`);
  }
  async GetAssessmentShareTeachers(params) {
    return await HttpMethod._post(
      `Assessment/GetAssessmentShareTeachers`,
      params
    );
  }

  async getFilterDefaults(params) {
    return await HttpMethod._post(`Report/GetFilterDefaults`, params);
  }

  //api/Report/GetQuestionsHtml
  async getQuestionsHtml(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Report/GetQuestionsHtml?${queryString}`);
  }

  // Rosters

  async getSAClassListFilter(params) {
    return await HttpMethod._post("Roster/MyClassFilters", params);
  }

  async getRosterClassListFilter(params) {
    return await HttpMethod._post("Roster/ClassListStudentTransfer", params);
  }

  async rosterTransferStudent(params) {
    return await HttpMethod._post("Roster/TransferStudent", params);
  }

  async getRosterClassList(params) {
    return await HttpMethod._post(`Roster/MyClasses`, params);
  }
  async getRosterDeleteClass(params) {
    return await HttpMethod._post(`Roster/DeleteClass`, params);
  }

  async getRosterSaveClass(params) {
    return await HttpMethod._post(`Roster/SaveClass`, params);
  }

  async getRosterStudentList(params) {
    return await HttpMethod._post(`Roster/MyStudents`, params);
  }
  // Roster student
  async rosterDeleteStudent(params) {
    return await HttpMethod._post(`Roster/DeleteStudent`, params);
  }

  async rosterDeactivateStudent(params) {
    return await HttpMethod._post(`Roster/DeactivateStudent`, params);
  }

  async rosterReactivateStudent(params) {
    return await HttpMethod._post(`Roster/ReactivateStudent`, params);
  }

  //BULKUPLOADS

  async rosterBulkUploadClass(params) {
    return await HttpMethod._post(`Roster/BulkUploadClasses`, params);
  }

  async rosterBulkUploadTeacher(params) {
    return await HttpMethod._post(`Roster/BulkUploadTeachers`, params);
  }

  async rosterBulkUploadStudent(params) {
    return await HttpMethod._post(`Roster/BulkUploadStudents`, params);
  }

  // reports

  async getReportSchoolList() {
    return await HttpMethod._get(`Report/SchoolOptions`);
  }

  async getReportGradeList(params) {
    return await HttpMethod._post(`Report/GradeOptions`, params);
  }

  async getReportTeachersList(params) {
    return await HttpMethod._post(`Report/TeacherOptions`, params);
  }
  async getReportClassList(params) {
    return await HttpMethod._post(`Report/ClassOptions`, params);
  }

  async getReportClassGroupOptionsList(params) {
    return await HttpMethod._post(`Report/ClassGroupOptions`, params);
  }

  async getReportAssessmentList(params) {
    return await HttpMethod._post(`Report/AssessmentOptions`, params);
  }

  async getReportStrandList(params) {
    return await HttpMethod._post(`Report/StrandOptions`, params);
  }

  async getReportAssignmentTypeList(params) {
    return await HttpMethod._get(`Report/AssignmentTypes`, params);
  }

  async getReportStandardList(params) {
    return await HttpMethod._post(`Report/StandardOptions`, params);
  }

  async getReportAssessmentTypesList(params) {
    return await HttpMethod._get(`Report/AssessmentTypes`, params);
  }

  async getReportStudentList(params) {
    return await HttpMethod._post(`Report/StudentOptions`, params);
  }
  async getTeachersList(params) {
    return await HttpMethod._post(`Roster/TeacherList`, params);
  }
  async reAssignStudentClassList(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Roster/GetStudentByClassId?${queryString}`);
  }
  async getClassesAndGrades(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Roster/GetClassAndGradeBySchool?${queryString}`
    );
  }
  async getTeacherTransferDetails(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Roster/TeacherTransferDetails?${queryString}`
    );
  }
  async getTeacherById(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Roster/GetTeacherById?${queryString}`);
  }
  async getStudentById(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Roster/GetStudentById?${queryString}`);
  }

  async saveStudent(params) {
    return await HttpMethod._post(`Roster/AddStudent`, params);
  }

  async saveTeacher(params) {
    return await HttpMethod._post(`Roster/SaveTeacherRole`, params);
  }
  async updateTeacher(params) {
    return await HttpMethod._post(`Roster/UpdateTeacherRole`, params);
  }
  async updateStudent(params) {
    return await HttpMethod._post(`Roster/UpdateStudent`, params);
  }
  async getReportClassGroupList(params) {
    return await HttpMethod._post(`Report/ClassGroupOptions`, params);
  }
  async transferTeacher(params) {
    return await HttpMethod._post(`Roster/TransferTeacher`, params);
  }
  async deleteTeacher(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Roster/DeleteTeacher?${queryString}`);
  }
  async deactivateTeacher(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Roster/DeactivateTeacher?${queryString}`);
  }
  async reactivateTeacher(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Roster/ReactivateTeacher?${queryString}`);
  }
  async reAssignStudentToClass(params) {
    return await HttpMethod._post(`Roster/ReassignStudentToNewClass`, params);
  }
  async getQuestionsHTML(params) {
    return await HttpMethod._get(`Report/GetQuestionsHTML?${params}`);
  }

  // ROASTER BULK UPLOADS
  // tag manager
  async tagFilterList(params) {
    return await HttpMethod._post(`Tag/FilterLists`, params);
  }

  async tagQuestionList(params) {
    return await HttpMethod._post(`Tag/QuestionList`, params);
  }

  async tagPreviewEditQuestionDetail(params) {
    return await HttpMethod._post(`Tag/PreviewEditQuestionDetail`, params);
  }

  async tagGetEditQuestionDetail(params) {
    return await HttpMethod._post(`Tag/GetEditQuestionDetail`, params);
  }

  async tagEditQuestion(params) {
    return await HttpMethod._post(`Tag/EditQuestion`, params);
  }

  async tagCopyQuestion(params) {
    return await HttpMethod._post(`Tag/CopyQuestion`, params);
  }
  async tagCreateQuestion(params) {
    return await HttpMethod._post(`Tag/CreateQuestion`, params);
  }
  async tagDeleteQuestion(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Tag/DeleteQuestion?${queryString}`);
  }

  // assessments
  async tagAssessmentFilterLists(params) {
    return await HttpMethod._post(`Tag/AssessmentFilterLists`, params);
  }

  async tagAssessmentList(params) {
    return await HttpMethod._post(`Tag/AssessmentList`, params);
  }

  async tagSaveAssessment(params) {
    return await HttpMethod._post(`Tag/Assessment`, params);
  }

  async tagEditAssessment(params) {
    return await HttpMethod._post(`Tag/EditAssessmentSubmit`, params);
  }

  async tagCopyAssessment(params) {
    return await HttpMethod._post(`Tag/CopyAssessment`, params);
  }

  async tagGetAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Tag/Assessment?${queryString}`);
  }

  async tagDeleteAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Tag/DeleteAssessment?${queryString}`);
  }

  async tagPublishAssessment(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Tag/PublishAssessment?${queryString}`);
  }
  async tagAssessmentGrades(params) {
    return await HttpMethod._post(`Tag/GradeOptions`, params);
  }

  async tagRefreshSpeechText(params) {
    return await HttpMethod._post(`Tag/RefreshSpeechText`, params);
  }
  // bundle Api
  async bundleFilterList(params) {
    return await HttpMethod._post(`Bundle/BundleFilterLists`, params);
  }

  async listBundle(params) {
    return await HttpMethod._post(`Bundle/BundleList`, params);
  }

  async editBundle(params) {
    return await HttpMethod._post(`Bundle/Bundle`, params);
  }

  async deleteBundle(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Bundle/Delete?${queryString}`);
  }

  // /api/Bundle/Bundle
  async createBundle(params) {
    return await HttpMethod._post(`Bundle/Bundle`, params);
  }

  async createBundleFilters(params) {
    return await HttpMethod._post(`Bundle/CreateBundleFilters`, params);
  }

  async getBundle(params) {
    return await HttpMethod._post(`Bundle/BundleDetail`, params);
  }
  // /api/Bundle/BundleAssessmentList
  async getBundleAssessmentList(params) {
    return await HttpMethod._post(`Bundle/BundleAssessmentList`, params);
  }
  // /api/Bundle/BundleQuestionList
  async getBundleQuestionList(params) {
    return await HttpMethod._post(`Bundle/BundleQuestionList`, params);
  }
  //cancel
  async cancelBundle(params) {
    return await HttpMethod._post(`Bundle/Cancel`, params);
  }

  // Liscense

  async getLicenseFilterList(params) {
    return await HttpMethod._post(`License/FilterLists`, params);
  }

  async getLicenseList(params) {
    return await HttpMethod._post(`License/LicenseList`, params);
  }

  async createLicense(params) {
    return await HttpMethod._post(`License/CreateLicense`, params);
  }

  async editLicense(params) {
    return await HttpMethod._post(`License/EditLicense`, params);
  }

  async deleteLicense(params) {
    return await HttpMethod._post(`License/Delete?licenseId=${params}`);
  }

  async districtSchoolFilter(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Shared/DistrictAndSchoolList?${queryString}`);
  }

  async schoolAndSchoolAdminList(params) {
    return await HttpMethod._post(`Shared/SchoolAndSchoolAdminList`, params);
  }

  async getStateList(params) {
    return await HttpMethod._get(`Shared/GetStateList?createLicense=${params}`);
  }

  async licenseDetails(params) {
    return await HttpMethod._get(
      `License/GetLicenseDetail?licenseId=${params}`
    );
  }

  // organizations
  // /api/Organization/FilterLists
  async organizationFilterList(params) {
    return await HttpMethod._post(`Organization/FilterLists`, params);
  }

  // /api/Organization/CreateDistrict
  async organizationCreateDistrict(params) {
    return await HttpMethod._post(`Organization/CreateDistrict`, params);
  }

  // /api/Organization/EditDistrict
  async organizationEditDistrict(params) {
    return await HttpMethod._post(`Organization/EditDistrict`, params);
  }

  ///api/Organization/GetDistrictDetail
  async organizationGetDistrictDetail(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Organization/GetDistrictDetail?${queryString}`
    );
  }

  // /api/Organization/DistrictList
  async organizationDistrictList(params) {
    return await HttpMethod._post(`Organization/DistrictList`, params);
  }

  // /api/Organization/DeleteDistrict
  async organizationDeleteDistrict(params) {
    return await HttpMethod._post(`Organization/DeleteDistrict`, params);
  }

  // /api/Organization/DeactivateDistrict
  async organizationDeactivateDistrict(params) {
    return await HttpMethod._post(`Organization/DeactivateDistrict`, params);
  }

  // /api/Organization/ReactivateDistrict
  async organizationReactivateDistrict(params) {
    return await HttpMethod._post(`Organization/ReactivateDistrict`, params);
  }

  // /api/Organization/CreateDistrictAdministrator
  async organizationCreateDistrictAdministrator(params) {
    return await HttpMethod._post(
      `Organization/CreateDistrictAdministrator`,
      params
    );
  }

  // /api/Organization/EditDistrictAdministrator
  async organizationEditDistrictAdministrator(params) {
    return await HttpMethod._post(
      `Organization/EditDistrictAdministrator`,
      params
    );
  }

  // /api/Organization/DeleteDistrictAdmin
  async organizationDeleteDistrictAdmin(params) {
    return await HttpMethod._post(`Organization/DeleteDistrictAdmin`, params);
  }

  // /api/Organization/DeactivateDistrictAdmin
  async organizationDeactivateDistrictAdmin(params) {
    return await HttpMethod._post(
      `Organization/DeactivateDistrictAdmin`,
      params
    );
  }

  // /api/Organization/DistrictAdminList
  async organizationDistrictAdminList(params) {
    return await HttpMethod._post(`Organization/DistrictAdminList`, params);
  }

  // /api/Organization/DeleteSchool
  async orgDeleteSchool(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Organization/DeleteSchool?${queryString}`);
  }

  // /api/Organization/DeactivateSchool
  async orgDeactivateSchool(params) {
    return await HttpMethod._post(`Organization/DeactivateSchool`, params);
  }

  // /api/Organization/ReactivateSchool
  async orgReactivateSchool(params) {
    return await HttpMethod._post(`Organization/ReactivateSchool`, params);
  }

  // /api/Shared/GetDistrictList
  async sharedGetDistrictList(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Shared/GetDistrictList?${queryString}`);
  }

  // /api/Shared/GetDistrictList
  async sharedGetStateList(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Shared/GetStateList?${queryString}`);
  }

  ///api/Organization/CreateDistrictAdministrator
  async orgCreateDistrictAdministrator(params) {
    return await HttpMethod._post(
      `Organization/CreateDistrictAdministrator`,
      params
    );
  }

  ///api/Organization/EditDistrictAdministrator
  async orgEditDistrictAdministrator(params) {
    return await HttpMethod._post(
      `Organization/EditDistrictAdministrator`,
      params
    );
  }

  ///api/Organization/DeleteDistrictAdmin
  async orgDeleteDistrictAdmin(params) {
    return await HttpMethod._post(`Organization/DeleteDistrictAdmin`, params);
  }

  ///api/Organization/DeactivateDistrictAdmin
  async orgDeactivateDistrictAdmin(params) {
    return await HttpMethod._post(
      `Organization/DeactivateDistrictAdmin`,
      params
    );
  }

  ///api/Organization/ReactivateDistrictAdmin
  async orgReactivateDistrictAdmin(params) {
    return await HttpMethod._post(
      `Organization/ReactivateDistrictAdmin`,
      params
    );
  }

  ///api/Organization/SchoolList
  async orgSchoolList(params) {
    return await HttpMethod._post(`Organization/SchoolList`, params);
  }

  // /api/Organization/CreateSchool
  async orgCreateSchool(params) {
    return await HttpMethod._post(`Organization/CreateSchool`, params);
  }

  // /api/Organization/UpdateSchool
  async orgUpdateSchool(params) {
    return await HttpMethod._post(`Organization/UpdateSchool`, params);
  }

  // /api/Organization/DeactivateSchool
  async orgDeactivateSchool(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(
      `Organization/DeactivateSchool?${queryString}`
    );
  }

  // /api/Organization/ReactivateSchool
  async orgReactivateSchool(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(
      `Organization/ReactivateSchool?${queryString}`,
      params
    );
  }

  // /api/Organization/DeleteSchoolAdmin
  async orgDeleteSchoolAdmin(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(
      `Organization/DeleteSchoolAdmin?${queryString}`
    );
  }

  // /api/Organization/CreateSchoolAdmin
  async orgCreateSchoolAdmin(params) {
    return await HttpMethod._post(`Organization/CreateSchoolAdmin`, params);
  }

  // /api/Organization/UpdateSchoolAdmin
  async orgUpdateSchoolAdmin(params) {
    return await HttpMethod._post(`Organization/EditSchoolAdmin`, params);
  }

  // /api/Organization/DeactivateSchoolAdmin
  async orgDeactivateSchoolAdmin(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(
      `Organization/DeactivateSchoolAdmin?${queryString}`
    );
  }

  // /api/Organization/ReactivateSchoolAdmin
  async orgReactivateSchoolAdmin(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(
      `Organization/ReactivateSchoolAdmin?${queryString}`,
      params
    );
  }

  // /api/Organization/SchoolAdminList
  async orgSchoolAdminList(params) {
    return await HttpMethod._post(`Organization/SchoolAdminList`, params);
  }

  // /api/Organization/GetParentDistrict
  async orgGetParentDistrict(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Organization/GetParentDistrict?${queryString}`
    );
  }

  // /api/Shared/GetAccountType
  async orgGetAccountType(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Shared/GetAccountType?${queryString}`);
  }

  // /api/Shared/GetStateStandards
  async orgGetStateStandards(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Shared/GetStateStandards?${queryString}`);
  }

  ///api/Shared/GetSchoolByDistrict
  async orgGetSchoolByDistrict(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Shared/GetSchoolByDistrict?${queryString}`);
  }

  // GetStateStandards?stateCode=IL
  // /api/Organization/GetDistrictAdminDetail
  async orgGetDistrictAdminDetail(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Organization/GetDistrictAdminDetail?${queryString}`
    );
  }

  // /api/Organization/GetSchoolAdminDetail
  async orgGetSchoolAdminDetail(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Organization/GetSchoolAdminDetail?${queryString}`
    );
  }

  // /api/Organization/GetSchoolDetail
  async orgGetSchoolDetail(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Organization/GetSchoolDetail?${queryString}`);
  }

  // print-assessment
  ///api/Assessment/DocumentFilters
  async documentFilters(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._post(`Assessment/DocumentFilters?${queryString}`);
  }

  ///api/Assessment/GetDocument
  async getDocument(params) {
    const headers = {
      responseType: "blob",
    };
    return await HttpMethod._post(`Assessment/GetDocument`, params, headers);
  }

  ///api/Assessment/SubmitAnswerImage
  async submitAnswerImage(params) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await HttpMethod._post(
      `Assessment/SubmitAnswerImage`,
      params,
      headers
    );
  }
  // /api/Bundle/CheckBundleTitleExists
  async checkBundleTitleExists(params) {
    return await HttpMethod._post(`Bundle/CheckBundleTitleExists`, params);
  }

  // SaveImageFromBase64
  async saveImageFromBase64(params) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await HttpMethod._post(
      `Assignment/SaveImageFromBase64`,
      params,
      headers
    );
  }

  ///api/Assignment/AutoGrade
  async AutoGrade(params) {
    return await HttpMethod._post(`Assignment/AutoGrade`, params);
  }

  // /api/Report/ExportReport
  async downloadReport(params) {
    const headers = {
      responseType: "blob",
    };
    return await HttpMethod._post(`Report/ExportReport`, params, headers);
  }

  async systemSettings(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`System/Setting?${queryString}`);
  }

  //api/System/SaveUserSettings
  async saveUserSettings(params) {
    return await HttpMethod._post(`System/SaveUserSettings`, params);
  }
  ///api/System/GetSpeechVoices
  async getSpeechVoices() {
    return await HttpMethod._get(`System/GetSpeechVoices`);
  }

  async getCoppa(coppaId) {
    return await HttpMethod._get(`Coppa/${coppaId}`);
  }

  async submitCoppa(params) {
    return await HttpMethod._post(`Coppa/Submit`, params);
  }
  // create Fake Assessment
  async createFakeAssessment(params) {
    return await HttpMethod._post(`Assessment/AssessmentFake`, params);
  }
  // get student Fake Assignment
  async studentFakeAssignment(params) {
    return await HttpMethod._post(
      `Assignment/GetStudentAssessmentFake`,
      params
    );
  }

  // Archive
  async getArchiveHistory() {
    return await HttpMethod._get(`Archive/GetArchiveHistory`);
  }

  async archiveData() {
    return await HttpMethod._post(`Archive/ArchiveData`);
  }

  async setupSchoolYear() {
    return await HttpMethod._post(`Archive/SetupSchoolYear`);
  }

  // Assessment Reownership
  async getOwnerAssessments(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(
      `Assessment/GetOwnerAssessments?${queryString}`
    );
  }

  async updateOwnerAssessments(params) {
    return await HttpMethod._post(`Assessment/UpdateOwnerAssessments`, params);
  }

  // Student Promotion
  async getPromoteStudents(params) {
    const queryString = new URLSearchParams(params).toString();
    return await HttpMethod._get(`Roster/GetPromoteStudents?${queryString}`);
  }

  async savePromoteStudents(params) {
    return await HttpMethod._post(`Roster/SavePromoteStudents`, params);
  }

  async deletePromoteStudents(params) {
    return await HttpMethod._post(`Roster/DeletePromoteStudents`, params);
  }

  async getMediaList(params) {
    return await HttpMethod._post(`Media/MediaList`, params);
  }
}

export default new HttpApis();
