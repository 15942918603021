import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageDialogAction } from "../store/global";
export const useCommon = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.global.loading);
  const globalState = useSelector((state) => state.global);
  const assessmentLoading = useSelector((state) => state.assessment.loading);
  const imageDialog = useSelector((state) => state.global.imageDialog);
  const isPageLoading = isLoading || assessmentLoading;

  const useNTNDebounce = (value, delay) => {
    // State and setters for debounced value
    const [ntndebouncedValue, setNTNDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setNTNDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return ntndebouncedValue;
  };

  const scrollToTop = (ref) => {
    if (ref) {
      ref.current.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleArraySort = (params) => {
    let { items, column, direction } = params;
    switch (direction) {
      case "DESC":
        // items = items?.sort((a, b) =>
        //   a[column].toLowerCase() < b[column].toLowerCase() ? 1 : -1
        // );
        items = items
          .sort((a, b) => a[column].localeCompare(b[column]))
          .reverse();
        break;
      case "ASC":
        items = items.sort((a, b) => a[column].localeCompare(b[column]));
        // items = items?.sort((a, b) =>
        //   a[column].toLowerCase() > b[column].toLowerCase() ? 1 : -1
        // );
        break;
      default:
        console.log("i m default");
        break;
    }
    return items;
  };

  const formatString = (source, params) => {
    params.forEach((i, n) => {
      source = source.replace(new RegExp("\\{" + i + "\\}", "g"), n);
    });
    return source;
  };

  const handleImageDialog = (request) => {
    return dispatch(imageDialogAction(request));
  };

  return {
    isPageLoading,
    useNTNDebounce,
    scrollToTop,
    handleArraySort,
    formatString,
    imageDialog,
    handleImageDialog,
  };
};
