import * as types from "./actionTypes";
const initialState = {
  loading: false,
  assessmentData: null,
  createAssessment: {},
  assessmentList: [],
};
const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ASSESSMENT_SUCCESS:
      let assessmentList = state.assessmentList
        ? [...state.assessmentList]
        : [];
      action.payload.forEach((element, index) => {
        let selectedIndex = assessmentList.findIndex(
          (q) => q.id === element.id
        );
        if (selectedIndex === -1) {
          assessmentList.push(element);
        } else {
          assessmentList.splice(selectedIndex, 1, element);
        }
      });

      return {
        ...state,
        assessmentList: assessmentList,
        loading: false,
      };
    case types.GET_ASSESSMENT_DETAIL:
      let _assessmentList = state.assessmentList
        ? [...state.assessmentList]
        : [];

      let selectedIndex = _assessmentList.findIndex(
        (q) => q.id === action.payload.id
      );
      if (selectedIndex === -1) {
        _assessmentList.push(action.payload);
      } else {
        _assessmentList.splice(selectedIndex, 1, action.payload);
      }

      return {
        ...state,
        assessmentList: _assessmentList,
        loading: false,
      };
    case types.GET_ASSESSMENT_BY_ID:
      const assessmentById = state.assessmentList?.filter(
        (a) => a.id == action.id
      );
      if (assessmentById && assessmentById.length) {
        return {
          ...assessmentById[0],
        };
      } else {
        return null;
      }
    case types.CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        createAssessment: action.payload,
      };
    case types.REMOVE_CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        createAssessment: {},
        loading: false,
      };
    case types.CREATE_ASSESSMENT_REQUEST_STEP:
      return {
        ...state,
        createAssessment: { ...state.createAssessment, step: action.payload },
      };
    case types.GET_ASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case types.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case types.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default assessmentReducer;
