import * as types from "./actionTypes";
const initialState = {
  assessment: null,
  createdAssessment: null,
  premadeAssessment: null,
  sharedAssessment: null,
  assignment: null,
  bundle: null,
  license: null,
  roasterClass: null,
  roasterStudent: null,
  roasterTeacher: null,
  orgDistrict: null,
  orgDistrictAdmin: null,
  orgSchool: null,
  orgSchoolAdmin: null,
  tagManager: null,
};
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ASSESSMENT_FILTER:
      return {
        ...state,
        assessment: action.payload,
      };

    case types.CREATED_ASSESSMENT_FILTER:
      return {
        ...state,
        createdAssessment: action.payload,
      };

    case types.PREMADE_ASSESSMENT_FILTER:
      return {
        ...state,
        premadeAssessment: action.payload,
      };

    case types.SHARED_ASSESSMENT_FILTER:
      return {
        ...state,
        sharedAssessment: action.payload,
      };
    case types.ASSIGNMENT_FILTER:
      return {
        ...state,
        assignment: action.payload,
      };
    case types.BUNDLE_FILTER:
      return {
        ...state,
        bundle: action.payload,
      };
    case types.LICENSE_FILTER:
      return {
        ...state,
        license: action.payload,
      };
    case types.ROASTER_CLASS_FILTER:
      return {
        ...state,
        roasterClass: action.payload,
      };
    case types.ROASTER_STUDENT_FILTER:
      return {
        ...state,
        roasterStudent: action.payload,
      };
    case types.ROASTER_TEACHER_FILTER:
      return {
        ...state,
        roasterTeacher: action.payload,
      };
    case types.ORG_DISTRICT_FILTER:
      return {
        ...state,
        orgDistrict: action.payload,
      };
    case types.ORG_DISTRICT_ADMIN_FILTER:
      return {
        ...state,
        orgDistrictAdmin: action.payload,
      };
    case types.ORG_SCHOOL_FILTER:
      return {
        ...state,
        orgSchool: action.payload,
      };
    case types.ORG_SCHOOL_ADMIN_FILTER:
      return {
        ...state,
        orgSchoolAdmin: action.payload,
      };
    case types.TAG_MANAGER_FILTER:
      return {
        ...state,
        tagManager: action.payload,
      };

    case types.CLEAR_FILTER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default filterReducer;
