import React, { useEffect } from "react";
import { Modal, Image, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useCommon } from "../../hooks";
import "./image-dialog.scss";
import { MdClose } from "react-icons/md";
const ImageDialogModel = (props) => {
  const { handleImageDialog } = useCommon();
  const handleCancle = () => {
    handleImageDialog(null);
  };
  useEffect(() => {
    return () => {
      handleImageDialog(null);
    };
  }, []);
  return (
    <Modal
      show
      size="lg"
      className="image-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => handleCancle()}
    >
      <Modal.Body>
        <Button
          className="close-btn-absolute-right"
          size="sm"
          variant="link"
          onClick={() => handleCancle()}
        >
          <MdClose />
        </Button>
        <Row>
          <Col className="align-center-center p-6 larger-image-container">
            <input type="checkbox" id="zoomCheck" />
            <label htmlFor="zoomCheck">
              <Image src={props?.url}></Image>
            </label>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ImageDialogModel.propTypes = {
  url: PropTypes.string.isRequired,
};
export default ImageDialogModel;
