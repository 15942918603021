import { ComponentUrls } from "./component-names";

export const componentRoutes = {
  welcome: "/",
  dashboard: `/${ComponentUrls.dashboard}`,
  logIn: `/${ComponentUrls.login}`,
  invalidLogin: `/${ComponentUrls.invalidLogin}`,
  coppaApproval: `${ComponentUrls.coppaApproval}`,
  // signUp: `/${ComponentUrls.signUp}`,
  // msalSignin: `/${ComponentUrls.msalSignin}`,
};

export const commonComponents = [
  {
    path: componentRoutes.welcome,
    header: false,
    public: true,
  },
  {
    path: componentRoutes.logIn,
    header: false,
    public: true,
  },
  {
    path: componentRoutes.invalidLogin,
    header: false,
    public: true,
  },
  {
    path: componentRoutes.coppaApproval,
    header: true,
    public: true,
  },
];
