import {
  getSystemSettingAction,
  saveUserSettingAction,
  getSpeechVoicesAction,
} from "../store/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
export const useSettings = () => {
  const settingsData = useSelector((state) => state.settings);
  const [systemSettings, setSystemSettings] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [speechVoices, setSpeechVoices] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setUserSettings(settingsData?.user);
    setSystemSettings(settingsData?.setting);
    setSpeechVoices(settingsData?.speechVoices);
  }, [settingsData]);

  const getSystemSetting = async (request) => {
    return await dispatch(getSystemSettingAction(request));
  };

  const getSpeechVoices = async (request) => {
    return await dispatch(getSpeechVoicesAction(request));
  };

  const saveUserSetting = async (request) => {
    return await dispatch(saveUserSettingAction(request));
  };

  const getUserSetting = (key) => {
    return userSettings ? userSettings[key] : null;
  };

  return {
    getSystemSetting,
    saveUserSetting,
    userSettings,
    systemSettings,
    getUserSetting,
    getSpeechVoices,
    speechVoices,
  };
};
