export const ntnUserRoles = {
  TEACHER: "Teacher",
  STUDENT: "Student",
  SCHOOL_ADMIN: "School Admin",
  DISTRICT_ADMIN: "District Admin",
  NTN_ADMIN: "NTN Admin",
};

export const role = [
  { id: "1", role: ntnUserRoles.STUDENT },
  { id: "2", role: ntnUserRoles.TEACHER },
  { id: "3", role: ntnUserRoles.SCHOOL_ADMIN },
  { id: "4", role: ntnUserRoles.DISTRICT_ADMIN },
  { id: "5", role: ntnUserRoles.NTN_ADMIN },
];
