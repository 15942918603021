import { httpStatus } from "../constants";

export const errorMessages = {
  invalidRequestDetails:
    "Sorry. we couldn't find the property information in our database. The error is reported and we are working to resolve the issue as soon as possible.",
  networkError:
    "Sorry. It's not you, something is wrong on our side. We are working to resolve the issue as soon as possible.",
  notFound:
    "Sorry. we couldn't find the property information in our database. The error is reported and we are working to resolve the issue as soon as possible.",
  unAuthorized: "User is not authorized to get this data.",
  requestTimeout: "Request has taken too much time to get data.",
  serviceUnavailable: "Service is not available for requested data.",
  credentialsNotFound: "Incorrect username or password. Retry.",
};

export const getErrorMessage = (status) => {
  switch (status) {
    case httpStatus.BAD_REQUEST:
      return errorMessages.invalidRequestDetails;

    case httpStatus.UNAUTHORIZED:
      return errorMessages.unAuthorized;

    case httpStatus.NOT_FOUND:
      return errorMessages.notFound;

    case httpStatus.REQUEST_TIMEOUT:
      return errorMessages.requestTimeout;

    case httpStatus.INTERNAL_SERVER_ERROR:
      return errorMessages.networkError;

    case httpStatus.SERVICE_UNAVAILABLE:
      return errorMessages.serviceUnavailable;

    case httpStatus.CREDENTIAL_NOTFOUND:
      return errorMessages.credentialsNotFound;

    default:
      console.error("message status", status);
      break;
  }
};
export default getErrorMessage;
