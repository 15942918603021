import { useState, useEffect } from "react";
import { useUser } from "./user-hooks";
export const useAssessmentPermission = (assessment, state) => {
  const { userId } = useUser();
  const [canCreate, setCanCreate] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [canCopy, setCanCopy] = useState(null);
  const [canDelete, setCanDelete] = useState(null);
  useEffect(() => {
    if (assessment) {
      setCanCreate(userId === assessment.ownerUserId);

      let _canEdit =
        userId === assessment.ownerUserId &&
        assessment.isAssessmentAttempted === false;

      setCanEdit(_canEdit);

      let _canDelete =
        (userId === assessment.ownerUserId &&
          assessment.isAssessmentAttempted === false) ||
        state?.dataType === "shared-with-me";

      setCanDelete(_canDelete);

      const _canCopy = userId === assessment.ownerUserId;
      setCanCopy(_canCopy);
    }
  }, [assessment, userId]);

  return {
    canCreate,
    canEdit,
    canDelete,
    canCopy,
  };
};
