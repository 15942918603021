import { useDispatch } from "react-redux";
import { getLessons } from "../store/pdOnDemand/actions";
import { useState } from "react";
export const usePdOnDemand = () => {
  const dispatch = useDispatch();
  const [request, setRequest] = useState({ grades: [], standards: [] });
  const [media, setMedia] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [lessons, setLessons] = useState([]);

  const handleGetLessons = (payload) => {
    return dispatch(
      getLessons({ gradeList: payload.grades, standardList: payload.standards })
    );
  };
  const handleSetRequest = (payload) => {
    return setRequest(payload);
  };
  const handleCardClick = (data) => {
    setMedia(data);
    if (data.isVideo || data.fileType == "pptx" || data.fileType == "ppt") {
      handleShow();
    } else {
      window.open(data.pathFileUrl);
    }
  };

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const handleLesonCallback = async (data) => {
    if (
      data.grades &&
      data.standards &&
      data.grades.length > 0 &&
      data.standards.length > 0
    ) {
      const response = await handleGetLessons(data);
      setLessons(response.data?.lessonLists);
    } else {
      setLessons([]);
    }
  };
  return {
    handleGetLessons,
    request,
    handleSetRequest,
    handleCardClick,
    handleShow,
    handleClose,
    media,
    modalShow,
    handleLesonCallback,
    lessons,
  };
};
