const TAG = "GLOB_";
export const API_LOADING_START = TAG + "API_LOADING_START";
export const API_LOADING_STOP = TAG + "API_LOADING_STOP";
export const IS_INTERNET_CONNECTED = TAG + "IS_INTERNET_CONNECTED";
export const ON_ERROR_RECEIVED = TAG + "ON_ERROR_RECEIVED";

export const CONSTANT_API_LOADING_START = TAG + "CONSTANT_API_LOADING_START";
export const CONSTANT_API_LOADING_STOP = TAG + "CONSTANT_API_LOADING_STOP";

export const CONFIG_SUCCESS = TAG + "CONFIG_SUCCESS";
export const CONFIG_ERROR = TAG + "CONFIG_ERROR";
export const SET_CURRENT_ROUTE_NAME = "SET_CURRENT_ROUTE_NAME";
export const CONSTANT_SIDEBAR = TAG + "CONSTANT_SIDEBAR";
export const CONSTANT_IMAGE_DIALOG = TAG + "CONSTANT_IMAGE_DIALOG";
