import * as types from "./actionTypes";
const initialState = {
  loading: false,
  assigment: null,
  assigmentList: [],
  gridType: "grid",
};
const assignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ASSIGNMENT_SUCCESS:
      let assignmentList = state.assignmentList
        ? [...state.assignmentList]
        : [];
      action.payload.forEach((element, index) => {
        let selectedIndex = assignmentList.findIndex(
          (q) => q.id === element.id
        );
        if (selectedIndex === -1) {
          assignmentList.push(element);
        } else {
          assignmentList.splice(selectedIndex, 1, element);
        }
      });

      return {
        ...state,
        assignmentList: assignmentList,
        loading: false,
      };
    case types.GET_ASSIGNMENT_DETAIL:
      let _assignmentList = state.assignmentList
        ? [...state.assignmentList]
        : [];

      let selectedIndex = _assignmentList.findIndex(
        (q) => q.id === action.payload.id
      );
      if (selectedIndex === -1) {
        _assignmentList.push(action.payload);
      } else {
        _assignmentList.splice(selectedIndex, 1, action.payload);
      }

      return {
        ...state,
        assignmentList: _assignmentList,
        loading: false,
      };
    case types.GET_ASSIGNMENT_BY_ID:
      const assignmentById = state.assignmentList?.filter(
        (a) => a.id == action.id
      );
      if (assignmentById && assignmentById.length) {
        return {
          ...assignmentById[0],
        };
      } else {
        return null;
      }
    case types.GET_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case types.SET_ASSIGNMENT_GRID_TYPE:
      return {
        ...state,
        gridType: action.payload,
      };
    case types.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case types.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default assignmentReducer;
