import React from "react";
const Backdrop = ({children, open }) => {
    return (<>
    {open?
    <div className="ui-loader backdrop">
        <div className="ui-loader-container">
            {children}   
        </div>
    </div>:null}</>
    );
  };
  
  export default Backdrop;