//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const rosterClassListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getRosterClassList(request)
      .then((response) => {
        dispatch({
          type: types.SET_CLASS_LIST,
          payload: response.data?.classes,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterStudentListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getRosterStudentList(request)
      .then((response) => {
        dispatch({
          type: types.SET_STUDENT_LIST,
          payload: response.data?.studentClass,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterClassFilterAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getSAClassListFilter(request)
      .then((response) => {
        dispatch({
          type: types.SET_SCHOOL_LIST,
          payload: response.data?.schoolList,
        });

        dispatch({
          type: types.SET_DISTRICT_LIST,
          payload: response.data?.districtList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterClassListFilterAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getRosterClassListFilter(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterTransferStudentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterTransferStudent(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterGetStudentByIdAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getStudentById(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterDeleteClassAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getRosterDeleteClass(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterSaveClassAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getRosterSaveClass(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

//Student roster

export const rosterDeleteStudentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterDeleteStudent(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterDeactivateStudentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterDeactivateStudent(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const rosterReactivateStudentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterReactivateStudent(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

// export const rosterFilterClassAction = (request) => {
//   return (dispatch) => {
//     dispatch({
//       type: types.SET_FILTER_LIST,
//       payload: request,
//     });
//   };
// };

// export const setRosterFilterDataAction = (request) => {
//   return (dispatch) => {
//     dispatch({
//       type: types.SET_ROSTER_FILTER,
//       payload: request,
//     });
//   };
// };

export const rosterClassBulkUploadAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterBulkUploadClass(request)
      .then((response) => {
        dispatch({
          type: types.SET_CLASS_BULK_UPLOAD,
          payload: response.data?.classes,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return error;
      });
  };
};

export const rosterTeacherBulkUploadAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterBulkUploadTeacher(request)
      .then((response) => {
        dispatch({
          type: types.SET_TEACHER_BULK_UPLOAD,
          payload: response.data?.teachers,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return error;
      });
  };
};

export const rosterStudentBulkUploadAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.rosterBulkUploadStudent(request)
      .then((response) => {
        dispatch({
          type: types.SET_STUDENT_BULK_UPLOAD,
          payload: response.data,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return error;
      });
  };
};
