import * as types from "./actionTypes";
const initialState = {
  loading: false,
  studentList: null,
  classList: null,
  schoolList: null,
  teacherList: null,
  districtList: null,
  bulkUploadClass: null,
  bulkUploadTeacher: null,
  bulkUploadStudent: null,
};
const rosterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STUDENT_LIST:
      return {
        ...state,
        studentList: action.payload,
        loading: false,
      };
    case types.SET_CLASS_LIST:
      return {
        ...state,
        classList: action.payload,
        loading: false,
      };
    case types.SET_SCHOOL_LIST:
      return {
        ...state,
        schoolList: action.payload,
        loading: false,
      };
    case types.SET_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload,
        loading: false,
      };
    case types.SET_TEACHER_LIST:
      return {
        ...state,
        teacherList: action.payload,
        loading: false,
      };
    case types.SET_CLASS_BULK_UPLOAD:
      return {
        ...state,
        bulkUploadClass: action.payload,
        loading: false,
      };
    case types.SET_TEACHER_BULK_UPLOAD:
      return {
        ...state,
        bulkUploadTeacher: action.payload,
        loading: false,
      };
    case types.SET_STUDENT_BULK_UPLOAD:
      return {
        ...state,
        bulkUploadStudent: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default rosterReducer;
