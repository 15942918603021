import React, { useEffect, useState } from "react";
export default function SliderInput(props) {
  //   const [playbackRate, setPlaybackRate] = useState(
  //     props?.defaultValue ? props?.defaultValue : 1
  //   );
  //   useEffect(() => {
  //     const nnn = setTimeout(() => {
  //       console.log("playbackRate", playbackRate);
  //       props?.onChange(playbackRate);
  //     }, 500);
  //     return () => clearTimeout(nnn);
  //   }, [playbackRate]);

  //   const playbackRateChange = (playbackRate) => {
  //     setPlaybackRate(playbackRate);
  //   };

  return (
    <>
      <input
        type={"range"}
        {...props}
        // onChange={(e) => playbackRateChange(e?.target?.value)}
      />
    </>
  );
}
