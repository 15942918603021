import { useDispatch, useSelector } from "react-redux";
import {
  setScannerDataAction,
  removeScannerDataAction,
  setWebcamCrashAction,
  setCurrentDocumentAction,
  setTestIdsAction,
} from "../store/scanner/actions";
export const useScanner = () => {
  const scannerData = useSelector((state) => state.scanner);
  const dispatch = useDispatch();
  const handleSetScannerData = async (request) => {
    return await dispatch(setScannerDataAction(request));
  };
  const handleRemoveScannerData = async (request) => {
    return await dispatch(removeScannerDataAction(request));
  };
  const handleSetWebcamCrash = async (request) => {
    return await dispatch(setWebcamCrashAction(request));
  };
  const handleSetTestIds = async (request) => {
    return await dispatch(setTestIdsAction(request));
  };

  const setCurrentDocument = async (request) => {
    return await dispatch(setCurrentDocumentAction(request));
  };

  return {
    handleSetScannerData,
    handleSetWebcamCrash,
    handleRemoveScannerData,
    handleSetTestIds,
    setCurrentDocument,
    scannerData,
  };
};
