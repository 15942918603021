import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllFilters,
  saveAssessment,
  saveAssignment,
  saveBundle,
  saveCreatedAssessment,
  savePremadeAssessment,
  saveSharedAssessment,
  saveLicense,
  saveRoasterClass,
  saveRoasterStudent,
  saveTagManager,
  saveRoasterTeacher,
  saveOrgDistrict,
  saveOrgDistrictAdmin,
  saveOrgSchool,
  saveOrgSchoolAdmin,
} from "../store/filters/actions";
import {
  ASSESSMENT_FILTER,
  ASSIGNMENT_FILTER,
  CREATED_ASSESSMENT_FILTER,
  PREMADE_ASSESSMENT_FILTER,
  SHARED_ASSESSMENT_FILTER,
  BUNDLE_FILTER,
  LICENSE_FILTER,
  ORG_DISTRICT_FILTER,
  ORG_DISTRICT_ADMIN_FILTER,
  ORG_SCHOOL_FILTER,
  ORG_SCHOOL_ADMIN_FILTER,
  ROASTER_CLASS_FILTER,
  ROASTER_STUDENT_FILTER,
  ROASTER_TEACHER_FILTER,
  TAG_MANAGER_FILTER,
} from "../store/filters/actionTypes";
export const useFilter = () => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.filters);
  const [assesmentFilter, setAssesmentFilter] = useState(null);
  const [createdAssesmentFilter, setCreatedAssesmentFilter] = useState(null);
  const [sharedAssesmentFilter, setSharedAssesmentFilter] = useState(null);
  const [premadeAssesmentFilter, setPremadeAssesmentFilter] = useState(null);
  const [assignmentFilter, setAssignmentFilter] = useState(null);
  const [bundleFilter, setBundleFilter] = useState(null);
  const [licenseFilter, setLicenseFilter] = useState(null);
  const [classRoasterFilter, setClassRoasterFilter] = useState(null);
  const [studentRoasterFilter, setStudentRoasterFilter] = useState(null);
  const [teacherRoasterFilter, setTeacherRoasterFilter] = useState(null);
  const [orgDistrictFilter, setOrgDistrictFilter] = useState(null);
  const [orgDistrictAdminFilter, setOrgDistrictAdminFilter] = useState(null);
  const [orgSchoolFilter, setOrgSchoolFilter] = useState(null);
  const [orgSchoolAdminFilter, setOrgSchoolAdminFilter] = useState(null);
  const [tageManager, setTagManager] = useState(null);

  useEffect(() => {
    setAssesmentFilter(filterData.assessment);
    setAssignmentFilter(filterData.assignment);
    setBundleFilter(filterData.bundle);
    setLicenseFilter(filterData.license);
    setClassRoasterFilter(filterData.roasterClass);
    setStudentRoasterFilter(filterData.roasterStudent);
    setTeacherRoasterFilter(filterData.roasterTeacher);
    setOrgDistrictFilter(filterData.orgDistrict);
    setOrgDistrictAdminFilter(filterData.orgDistrictAdmin);
    setOrgSchoolFilter(filterData.orgSchool);
    setOrgSchoolAdminFilter(filterData.orgSchoolAdmin);
    setTagManager(filterData.tagManager);
    setCreatedAssesmentFilter(filterData.createdAssessment);
    setSharedAssesmentFilter(filterData.sharedAssessment);
    setPremadeAssesmentFilter(filterData.premadeAssessment);
  }, [filterData]);

  const handleClearFilter = () => {
    dispatch(clearAllFilters());
  };

  const handleSaveFilter = (request, type) => {
    switch (type) {
      case ASSESSMENT_FILTER:
        dispatch(saveAssessment(request));
        break;

      case CREATED_ASSESSMENT_FILTER:
        dispatch(saveCreatedAssessment(request));
        break;

      case PREMADE_ASSESSMENT_FILTER:
        dispatch(savePremadeAssessment(request));
        break;

      case SHARED_ASSESSMENT_FILTER:
        dispatch(saveSharedAssessment(request));
        break;

      case ASSIGNMENT_FILTER:
        dispatch(saveAssignment(request));
        break;

      case BUNDLE_FILTER:
        dispatch(saveBundle(request));
        break;

      case LICENSE_FILTER:
        dispatch(saveLicense(request));
        break;

      case ROASTER_CLASS_FILTER:
        dispatch(saveRoasterClass(request));
        break;
      case ROASTER_STUDENT_FILTER:
        dispatch(saveRoasterStudent(request));
        break;
      case ROASTER_TEACHER_FILTER:
        dispatch(saveRoasterTeacher(request));
        break;
      case ORG_DISTRICT_FILTER:
        dispatch(saveOrgDistrict(request));
        break;
      case ORG_DISTRICT_ADMIN_FILTER:
        dispatch(saveOrgDistrictAdmin(request));
        break;
      case ORG_SCHOOL_FILTER:
        dispatch(saveOrgSchool(request));
        break;
      case ORG_SCHOOL_ADMIN_FILTER:
        dispatch(saveOrgSchoolAdmin(request));
        break;
      case TAG_MANAGER_FILTER:
        dispatch(saveTagManager(request));
        break;

      default:
        console.warn("Invalid filter type", type);
        break;
    }
  };

  const getAssignmentFilter = (assignDateStart, assignDateEnd) => {
    const assignmentFilterData = { ...filterData.assignment };
    if (assignmentFilterData) {
      assignmentFilterData.assignDateStart =
        assignmentFilterData.assignDateStart
          ? moment(assignmentFilterData.assignDateStart)
          : assignmentFilterData.dueDateStart
          ? null
          : moment(assignDateStart);

      assignmentFilterData.assignDateEnd = assignmentFilterData.assignDateEnd
        ? moment(assignmentFilterData.assignDateEnd)
        : assignmentFilterData.dueDateEnd
        ? null
        : moment(assignDateEnd);

      assignmentFilterData.dueDateStart = assignmentFilterData.dueDateStart
        ? moment(assignmentFilterData.dueDateStart)
        : null;

      assignmentFilterData.dueDateEnd = assignmentFilterData.dueDateEnd
        ? moment(assignmentFilterData.dueDateEnd)
        : null;
    }
    return assignmentFilterData;
  };
  return {
    assesmentFilter,
    createdAssesmentFilter,
    sharedAssesmentFilter,
    premadeAssesmentFilter,
    assignmentFilter,
    licenseFilter,
    classRoasterFilter,
    studentRoasterFilter,
    teacherRoasterFilter,
    orgDistrictFilter,
    orgDistrictAdminFilter,
    orgSchoolFilter,
    orgSchoolAdminFilter,
    tageManager,
    bundleFilter,
    handleClearFilter,
    handleSaveFilter,
    ASSESSMENT_FILTER,
    ASSIGNMENT_FILTER,
    ROASTER_CLASS_FILTER,
    ROASTER_STUDENT_FILTER,
    ROASTER_TEACHER_FILTER,
    CREATED_ASSESSMENT_FILTER,
    PREMADE_ASSESSMENT_FILTER,
    SHARED_ASSESSMENT_FILTER,
    ORG_DISTRICT_ADMIN_FILTER,
    ORG_DISTRICT_FILTER,
    ORG_SCHOOL_ADMIN_FILTER,
    ORG_SCHOOL_FILTER,
    BUNDLE_FILTER,
    LICENSE_FILTER,
    filterData,
    getAssignmentFilter,
  };
};
