import React, { Suspense } from "react";
import * as LazyComponent from "../utils/LazyLoaded";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "../utils/PrivateRoute";
import { componentRoutes } from "../utils/constants/component-routes";
import { getPageRoutes } from "../utils/constants/pages";
import NotFound from "../components/NotFound/NotFound";
import { Switch, Route } from "react-router-dom";
import { useUser } from "../hooks";
export const PrivateRoutes = () => {
  const { userProfileData } = useUser();
  const role = userProfileData?.role;
  let pages = [];
  pages = getPageRoutes(role);
  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Switch>
        {pages.length &&
          pages.map((page, index) => {
            return (
              <PrivateRoute
                key={index}
                component={page.component}
                path={page.path}
                state={page?.state}
                exact
              />
            );
          })}
      </Switch>
    </Suspense>
  );
};

export const PublicRoutes = (props) => {
  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Switch>
        <LazyComponent.Login path={componentRoutes.welcome} exact />
        <LazyComponent.Login path={componentRoutes.logIn} exact />
        <LazyComponent.InvalidLogin path={componentRoutes.invalidLogin} exact />
        <LazyComponent.CoppaApproval
          path={componentRoutes.coppaApproval}
          exact
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export const CombinedRoutes = (props) => {
  const { userProfileData } = useUser();
  const role = userProfileData?.role;
  let pages = [];
  pages = getPageRoutes(role);
  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Switch>
        {pages.length &&
          pages.map((page, index) => {
            return (
              <PrivateRoute
                key={index}
                component={page.component}
                path={page.path}
                state={page?.state}
                exact
              />
            );
          })}
        {userProfileData ? (
          <LazyComponent.Welcome path={componentRoutes.welcome} exact />
        ) : (
          <LazyComponent.Login path={componentRoutes.welcome} exact />
        )}
        <LazyComponent.Login path={componentRoutes.welcome} exact />
        <LazyComponent.Login path={componentRoutes.logIn} exact />
        <LazyComponent.InvalidLogin path={componentRoutes.invalidLogin} exact />
        <LazyComponent.CoppaApproval
          path={componentRoutes.coppaApproval}
          exact
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};
