import moment from "moment";
import { assessmentCategory } from "./constants";
import { models } from "powerbi-client";
import { capitalizeFirstLetter } from "../Common";

export const ENABLE_REPORT_FILTER =
  process.env.REACT_APP_ENV !== "production" ? true : false;
export const reportFilterVisibleStatus = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const visible = queryParams.get("filter") || ENABLE_REPORT_FILTER;
  return visible ? true : false;
};

export const powerbiTables = {
  skillsTable: "vwReport_StudentTestScores_Skills",
  skillCompareTable: "vwReport_StudentTestScores_Skill_Compare",
  columnGradeAssement: "ColumnGradeAssement",
  columnsSRStandard: "ColumnsSRStandard",
  columnGrade: "ColumnGrade",
  vwReportColumnsSRSWithoutskill: "vwReport_ColumnsSRSWithoutskill",
  columnsSchoolReportSkill: "ColumnsSchoolReportSkill",
  vwReport_StudentTestScores_Assessment:
    "vwReport_StudentTestScores_Assessment",
  vwReport_StudentTestScores_ToolTip: "vwReport_StudentTestScores_ToolTip",
};

export const powerbiSchema = {
  basic: "http://powerbi.com/product/schema#basic",
  advanced: "http://powerbi.com/product/schema#advanced",
};

export const powerbiOperators = {
  And: "And",
  LessThanOrEqual: "LessThanOrEqual",
  GreaterThanOrEqual: "GreaterThanOrEqual",
  In: "In",
  Is: "Is",
  GreaterThan: "GreaterThan",
};

export const defaultReportConfig = {
  type: "report",
  embedUrl: undefined,
  tokenType: models.TokenType.Embed,
  accessToken: undefined,
  settings: {
    panes: {
      filters: {
        expanded: false,
        visible: reportFilterVisibleStatus(),
      },
      pageNavigation: {
        visible: false,
      },
    },
    // commands: {
    //   parameterPanel: {
    //     expanded: false,
    //     enabled: false,
    //   },
    // },
  },
};

export const teacherPaginatedFilterMapping = (data) => {
  const pbFilters = [
    "startDate",
    "endDate",
    "schoolId",
    "teacherId",
    "studentId",
    "gradeId",
    "classId",
    "strandCode",
    "standardCode",
    "isTestSubmitted",
  ];
  const uiFilterMap = {
    startDate: "startDate",
    endDate: "endDate",
    schoolId: "schoolId",
    teacherId: "selectedTeachers",
    studentId: "selectedStudents",
    gradeId: "selectedGrades",
    classId: "selectedClasses",
    strandCode: "strandCode",
    standardCode: "standardName",
    isTestSubmitted: "viewType",
  };

  const filters = pbFilters.map((k) => {
    const mappedKey = uiFilterMap[k] ? uiFilterMap[k] : k;
    return {
      name: capitalizeFirstLetter(k),
      value:
        mappedKey === "viewType" && data[mappedKey] === "1"
          ? "true"
          : data[mappedKey]
          ? data[mappedKey].toString()
          : "",
    };
  });
  return filters;
};
export const defaultPaginatedConfig = {
  commands: {
    parameterPanel: {
      expanded: false,
      enabled: false,
    },
  },
};
export const reportSchemas = {
  classReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    } else {
      if (filterData?.classIds) {
        filters.push({
          $schema: `${powerbiSchema.basic}`,
          target: {
            table: `${powerbiViewTable}`,
            column: "ClassId",
          },
          target: {
            table: `${powerbiViewTable}`,
            column: "ClassId",
          },
          operator: `${powerbiOperators.In}`,
          values: filterData?.classIds,
          // logicalOperator: `${powerbiOperators.And}`,
          // conditions: [
          //   {
          //     operator: `${powerbiOperators.GreaterThan}`,
          //     value: "0",
          //   },
          // ],
        });
      }
    }

    if (filterData?.viewType) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      });
    }
    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.teacherId],
      });
    }
    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.schoolId],
      });
    }
    return filters;
  },
  classReportByStudent(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.teacherId],
      });
    }
    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.schoolId],
      });
    }
    return filters;
  },
  prepareBasicFilterByDataPoint(state) {
    const basicFilters = [];
    if (state?.dataPoints) {
      state.dataPoints.map((data) => {
        const identity = data.identity;
        const values = data.values;
        identity.length &&
          values.length &&
          identity.map((id, index) => {
            const basicFilter = {
              $schema: `${powerbiSchema.basic}`,
              target: id.target,
              operator: `${powerbiOperators.In}`,
              values: [values[index].value],
              //filterType: 1, // pbi.models.FilterType.BasicFilter
            };
            basicFilters.push(basicFilter);
          });
      });
    }

    return basicFilters;
  },

  assessmentReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }
    const isSubmitted = parseInt(filterData?.viewType) ? true : false;
    if (isSubmitted) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [isSubmitted],
      });
    }

    // Assigned class =1, Assigned Group=2, practice group=3
    if (filterData?.assignmentTypeId === 1 && filterData?.assignToName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assignToName],
      });
    } else if (filterData?.assignToName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assignToName],
      });

      if (filterData?.assignmentTypeId === 3) {
        filters.push({
          $schema: `${powerbiSchema.basic}`,
          target: {
            table: `${powerbiViewTable}`,
            column: "IsPracticeAssignment",
          },
          operator: `${powerbiOperators.In}`,
          values: [true],
        });
      }
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.teacherId],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    } else if (
      filterData?.assignmentTypeId === 2 ||
      filterData?.assignmentTypeId === 3
    ) {
      filters.push({
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.Is}`,
            value: "0",
          },
        ],
      });
    } else {
      filters.push({
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.GreaterThan}`,
            value: "0",
          },
        ],
      });
    }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.schoolId],
      });
    }
    return filters;
  },

  standardReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    } else {
      if (filterData?.classIds) {
        filters.push({
          $schema: `${powerbiSchema.basic}`,
          target: {
            table: `${powerbiViewTable}`,
            column: "ClassId",
          },
          // logicalOperator: `${powerbiOperators.And}`,
          // conditions: [
          //   {
          //     operator: `${powerbiOperators.GreaterThan}`,
          //     value: "0",
          //   },
          // ],
          operator: `${powerbiOperators.In}`,
          values: filterData?.classIds,
        });
      }
    }

    // removing teacher ID from powerBI filter

    // if (filterData?.teacherId) {
    //   filters.push({
    //     $schema: `${powerbiSchema.basic}`,
    //     target: {
    //       table: `${powerbiViewTable}`,
    //       column: "TeacherId",
    //     },
    //     operator: `${powerbiOperators.Is}`,
    //     values: [filterData?.teacherId],
    //   });
    // }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.schoolId],
      });
    }
    return filters;
  },
  standardReportDetail(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    } else {
      filters.push({
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.GreaterThan}`,
            value: "0",
          },
        ],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.teacherId],
      });
    }
    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.schoolId],
      });
    }

    if (filterData?.standardName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StandardCode",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.standardName],
      });
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }
    return filters;
  },
  standardStudentReportByName(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    let filters = [];
    filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.studentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StudentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.studentId],
      });
    } else if (filterData?.studentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StudentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.studentName],
      });
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData.classId],
      });
    } else if (filterData?.className) {
      // console.log("inside filterData?.className", filterData?.className);
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData.className],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.teacherId],
      });
    }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.schoolId],
      });
    }

    if (filterData?.standardName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StandardCode",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.standardName],
      });
    }

    return filters;
  },
  schoolAdminStandardReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.viewType) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      });
    }

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }
    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherId],
      });
    } else if (filterData?.teacherName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherName],
      });
    }

    if (filterData?.standardName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StandardCode",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.standardName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    } else {
      if (
        (filterData?.gradeId &&
          filterData?.teacherId &&
          !filterData?.standardName) ||
        (filterData?.gradeId &&
          !filterData?.teacherId &&
          !filterData?.standardName)
      ) {
        filters.push({
          $schema: `${powerbiSchema.advanced}`,
          target: {
            table: `${powerbiViewTable}`,
            column: "ClassId",
          },
          logicalOperator: `${powerbiOperators.And}`,
          conditions: [
            {
              operator: `${powerbiOperators.GreaterThan}`,
              value: "0",
            },
          ],
        });
      }
    }

    // district Admin
    if (filterData?.districtId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "DistrictId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.districtId],
      });
    }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolId],
      });
    } else if (filterData?.schoolName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolName],
      });
    }
    return filters;
  },

  schoolAdminBaselineReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [assessmentCategory.BASELINE],
      },
    ];

    const isSubmitted = parseInt(filterData?.viewType) ? true : false;
    if (isSubmitted) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [isSubmitted],
      });
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherId],
      });
    } else if (filterData?.teacherName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherName],
      });
    }

    if (filterData?.standardName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StandardCode",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.standardName],
      });
    }

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    }

    // district Admin
    if (filterData?.districtId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "DistrictId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.districtId],
      });
    }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolId],
      });
    } else if (filterData?.schoolName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolName],
      });
    }

    if (filterData?.studentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StudentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.studentId],
      });
    } else if (filterData?.studentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StudentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.studentName],
      });
    }
    return filters;
  },

  districtAdminBaselineReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [assessmentCategory.BASELINE],
      },
    ];

    if (filterData?.viewType) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      });
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherId],
      });
    } else if (filterData?.teacherName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherName],
      });
    }

    if (filterData?.standardName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StandardCode",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.standardName],
      });
    }

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    } else {
      if (
        (filterData?.gradeId &&
          filterData?.teacherId &&
          !filterData?.standardName) ||
        (filterData?.gradeId &&
          !filterData?.teacherId &&
          !filterData?.standardName)
      ) {
        filters.push({
          $schema: `${powerbiSchema.advanced}`,
          target: {
            table: `${powerbiViewTable}`,
            column: "ClassId",
          },
          logicalOperator: `${powerbiOperators.And}`,
          conditions: [
            {
              operator: `${powerbiOperators.GreaterThan}`,
              value: "0",
            },
          ],
        });
      }
    }

    // district Admin
    if (filterData?.districtId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "DistrictId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.districtId],
      });
    }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolId],
      });
    } else if (filterData?.schoolName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolName],
      });
    }

    if (filterData?.studentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StudentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.studentId],
      });
    } else if (filterData?.studentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StudentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.studentName],
      });
    }
    return filters;
  },

  adminSchoolReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.districtId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "DistrictId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.districtId],
      });
    }

    if (filterData?.viewType) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      });
    }

    if (filterData?.gradeId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeId],
      });
    } else if (filterData?.gradeName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "GradeName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.gradeName],
      });
    }

    if (filterData?.assessmentId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentId],
      });
    } else if (filterData?.assessmentName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.assessmentName],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherId],
      });
    } else if (filterData?.teacherName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherName],
      });
    }

    if (filterData?.standardName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "StandardCode",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.standardName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    }
    // school Admin
    // if (filterData?.schoolId) {
    //   filters.push({
    //     $schema: `${powerbiSchema.basic}`,
    //     target: {
    //       table: `${powerbiViewTable}`,
    //       column: "SchoolId",
    //     },
    //     operator: `${powerbiOperators.Is}`,
    //     values: [filterData?.schoolId],
    //   });
    // }

    // district Admin
    // if (districtId) {
    //   filters.push({
    //     $schema: `${powerbiSchema.basic}`,
    //     target: {
    //       table: "District",
    //       column: "Id",
    //     },
    //     operator: `${powerbiOperators.Is}`,
    //     values: [districtId],
    //   });
    // }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolId],
      });
    } else if (filterData?.schoolName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolName],
      });
    }
    return filters;
  },

  adminStudentReport(filterData) {
    const powerbiViewTable = getPowerBiTableBySectionId(filterData);
    const filters = [
      {
        $schema: `${powerbiSchema.advanced}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssignedDate",
        },
        logicalOperator: `${powerbiOperators.And}`,
        conditions: [
          {
            operator: `${powerbiOperators.LessThanOrEqual}`,
            value: moment(filterData?.endDate)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00"),
          },
          {
            operator: `${powerbiOperators.GreaterThanOrEqual}`,
            value: moment(filterData?.startDate).format("YYYY-MM-DD 00:00:00"),
          },
        ],
      },
      // default category filter
      {
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "AssessmentCategoryId",
        },
        operator: `${powerbiOperators.In}`,
        values: [assessmentCategory.CUSTOM, assessmentCategory.MODULE],
      },
    ];

    if (filterData?.viewType) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "IsTestSubmitted",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.viewType == 1 ? true : "All"],
      });
    }

    if (filterData?.teacherId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherId],
      });
    } else if (filterData?.teacherName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "TeacherName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.teacherName],
      });
    }

    if (filterData?.classId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.classId],
      });
    } else if (filterData?.className) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "ClassName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.className],
      });
    }

    // district Admin
    if (filterData?.districtId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "DistrictId",
        },
        operator: `${powerbiOperators.Is}`,
        values: [filterData?.districtId],
      });
    }

    if (filterData?.schoolId) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolId",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolId],
      });
    } else if (filterData?.schoolName) {
      filters.push({
        $schema: `${powerbiSchema.basic}`,
        target: {
          table: `${powerbiViewTable}`,
          column: "SchoolName",
        },
        operator: `${powerbiOperators.In}`,
        values: [filterData?.schoolName],
      });
    }
    return filters;
  },
};

// current year month is greater than auguest then take current date august date, else take last year august date
export const schoolCalendarDateRange = {
  startDate:
    parseInt(moment(Date.now()).format("M")) >=
    parseInt(moment().month("August").format("M"))
      ? moment(Date.now()).format("07/01/YYYY")
      : moment(Date.now()).subtract(1, "years").format("07/01/YYYY"),
  endDate: moment(Date.now()).format("MM/DD/YYYY"),
};

export const reportViewTypes = [
  { id: 0, name: "All Assignments" },
  { id: 1, name: "Submissions only" },
];

export const reportPageNamesList = {
  CLASS_REPORT: "ReportSectionc52dab8837110d088d29",
  CLASS_REPORT_BY_STUDENT: "ReportSection0cccf24b2206ad023094",
  STUDENT_REPORT_BY_NAME: "ReportSectionef0785a1d204e9970a86",
  STANDARD_BY_STUDENT: "ReportSectiond45fc9a0aacda4044097",
  ASSESSMENT_REPORT: "ReportSection0768cae3e03e700150a6",

  STANDARD_REPORT: "ReportSectionc16007abc3dc8110190a",
  STANDARD_REPORT_DETAILS: "ReportSection81f2a6494acca2a8aec0",
  SA_STANDARD_REPORT_BY_GRADE: "ReportSection1483724497f5e1be912b",

  SA_STANDARD_REPORT_BY_TEACHER: "ReportSectione6a65ae94ac4e192b0b4",
  DA_STANDARD_REPORT_BY_GRADE: "ReportSection",
  TEACHER_ASSESSMENT_REPORT: "ReportSection",
  DA_STANDARD_REPORT_BY_STANDARD: "ReportSection379def9ade9624a54fe1",
  DISTRICT_ADMIN_STANDARD_REPORT_BY_CLASS_STANDARD:
    "ReportSection3e96773920441b4f8631",
  // DISTRICT_ADMIN_STANDARD_REPORT_BY_CLASS_STANDARD:,
  ADMIN_STANDARD_REPORT: "ReportSection1483724497f5e1be912b", // default
  ADMIN_STANDARD_REPORT_BY_GRADE_TEACHER: "ReportSectione6a65ae94ac4e192b0b4", // grade select
  ADMIN_STANDARD_REPORT_BY_GRADE_SCHOOL_STANDARD:
    "ReportSection777684f531c743cc5c36", //
  ADMIN_STANDARD_REPORT_BY_GRADE_CLASS: "ReportSection3e96773920441b4f8631", //

  // school report
  ADMIN_SCHOOL_REPORT: "ReportSection551b145e1176f03b1e1f",

  ADMIN_BASELINE_SCHOOL_REPORT_BY_TEACHER_AND_GRADE:
    "ReportSection0cc28ffbb755ff35ee21",

  ADMIN_SCHOOL_REPORT_TEACHER_AND_GRADE: "ReportSectiona64667e7fe840b715072",
  ADMIN_SCHOOL_REPORT_ASSESSMENT: "ReportSectionf039f211f0f333cfd8ab",
  ADMIN_SCHOOL_REPORT_STANDARD: "ReportSectionaa73c26b8bdc121453e1",
  ADMIN_SCHOOL_REPORT_CLASS: "ReportSection01c5e14cabdccb8da1c0",
  // student report
  ADMIN_STUDENT_REPORT: "ReportSection8fa8992d1efc0b2207e4",
  ADMIN_BASELINE_SCHOOL_GRADE_ASSESSMENT: "ReportSection78f4f63b4dd1a83ebc3f",
  // Baseline report
  STANDARD_REPORT_BY_TEACHER_COMPARE_SKILL: "ReportSectiona2614e24ef7b572eee94",
  SCHOOL_REPORT_SCHOOL_GRADE_STANDARD: "ReportSection761e0d4b9e0bfef97201",
  TEACHER_ASSESSMENT_REPORT_SUBMISSION_ONLY:
    "ReportSectionc8c311c3348185c63e45",
  StudentReportDrillDownByStandards: "StudentReportDrillDownByStandards",
  StandardReportbyClassWithSkills: "StandardReportbyClassWithSkills",
};
export const reportIdByPageName = {
  ReportSection1483724497f5e1be912b: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection777684f531c743cc5c36: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSectione6a65ae94ac4e192b0b4: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection551b145e1176f03b1e1f: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection0cc28ffbb755ff35ee21: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSectiona64667e7fe840b715072: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection01c5e14cabdccb8da1c0: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection78f4f63b4dd1a83ebc3f: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSection8fa8992d1efc0b2207e4: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSectionf039f211f0f333cfd8ab: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSectionaa73c26b8bdc121453e1: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSection81f2a6494acca2a8aec0: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSection: `${process.env.REACT_APP_HYBRID_REPORT}`,
  DISTRICT_ADMIN_STANDARD_REPORT_BY_CLASS_STANDARD: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ADMIN_SCHOOL_REPORT_STANDARD: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ADMIN_SCHOOL_REPORT_ASSESSMENT: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  SA_STANDARD_REPORT_BY_GRADE: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection0cccf24b2206ad023094: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSectionef0785a1d204e9970a86: `${process.env.REACT_APP_DYNAMIC_REPORT}`,
  ReportSectiona2614e24ef7b572eee94: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSection761e0d4b9e0bfef97201: `${process.env.REACT_APP_DYNAMIC_REPORT_ADMIN}`,
  ReportSectionc8c311c3348185c63e45: `${process.env.REACT_APP_HYBRID_REPORT}`,
};

export const reportEnumByReportSectionId = {
  ReportSectionc52dab8837110d088d29: 1,
  ReportSection0cccf24b2206ad023094: 2,
  ReportSectionef0785a1d204e9970a86: 3,
  ReportSectiond45fc9a0aacda4044097: 4,
  ReportSectionc16007abc3dc8110190a: 5,
  ReportSection81f2a6494acca2a8aec0: 6,
  ReportSection: 7,
  ReportSectionf039f211f0f333cfd8ab: 8,
  ReportSectionaa73c26b8bdc121453e1: 9,
  ReportSection8fa8992d1efc0b2207e4: 10,
  ReportSection189ffa6d6c81509280e7: 11,
  ReportSection1483724497f5e1be912b: 12,
  ReportSectione6a65ae94ac4e192b0b4: 13,
  ReportSection777684f531c743cc5c36: 14,
  ReportSection3e96773920441b4f8631: 15,
  "ReportSection71ee874f7c9bbaf3b77c-2": 16,
  ReportSection551b145e1176f03b1e1f: 17,
  ReportSectiona64667e7fe840b715072: 18,
  ReportSection01c5e14cabdccb8da1c0: 19,
  ReportSectiona2614e24ef7b572eee94: 20,
  ReportSection761e0d4b9e0bfef97201: 21,
  ReportSection78f4f63b4dd1a83ebc3f: 22,
  ReportSectionc8c311c3348185c63e45: 23,
  StudentReportDrillDownByStandards: 24,
  StandardReportbyClassWithSkills: 25,
};

export const getPowerBiTableBySectionId = ({ reportSectionId }) => {
  return powerbiTableByPageName[reportSectionId] ?? powerbiTables.skillsTable;
};

export const powerbiTableByPageName = {
  ReportSectiona2614e24ef7b572eee94: `${powerbiTables.skillCompareTable}`,
  ReportSection: `${powerbiTables.vwReport_StudentTestScores_ToolTip}`,
  ReportSectionaa73c26b8bdc121453e1: `${powerbiTables.skillCompareTable}`,
  ReportSection81f2a6494acca2a8aec0: `${powerbiTables.skillCompareTable}`,
  ReportSectionc8c311c3348185c63e45: `${powerbiTables.vwReport_StudentTestScores_ToolTip}`,
};

export const enableExportButtons = [
  "ReportSection",
  "ReportSectionc8c311c3348185c63e45",
  "ReportSectiona2614e24ef7b572eee94",
  "ReportSection3e96773920441b4f8631",
  "ReportSectione6a65ae94ac4e192b0b4",
  "ReportSection1483724497f5e1be912b",
  "ReportSectionc16007abc3dc8110190a",
  "ReportSectiona64667e7fe840b715072",
  "ReportSection551b145e1176f03b1e1f",
  "ReportSectionaa73c26b8bdc121453e1",
  "ReportSectionf039f211f0f333cfd8ab",
];

export const reportsVisualNames = [
  "a25a2ca9112f2e75936a" /* assessment report*/,
  "16d9ac6114a79c265e2a" /* standard report */,
  // "d4f0d712b7801457e094" /* Standard report */,
  "0100e8b8b6fa52caee60" /* standard report */,
  "27935837aea329ecd979" /* standard report */,
  "92ff3b6c6b2ddea51269" /* school report */,
  "47ecd33308406651666c" /* school report */,
  "a19ec13a248bfff2edf0" /* school report */,
  "fc79ab4adeedcdad4208" /* school report */,
  "d503c5a423b86c054de0" /* assessment report submission*/,
  "486e094cea101df928a9" /* baseline assessment */,
  "b978222d70f2fa81a5eb" /* baseline assessment */,
  "8733a732744f14893975" /* standard report */,
  "3dcb1cc1ac8e1bb8beba" /* standard report */,
  "b1ab1568fa6676c783f7" /* DA standard report */,
  "959653317b3ec19228f5",
  "d06633e70ed764f7805c",
];

export const hideExportButtons = ["ReportSectiond45fc9a0aacda4044097"];
export const hideGraphButtons = [
  "ReportSectionc16007abc3dc8110190a",
  "StandardReportbyClassWithSkills",
  "StudentReportDrillDownByStandards",
];
