export const constants = {
  Log_In: "Log In",
  Login: "Login",
  MINIMUM_RECORD_LIMIT: "6",
  MAXIMUM_RECORD_LIMIT: "Max",
  GraphExportTooltip: "Download graph image. Takes about one minute to create.",
  ExcelExportTooltip:
    "Download report to Excel document. Takes about one minutes to create.",
};

export const evaluateQuestionType = {
  SINGLE_CHOICE: 1, // Single Choice
  MULTIPLE_CHOICE: 2, // multi choice
  OPEN_ENDED: 3, // descriptive
};

export const ntnCardType = {
  STUDENT_ASSIGNED: "student-assigned-assessment",
  STUDENT_GRADED: "student-graded-assessment",
  SCHHOLLADMIN_MINE: "school-admin-mine",
  TEACHER_CREATEDBYME: "teacher-mine",
};

export const assignedAssessmentStatus = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  LATE: 2,
};

export const assessmentCategory = {
  CUSTOM: 1,
  MODULE: 2,
  BASELINE: 3,
};

export const assessmentCategoryName = {
  CUSTOM: "Custom",
  MODULE: "Module",
  BASELINE: "Baseline",
};

export const questionCategoryName = {
  1: "Question Bank",
  2: "Module Assessment",
  3: "Baseline Assessment",
};

export const openEndedquestionCategoryName = {
  2: "Module Assessment",
};

export const dynamicComponentNames = {
  TAKE_ASSESSMENT: "takeAssessment",
  VIEW_SCORE: "viewScore",
  CLASS_REPORT: "classReport",
  CLASS_REPORT_BY_STUDENT: "classReportByStudent",
  STANDARD_REPORT_DETAIL: "standardReportDetail",
  BASELINE_REPORT: "baselineReport",
  ASSESSMENT_REPORT: "assessmentReport",
  ATTEMPT_TEST: "attemptTest",
};

export const assessmentDataType = {
  SHARED_WITH_ME: "shared-with-me",
  SHARED_BY_ME: "shared-by-me",
  PREMADE: "premade",
  CREATED_BY_ME: "created-by-me",
  ASSIGNED: "assignment",
};

export const dataTypeText = {
  "shared-with-me": "Shared With Me",
  "shared-by-me": "Shared By Me",
  premade: "Premade Assessment",
  "created-by-me": "Created By Me",
  assignment: "Assigments",
  "premade-assessment": "Premade Assessment",
};

export const assessmentQuestionLimit = {
  TEACHER: 30,
  DISTRICT_ADMIN: 50,
  SCHOOL_ADMIN: 50,
};

export const languageConstant = {
  ENGLISH: 1,
  SPANISH: 2,
};

export const languageCodeConstant = {
  ENGLISH: "en-US",
  SPANISH: "es-ES",
};

export const languageOptions = [
  { value: "1", label: "English" },
  { value: "2", label: "Spanish" },
];

export const assessmentLanguageOptions = [
  { id: "en-US", name: "English" },
  { id: "es-ES", name: "Spanish" },
];

export const studentStatusList = {
  ACTIVE: 1,
  INACTIVE: 2,
  TRANSFERRED: 3,
};

export const rosterFilter = {
  classTypeList: [
    { id: 0, name: "My Class(es)" },
    { id: 1, name: "All Class(es)" },
  ],
  studentStatusList: [
    { id: studentStatusList.ACTIVE, name: "Active" },
    { id: studentStatusList.TRANSFERRED, name: "Transferred" },
    { id: studentStatusList.INACTIVE, name: "Inactive" },
  ],
  teacherStatusList: [
    { id: studentStatusList.ACTIVE, name: "Active" },
    { id: studentStatusList.INACTIVE, name: "Inactive" },
  ],
};

export const pointValues = [
  { id: "2", name: "2" },
  { id: "3", name: "3" },
];

export const commonStatusList = [
  { id: "0", name: "Draft" },
  { id: "1", name: "Published" },
];

export const numberToAlphaBullets = ["A", "B", "C", "D", "E", "F"];

export const helpfulLinks = [
  {
    label: "Employee Login",
    url: "#",
    target: "_self",
  },
  {
    label: "Training Servey",
    url: "#",
    target: "_self",
  },
  {
    label: "Resource User Profile",
    url: "#",
    target: "_self",
  },
  {
    label: "Career Opportunities",
    url: "#",
    target: "_self",
  },
  {
    label: "Site Map",
    url: "https://ntnmath.com/site-map/",
    target: "_blank",
  },
];

export const userSettingKeys = {
  SpeechEnglishVoiceName: "SpeechEnglishVoiceName",
  SpeechPlaybackSpeed: "SpeechPlaybackSpeed",
  SpeechSpanishVoiceName: "SpeechSpanishVoiceName",
};
