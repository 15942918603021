import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const reportSchoolListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportSchoolList(request)
      .then((response) => {
        dispatch({
          type: types.SET_SCHOOL_LIST,
          payload: response.data?.schoolList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportGradeListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportGradeList(request)
      .then((response) => {
        dispatch({
          type: types.SET_GRADE_LIST,
          payload: response.data?.gradeList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportStudentListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportStudentList(request)
      .then((response) => {
        dispatch({
          type: types.SET_STUDENT_LIST,
          payload: response.data?.studentList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportTeacherListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportTeachersList(request)
      .then((response) => {
        dispatch({
          type: types.SET_TEACHER_LIST,
          payload: response.data?.teacherList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportClassGroupOptionsListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportClassGroupOptionsList(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportClassListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportClassList(request)
      .then((response) => {
        dispatch({
          type: types.SET_CLASS_LIST,
          payload: response.data?.classList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportAssessmentListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportAssessmentList(request)
      .then((response) => {
        dispatch({
          type: types.SET_ASSESSMENT_LIST,
          payload: response.data?.assessmentList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportStrandListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportStrandList(request)
      .then((response) => {
        dispatch({
          type: types.SET_STRAND_LIST,
          payload: response.data?.strandsList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportAssignmentTypeListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportAssignmentTypeList(request)
      .then((response) => {
        dispatch({
          type: types.SET_ASSIGNMENT_TYPE_LIST,
          payload: response.data?.assignmentTypeList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportStandardListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportStandardList(request)
      .then((response) => {
        dispatch({
          type: types.SET_STANDARD_LIST,
          payload: response.data?.standardsList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportAssessmentTypesListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportAssessmentTypesList(request)
      .then((response) => {
        dispatch({
          type: types.SET_ASSESSMENT_TYPE_LIST,
          payload: response.data?.assessmentTypeList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const reportClassGroupListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getReportClassGroupList(request)
      .then((response) => {
        dispatch({
          type: types.SET_CLASS_GROUP_LIST,
          payload: response.data?.classList,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const downloadReportAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.downloadReport(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getFilterDefaultsAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getFilterDefaults(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};
export const setReportFilterDataAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_REPORT_FILTER,
      payload: request,
    });
  };
};

export const setClassReportFilterDataAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_CLASS_REPORT_FILTER,
      payload: request,
    });
  };
};

export const setStandardReportFilterDataAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_STANDARD_REPORT_FILTER,
      payload: request,
    });
  };
};

export const setReportsStepsAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_REPORTS_STEPS,
      payload: request,
    });
  };
};

export const getQuestionsHTMLAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getQuestionsHTML(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getReportEmbedTokenAction = (request) => {
  return async () => {
    return await HttpApis.getReportEmbedToken(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return null;
      });
  };
};

export const getReportIdEmbedTokenAction = (request) => {
  return async () => {
    return await HttpApis.getReportIdEmbedToken(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return null;
      });
  };
};
