import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as RiIcons from "react-icons/ri";
import { useLocation, matchPath } from "react-router-dom";
import { getPageRoutes } from "../../utils/constants/pages";
import { useUser } from "../../hooks";
import "./sub_menu.scss";
import { useDispatch } from "react-redux";
const SubMenu = ({ item, handler, hasSubmenu }) => {
  const { userRole, handleActiveMenu } = useUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const allPageRoutes = getPageRoutes(userRole);

  let currentPageRoute = allPageRoutes.find((p) => {
    return matchPath(location.pathname, {
      path: p.path,
      exact: true,
      strict: true,
    });
  });

  // for home page only
  if (currentPageRoute && currentPageRoute.path === "/") {
    currentPageRoute = allPageRoutes[1];
  } else {
    //parentNav;
    const parent = allPageRoutes.find((p) => {
      if (p.childTags && currentPageRoute?.state?.parentNav) {
        return p?.childTags.includes(currentPageRoute.state.parentNav);
      }
    });
    if (parent) {
      currentPageRoute = parent;
    }
  }

  // //check for dynamic route
  const [subNav, setSubNav] = useState(location.pathname.includes(item.path));
  const handleSubmenu = (e, item) => {
    if (e.currentTarget?.nextSibling?.nodeName === "UL") {
      e.currentTarget.nextSibling.classList.toggle("expanded");
      e.currentTarget.nextSibling.classList.toggle("collapsed");
    }
    setSubNav(!subNav);
    if (item.path !== "#") {
      handler();
    }
    // item.expanded = !item.expanded;
    // handleActiveMenu(item);
  };
  const iconClass = !hasSubmenu
    ? currentPageRoute && item.path === currentPageRoute.path
      ? `nav-text ${item?.className} ${item.active ? ` active` : ``}`
      : `nav-text ${item?.className} ${item.active ? ` active` : ``}`
    : currentPageRoute && item.path === currentPageRoute.path
    ? `nav-text-submenu ${item?.className} ${
        item.active ? ` submenu-active` : ``
      }`
    : `nav-text-submenu ${item?.className} ${
        item.active ? ` submenu-active` : ``
      }`;
  const iconClassForNoSubMenu =
    currentPageRoute && item.path === currentPageRoute.path && !item.subMenu
      ? "nav-text  active"
      : "";
  return (
    <>
      {item.title ? (
        <li>
          <Link
            to={item.path}
            className={iconClass + " " + iconClassForNoSubMenu}
            onClick={(e) => handleSubmenu(e, item)}
          >
            <div className="menu-items">
              <span>
                {item.icons}
                {item.title}
              </span>
              <span>
                {item.subMenu && (subNav || item.expanded) ? (
                  <RiIcons.RiArrowUpSLine className="nav-direction-arrow" />
                ) : item.subMenu ? (
                  <RiIcons.RiArrowDownSLine className="nav-direction-arrow" />
                ) : null}
              </span>
            </div>
          </Link>
          {item.subMenu ? (
            <ul
              className={
                subNav || item.expanded
                  ? `expanded list-unstyled submenu-container`
                  : `collapsed list-unstyled submenu-container`
              }
            >
              {item.subMenu.map((item, index) => {
                return (
                  <SubMenu
                    handler={handler}
                    item={item}
                    key={index}
                    hasSubmenu
                  />
                );
              })}
            </ul>
          ) : null}
        </li>
      ) : null}
    </>
  );
};
export default SubMenu;
