import * as types from "./actionTypes";
const initialState = {
  loading: false,
  questions: [],
  selectedQuestions: [],
};
const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_QUESTIONS_REQUEST:
      let questions = [...state.questions];
      action.payload.filter((q) => {
        const c = questions.find((eq) => eq.id === q.id);
        if (!c) {
          questions.push(q);
        }
        return c;
      });
      return {
        ...state,
        questions: questions,
        loading: false,
      };
    case types.REMOVE_QUESTIONS_REQUEST:
      return {
        ...state,
        questions: [],
        loading: false,
      };
    case types.CLEAR_QUESTIONS_STORE:
      return {
        ...state,
        questions: [],
        selectedQuestions: [],
        loading: false,
      };
    case types.REPLACE_ALL_SELECTED_QUESTIONS:
      return {
        ...state,
        selectedQuestions: action.payload,
        loading: false,
      };
    case types.ADD_SELECTED_QUESTIONS:
      let _questions = [...state.selectedQuestions];
      action.payload.deleted = false;
      action.payload.isSelected = true;
      _questions.push(action.payload);
      return {
        ...state,
        selectedQuestions: _questions,
        loading: false,
      };

    case types.REMOVE_SELECTED_QUESTIONS:
      let r_questions = [...state.selectedQuestions];
      let _selectedIndex = r_questions.findIndex(
        (q) => q.id === action.payload.id
      );
      if (_selectedIndex > -1) {
        r_questions.splice(selectedIndex, 1);
      }
      return {
        ...state,
        selectedQuestions: r_questions,
        loading: false,
      };

    case types.UPDATE_SELECTED_QUESTIONS:
      let e_questions = [...state.selectedQuestions];
      let selectedIndex = e_questions.findIndex(
        (q) => q.id === action.payload.id
      );
      e_questions.splice(selectedIndex, 1, action.payload);
      return {
        ...state,
        selectedQuestions: e_questions,
        loading: false,
      };

    case types.ADD_UPDATE_SELECTED_QUESTIONS:
      let selected_questions = [...state.selectedQuestions];
      action.payload.forEach((element, index) => {
        let selectedIndex = selected_questions.findIndex(
          (q) => q.id === element.id
        );
        if (selectedIndex === -1) {
          // element.deleted = false;
          // element.isSelected = true;
          selected_questions.push(element);
        } else {
          // element.deleted = !element.deleted;
          // element.isSelected = !element.isSelected;
          selected_questions.splice(selectedIndex, 1, element);
        }
      });

      return {
        ...state,
        selectedQuestions: selected_questions,
        loading: false,
      };

    case types.CLEAR_SELECTED_QUESTIONS:
      return {
        ...state,
        selectedQuestions: [],
        loading: false,
      };
    default:
      return state;
  }
};
export default questionReducer;
