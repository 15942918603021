import * as types from "./actionTypes";
import { SAVE_USER_SETTING } from "../settings/actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "./../global";
import History from "../../routes/History";
import { componentRoutes } from "../../utils/constants/component-routes";
import { httpStatus } from "../../utils/constants";
import { getErrorMessage } from "../../utils/messages";
import Auth from "../../services/Auth";
export const loginAction = (param) => {
  let request = {
    customerId: param.phoneNumber,
    password: param.password,
  };
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    await HttpApis.login(request)
      .then((response) => {
        if (response.data.token) {
          dispatch({ type: types.LOGIN_USER_SUCCESS, payload: response.data });
          Auth.setToken(response.data.token);
          History.push(componentRoutes.home);
          dispatch(apiLoadingStop());
        } else {
          dispatch({
            type: types.LOGIN_USER_ERROR,
            payload: getErrorMessage(httpStatus.CREDENTIAL_NOTFOUND),
          });
          dispatch(apiLoadingStop());
          if (response.status !== httpStatus.OK) {
            console.log(getErrorMessage(response.status));
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: types.LOGIN_USER_ERROR,
          payload: getErrorMessage(httpStatus.INTERNAL_SERVER_ERROR),
        });
        console.log(error);
        dispatch(apiLoadingStop());
      });
  };
};

const redirect = (redirectUrl) => {
  window.location = redirectUrl;
};

export const getAccoutAction = () => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    await HttpApis.getAccount()
      .then((response) => {
        if (response.status === httpStatus.OK) {
          dispatch({ type: types.GET_ACCOUNT_SUCCESS, payload: response.data });

          dispatch(apiLoadingStop());
        } else {
          dispatch({
            type: types.GET_ACCOUNT_ERROR,
            payload: response.message,
          });
          dispatch(apiLoadingStop());

          console.log(getErrorMessage(response.status));
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_ACCOUNT_ERROR, payload: error.message });
        console.log(error);
        dispatch(apiLoadingStop());
      });
  };
};
export const loginWithoutApiAction = (request) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_USER_SUCCESS, payload: true });
    Auth.setToken("token");
    History.push(componentRoutes.home);
  };
};

export const logoutAction = (navigation) => {
  return (dispatch) => {
    dispatch({ type: types.LOGOUT_USER_SUCCESS, payload: false });
  };
};

export const clearLoginErrorMessage = (navigation) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_USER_ERROR, payload: null });
  };
};

export const checkAccountSecurityAction = (token, instance) => {
  return async (dispatch) => {
    //dispatch(apiLoadingStart());
    await HttpApis.checkAccountSecurity()
      .then((response) => {
        if (response && response.status === httpStatus.OK) {
          dispatch({ type: types.LOGIN_USER_SUCCESS, payload: true });
          dispatch({ type: types.GET_ACCOUNT_SUCCESS, payload: response.data });
          dispatch({
            type: SAVE_USER_SETTING,
            payload: response?.data?.settings,
          });
          dispatch(apiLoadingStop());
          Auth.setToken(token);
          History.push(componentRoutes.welcome);
        } else {
          Auth.signOut();
          dispatch({
            type: types.LOGIN_USER_ERROR,
            payload: response.ReasonNotActive,
          });
          dispatch(logoutAction());
          dispatch(apiLoadingStop());
          return instance.logoutRedirect({
            postLogoutRedirectUri: componentRoutes.invalidLogin,
          });
          //return redirect(componentRoutes.invalidLogin);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        Auth.signOut();
        if (error?.reasonNotActive || error.errorType) {
          dispatch({
            type: types.LOGIN_USER_ERROR,
            payload: error?.reasonNotActive
              ? error?.reasonNotActive
              : error?.displayFailMessage,
          });
        }
        dispatch(logoutAction());
        dispatch(apiLoadingStop());
        return instance.logoutRedirect({
          postLogoutRedirectUri: componentRoutes.invalidLogin,
        });
        //return redirect(componentRoutes.invalidLogin);
      });
  };
};

export const setSessionAction = (request) => {
  return (dispatch) => {
    dispatch({ type: types.ACCOUNT_SESSION, payload: request });
  };
};

export const clearUserStore = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_USER_ACCOUNT });
  };
};

export const setActiveMenu = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.CONSTANT_ACTIVE_MENU,
      payload: request,
    });
  };
};
