import * as types from "./actionTypes";
const initialState = {
  loading: false,
  districtList: null,
  filterList: null,
  orgRequest: null,
};
const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORG_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload,
        loading: false,
      };
    case types.SET_ORG_FILTER_LIST:
      return {
        ...state,
        filterList: action.payload,
        loading: false,
      };
    case types.SET_ORG_REQUEST:
      return {
        ...state,
        orgRequest: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default organizationReducer;
