import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk];
const configureStore = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default configureStore;
