import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as BiIcons from "react-icons/bi";
import * as RiIcons from "react-icons/ri";
import { ReactComponent as ContentIcon } from "../../../assets/images/content-icon.svg";
import { ReactComponent as LicenseIcon } from "../../../assets/images/license-icon.svg";
import { ReactComponent as OrganizationIcon } from "../../../assets/images/organization-icon.svg";

import React from "react";
import { ComponentUrls } from "../component-names";

export const MyClasses = React.lazy(() =>
  import("../../../pages/Rosters/NtnAdmin/Classes/Classes")
);

export const StudentList = React.lazy(() =>
  import("../../../pages/Rosters/NtnAdmin/Students/Students")
);

export const TeacherList = React.lazy(() =>
  import("../../../pages/Rosters/NtnAdmin/Teacher/TeacherList")
);

export const CreateTag = React.lazy(() =>
  import("../../../pages/TagManager/CreateTag")
);

export const TagEditor = React.lazy(() =>
  import("../../../pages/TagManager/TagEditor")
);

export const QuestionList = React.lazy(() =>
  import("../../../pages/TagManager/Questions/Questions")
);

export const CreateQuestionType = React.lazy(() =>
  import("../../../pages/TagManager/Questions/QuestionType")
);

export const CreateQuestion = React.lazy(() =>
  import("../../../pages/TagManager/Questions/CreateQuestion")
);

export const EditQuestion = React.lazy(() =>
  import("../../../pages/TagManager/Questions/EditQuestion")
);

export const ViewQuestion = React.lazy(() =>
  import("../../../pages/TagManager/Questions/ViewQuestion")
);

export const CopyQuestion = React.lazy(() =>
  import("../../../pages/TagManager/Questions/CopyQuestion")
);

export const AssessmentList = React.lazy(() =>
  import("../../../pages/TagManager/Assessments/Assessments")
);

export const CreateAssessment = React.lazy(() =>
  import("../../../pages/TagManager/Assessments/CreateAssessment")
);

export const AssessmentQuestions = React.lazy(() =>
  import("../../../pages/TagManager/Assessments/AssessmentQuestions")
);
export const ViewAssessment = React.lazy(() =>
  import("../../../pages/TagManager/Assessments/ViewAssessment")
);

export const EditAssessment = React.lazy(() =>
  import("../../../pages/TagManager/Assessments/EditAssessment")
);

export const CopyAssessment = React.lazy(() =>
  import("../../../pages/TagManager/Assessments/CopyAssessment")
);

// Bundle Manager

export const ManageBundle = React.lazy(() =>
  import("../../../pages/Bundles/Bundles")
);

export const CreateBundle = React.lazy(() =>
  import("../../../pages/Bundles/CreateBundle")
);

export const EditBundle = React.lazy(() =>
  import("../../../pages/Bundles/EditBundle")
);

export const ViewBundle = React.lazy(() =>
  import("../../../pages/Bundles/ViewBundle")
);

export const BundleQuestions = React.lazy(() =>
  import("../../../pages/Bundles/BundleQuestions")
);

export const BundleAssessments = React.lazy(() =>
  import("../../../pages/Bundles/BundleAssessments")
);

export const ManageLicense = React.lazy(() =>
  import("../../../pages/License/ManageLicense")
);

export const ManageLicensePreview = React.lazy(() =>
  import("../../../pages/License/ManageLicensePreview")
);
export const CreateLicenseDistrict = React.lazy(() =>
  import("../../../pages/License/CreateLicenseDistrict")
);

export const CreateLicenseContent = React.lazy(() =>
  import("../../../pages/License/CreateLicenseContent")
);

export const CreateLicenseTerm = React.lazy(() =>
  import("../../../pages/License/CreateLicenseTerm")
);

export const CreateLicensePreview = React.lazy(() =>
  import("../../../pages/License/createLicenseFinal")
);

export const EditLicenseDistrict = React.lazy(() =>
  import("../../../pages/License/editLicenseDistrict")
);

export const EditLicenseContent = React.lazy(() =>
  import("../../../pages/License/editLicenseContent")
);

export const EditLicenseTerm = React.lazy(() =>
  import("../../../pages/License/editLicenseTerm")
);

export const EditLicensePreview = React.lazy(() =>
  import("../../../pages/License/editLicenseFinal")
);

export const accessCodeLicense = React.lazy(() =>
  import("../../../pages/License/AccessCodeLicense")
);

export const generateAccessCode = React.lazy(() =>
  import("../../../pages/License/GenerateAccessCode")
);

// organizations
export const OrganizationDistricts = React.lazy(() =>
  import("../../../pages/Organizations/District/Districts")
);

export const OrganizationCreateDistricts = React.lazy(() =>
  import("../../../pages/Organizations/District/CreateDistrict")
);

export const OrganizationEditDistrict = React.lazy(() =>
  import("../../../pages/Organizations/District/EditDistrict")
);

export const OrganizationDistrictAdmins = React.lazy(() =>
  import("../../../pages/Organizations/DistrictAdmin/DistrictAdmins")
);

export const OrganizationCreateDistrictAdmin = React.lazy(() =>
  import("../../../pages/Organizations/DistrictAdmin/CreateDistrictAdmin")
);

export const OrganizationEditDistrictAdmin = React.lazy(() =>
  import("../../../pages/Organizations/DistrictAdmin/EditDistrictAdmin")
);

// schools

export const OrganizationSchools = React.lazy(() =>
  import("../../../pages/Organizations/School/Schools")
);

export const OrganizationCreateSchool = React.lazy(() =>
  import("../../../pages/Organizations/School/CreateSchool")
);

export const OrganizationEditSchool = React.lazy(() =>
  import("../../../pages/Organizations/School/EditSchool")
);

// school admin
export const OrganizationSchoolAdmin = React.lazy(() =>
  import("../../../pages/Organizations/SchoolAdmin/SchoolAdmins")
);

export const OrganizationCreateSchoolAdmin = React.lazy(() =>
  import("../../../pages/Organizations/SchoolAdmin/CreateSchoolAdmin")
);

export const OrganizationEditSchoolAdmin = React.lazy(() =>
  import("../../../pages/Organizations/SchoolAdmin/EditSchoolAdmin")
);

export const AddClass = React.lazy(() =>
  import("../../../pages/Rosters/NtnAdmin/Classes/AddClass")
);

// archive
export const DataArchive = React.lazy(() =>
  import("../../../pages/Archive/DataArchive")
);

export const PromoteStudents = React.lazy(() =>
  import("../../../pages/Rosters/StudentPromotion/PromoteStudents")
);

// export const CreateTag = React.lazy(() =>
//   import("../../../pages/TagManager/CreateTag")
// );
export const Help = React.lazy(() => import("../../../pages/Help"));

export const NTNAdminNavbar = [
  {
    title: "Tag Manager",
    path: `#`,
    icons: <BsIcons.BsGrid3X3Gap />,
    childTags: [
      "question-list",
      "edit-question",
      "view-question",
      "copy-question",
      "create-question",
      "assessment-list",
      "view-assessment",
      "copy-assessment",
      "create-assessment",
    ],
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Create A Tag",
        path: `${ComponentUrls.createTag}`,
        component: CreateTag,
      },
      {
        title: "Tag Editor",
        path: `${ComponentUrls.tagEditor}`,
        component: TagEditor,
      },
      {
        title: "Questions",
        path: `#`,
        childTags: [
          "question-list",
          "create-question",
          "edit-question",
          "view-question",
          "copy-question",
        ],
        subMenu: [
          {
            title: "Question List",
            path: `${ComponentUrls.tagManagerQuestions}`,
            component: QuestionList,
            childTags: [
              "question-list",
              "edit-question",
              "view-question",
              "copy-question",
            ],
            state: {
              type: "question-list",
              //mainMenu: true,
            },
          },
          {
            title: "Create A Question",
            path: `${ComponentUrls.tagManagerCreateQuestionType}`,
            component: CreateQuestionType,
            childTags: ["create-question"],
            state: {
              type: "create-question",
            },
          },
          {
            path: `${ComponentUrls.tagManagerCreateQuestion}`,
            component: CreateQuestion,
            state: {
              parentNav: "create-question",
            },
          },
          {
            path: `/${ComponentUrls.tagManagerEditQuestion}`,
            component: EditQuestion,
            state: {
              parentNav: "edit-question",
            },
          },
          {
            path: `/${ComponentUrls.tagManagerTTSEditQuestion}`,
            component: EditQuestion,
            state: {
              parentNav: "edit-question",
              tts: true,
              ttsPage: true,
            },
          },
          {
            path: `/${ComponentUrls.tagManagerViewQuestion}`,
            component: ViewQuestion,
            state: {
              parentNav: "view-question",
            },
          },
          {
            path: `/${ComponentUrls.tagManagerCopyQuestion}`,
            component: CopyQuestion,
            state: {
              parentNav: "copy-question",
            },
          },
        ],
      },
      {
        title: "Assessments",
        path: `#`,
        component: AssessmentList,
        childTags: [
          "assessment-list",
          "view-assessment",
          "copy-assessment",
          "create-assessment",
        ],
        subMenu: [
          {
            title: "Manage Assessments",
            path: `${ComponentUrls.tagManagerAssessments}`,
            component: AssessmentList,
            childTags: [
              "assessment-list",
              "view-assessment",
              "copy-assessment",
            ],
            state: {
              parentNav: "assessment-list",
            },
          },
          {
            title: "Create Assessment",
            path: `${ComponentUrls.tagManagerCreateAssessments}`,
            component: CreateAssessment,
            childTags: ["create-assessment"],
            state: {
              parentNav: "create-assessment",
            },
          },
          {
            path: `/${ComponentUrls.tagManagerViewAssessments}`,
            component: ViewAssessment,
            state: {
              parentNav: "view-assessment",
            },
          },
          {
            path: `/${ComponentUrls.tagManagerEditAssessment}`,
            component: EditAssessment,
            state: {
              parentNav: "view-assessment",
            },
          },
          {
            path: `/${ComponentUrls.tagManagerCopyAssessment}`,
            component: CopyAssessment,
            state: {
              parentNav: "view-assessment",
            },
          },
          {
            path: `${ComponentUrls.tagManagerAssessmentQuestions}`,
            component: AssessmentQuestions,
            state: {
              parentNav: "assessment-list",
            },
          },
        ],
      },
    ],
  },
  {
    title: "Bundle",
    path: `#`,
    icons: <ContentIcon />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: [
      "create-bundle",
      "bundle-questions",
      "bundle-assessments",
      "bundle-list",
      "view-bundle",
    ],
    subMenu: [
      {
        title: "Manage Bundle",
        path: `${ComponentUrls.manageBundle}`,
        component: ManageBundle,
        childTags: [
          "bundle-list",
          "view-bundle",
          "edit-bundle",
          "bundle-questions",
        ],
        state: {
          parentNav: "bundle-list",
        },
      },
      {
        title: "Create Bundle",
        path: `${ComponentUrls.createBundle}`,
        component: CreateBundle,
        state: {
          parentNav: "create-bundle",
        },
        childTags: ["create-bundle"],
      },
      {
        path: `/${ComponentUrls.editBundle}`,
        component: EditBundle,
        state: {
          parentNav: "edit-bundle",
        },
      },
      {
        path: `/${ComponentUrls.viewBundle}`,
        component: ViewBundle,
        state: {
          parentNav: "view-bundle",
        },
      },
      {
        path: `/${ComponentUrls.bundleQuestions}`,
        component: BundleQuestions,
        state: {
          parentNav: "bundle-questions",
        },
      },
      {
        path: `/${ComponentUrls.bundleAssessments}`,
        component: BundleAssessments,
        state: {
          parentNav: "bundle-assessments",
        },
      },
    ],
  },
  {
    title: "Organizations",
    path: "#",
    icons: <OrganizationIcon />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: [
      "district-list",
      "create-district",
      "edit-district",
      "district-admin-list",
      "create-district-admin",
      "edit-district-admin",
      "school-list",
      "edit-school",
      "create-school",
      "school-admin-list",
      "create-school-admin",
      "edit-school-admin",
      "school-admin-list",
      "create-school-admin",
      "edit-school-admin",
    ],
    subMenu: [
      {
        title: "District",
        path: `/${ComponentUrls.organizationsDistrict}`,
        component: OrganizationDistricts,
        childTags: ["district-list", "edit-district", "create-district"],
        state: {
          parentNav: "district-list",
        },
      },
      {
        path: `/${ComponentUrls.organizationCreateDistrict}`,
        component: OrganizationCreateDistricts,
        childTags: ["create-district"],
        state: {
          parentNav: "create-district",
        },
      },
      {
        path: `/${ComponentUrls.organizationEditDistrict}`,
        component: OrganizationEditDistrict,
        state: {
          parentNav: "edit-district",
        },
      },
      {
        title: "District Admin",
        path: `/${ComponentUrls.organizationsDistrictAdmin}`,
        component: OrganizationDistrictAdmins,
        childTags: [
          "district-admin-list",
          "create-district-admin",
          "edit-district-admin",
        ],
        state: {
          parentNav: "district-admin-list",
        },
      },
      {
        path: `/${ComponentUrls.orgCreateDistrictAdmin}`,
        component: OrganizationCreateDistrictAdmin,
        state: {
          parentNav: "create-district-admin",
        },
      },
      {
        path: `/${ComponentUrls.orgEditDistrictAdmin}`,
        component: OrganizationEditDistrictAdmin,
        state: {
          parentNav: "edit-district-admin",
        },
      },
      {
        title: "School",
        path: `/${ComponentUrls.organizationsSchool}`,
        component: OrganizationSchools,
        childTags: ["school-list", "edit-school", "create-school"],
        state: {
          parentNav: "school-list",
        },
      },
      {
        path: `/${ComponentUrls.orgCreateSchool}`,
        component: OrganizationCreateSchool,
        state: {
          parentNav: "create-school",
        },
      },
      {
        path: `/${ComponentUrls.orgEditSchool}`,
        component: OrganizationEditSchool,
        state: {
          parentNav: "edit-school",
        },
      },
      
      {
        title: "School Admin",
        path: `/${ComponentUrls.organizationsSchoolAdmin}`,
        childTags: [
          "school-admin-list",
          "create-school-admin",
          "edit-school-admin",
        ],
        component: OrganizationSchoolAdmin,
        state: {
          parentNav: "school-admin-list",
        },
      },
      {
        path: `/${ComponentUrls.orgCreateSchoolAdmin}`,
        component: OrganizationCreateSchoolAdmin,
        state: {
          parentNav: "create-school-admin",
        },
      },
      {
        path: `/${ComponentUrls.orgEditSchoolAdmin}`,
        component: OrganizationEditSchoolAdmin,
        state: {
          parentNav: "edit-school-admin",
        },
      },
    ],
  },
  // {
  //   title: "School District",
  //   path: "/SchoolDistrict",
  //   icons: <IoIcons.IoMdSchool />,
  //   iconsOpened: <RiIcons.RiArrowDownSLine />,
  //   subMenu: [
  //     {
  //       title: "School District List",
  //       path: "/SchoolDistrictList",
  //     },
  //     {
  //       title: "District Admin",
  //       path: "/DistrictAdmin",
  //     },
  //   ],
  // },
  {
    title: "Roster",
    path: `#`,
    icons: <FiIcons.FiUser />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: ["class-list", "teacher-list", "student-list"],
    subMenu: [
      {
        title: "Classes",
        path: `/${ComponentUrls.classRosters}`,
        component: MyClasses,
        state: {
          type: "class-list",
        },
        childTags: ["class-list"],
      },
      // {
      //   path: ComponentUrls.addClassRosters,
      //   component: AddClass,
      //   state: {
      //     parentNav: "class-list",
      //   },
      // },
      {
        title: "Teachers",
        path: `/${ComponentUrls.teacherRoster}`,
        state: {
          type: "teacher-list",
        },
        childTags: ["teacher-list"],
        component: TeacherList,
      },
      {
        title: "Students",
        path: `/${ComponentUrls.studentRosters}`,
        state: {
          type: "student-list",
        },
        childTags: ["student-list", "promote-students"],
        component: StudentList,
      },
      {
        path: `/${ComponentUrls.promoteStudents}`,
        component: PromoteStudents,
        state: {
          parentNav: "promote-students",
        },
      },
    ],
  },
  {
    title: "License",
    path: `#`,
    icons: <LicenseIcon />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: [
      "license-list",
      "create-license",
      "license-preview",
      "license-district",
      "license-terms",
      "license-content",
    ],
    subMenu: [
      {
        title: "Manage License",
        path: `/${ComponentUrls.manageLicense}`,
        component: ManageLicense,
        state: {
          type: "license-list",
        },
        childTags: [
          "license-list",
          "license-preview",
          "license-district",
          "license-terms",
          "license-content",
        ],
      },
      {
        title: "Create License",
        path: `/${ComponentUrls.createLicense}`,
        component: CreateLicenseDistrict,
        state: {
          type: "create-license",
        },
        childTags: ["create-license"],
      },
      {
        path: `/${ComponentUrls.manageLicensePreview}`,
        component: ManageLicensePreview,
        state: {
          type: "license-preview",
        },
      },
      {
        path: `/${ComponentUrls.createLicenseContent}`,
        component: CreateLicenseContent,
        state: {
          type: "license-content",
        },
      },
      {
        path: `/${ComponentUrls.createLicenseTerm}`,
        component: CreateLicenseTerm,
        state: {
          type: "license-terms",
        },
      },
      {
        path: `/${ComponentUrls.createLicensePreview}`,
        component: CreateLicensePreview,
        state: {
          type: "license-preview",
        },
      },
      {
        path: `/${ComponentUrls.editLicense}`,
        component: EditLicenseDistrict,
        state: {
          type: "license-district",
        },
      },
      {
        path: `/${ComponentUrls.editLicenseContent}`,
        component: EditLicenseContent,
        state: {
          type: "license-content",
        },
      },
      {
        path: `/${ComponentUrls.editLicenseTerm}`,
        component: EditLicenseTerm,
        state: {
          type: "license-terms",
        },
      },
      {
        path: `/${ComponentUrls.editLicensePreview}`,
        component: EditLicensePreview,
        state: {
          type: "license-preview",
        },
      },

      {
        title: "Manage Access Code",
        path: `/${ComponentUrls.manageAccessCode}`,
        component: accessCodeLicense,
      },
      {
        title: "Generate Access Code",
        path: `/${ComponentUrls.generateAccessCode}`,
        component: generateAccessCode,
      },
    ],
  },
  {
    title: "Report",
    path: "#",
    icons: <AiIcons.AiOutlineUnorderedList />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Standard Reports",
        path: "/StandardReports",
      },
    ],
  },
  {
    title: "User",
    path: "#",
    icons: <FiIcons.FiUser />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Manage User",
        path: "/ManageUser ",
      },
      {
        title: "Manage Roles",
        path: "/ManageRoles",
      },
      {
        title: "Merge Account",
        path: "/MergeAccount",
      },
    ],
  },
  {
    title: "System Management",
    path: `#`,
    icons: <FiIcons.FiUser />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: ["data-archive", "LTI-Set-Up", "tag-manager"],
    subMenu: [
      {
        title: "Data Archive",
        path: `/${ComponentUrls.dataArchive}`,
        component: DataArchive,
        state: {
          type: "data-archive",
        },
        childTags: ["data-archive"],
      },
      {
        title: "LTI Set Up",
        path: "/LTISetUp ",
      },
      {
        title: "Tag Manager",
        path: "/TagManager",
      },
    ],
  },
  {
    title: "Help",
    path: `/${ComponentUrls.help}`,
    state: {
      type: "help",
    },
    icons: <IoIcons.IoMdHelpCircleOutline />,
    component: Help,
  },
];
