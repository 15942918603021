/* eslint-disable */
import axios from "axios";
import { cors } from "../utils/cors";
import Auth from "../services/Auth";
// import { getAccessToken } from ".."
import History from "../routes/History";
import Emitter from "../libs/Emitter";
import { msalConfig } from "../utils/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import Cookie from "universal-cookie";
import { toast } from "react-toastify";

export const refreshAccessToken = async () => {
  const pca = new PublicClientApplication(msalConfig);
  const accounts = pca.getAllAccounts();
  const request = {
    ...msalConfig,
    account: accounts[0],
  };
  try {
    const resp = await pca.acquireTokenSilent(request);
    Auth.setTokenExpiresIn(resp.expiresOn);
    Auth.setToken(resp.accessToken);
    Auth.removeTokenWaiting();
    return resp;
  } catch (error) {
    return pca
      .acquireTokenRedirect(request)
      .then((resp) => {
        Auth.setTokenExpiresIn(resp.expiresOn);
        Auth.setToken(resp.accessToken);
        Auth.removeTokenWaiting();
        return resp;
      })
      .catch((err) => {
        console.log(err);
        Auth.removeTokenWaiting();
        History.push("/");
      });
  }
};

export const getTokenOrRefresh = async () => {
  const cookie = new Cookie();
  const speechToken = cookie.get("speech-token");

  if (speechToken === undefined) {
    // SPEECH_KEY=57aa7c908ebb44fa927aace219f1f8ac
    // SPEECH_REGION=eastus
    const speechKey = "57aa7c908ebb44fa927aace219f1f8ac";
    const speechRegion = "eastus";
    const headers = {
      headers: {
        "Ocp-Apim-Subscription-Key": speechKey,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const tokenResponse = await axios.post(
        `https://${speechRegion}.api.cognitive.microsoft.com/sts/v1.0/issueToken`,
        null,
        headers
      );
      const token = tokenResponse.data;
      const region = speechRegion;
      cookie.set("speech-token", region + ":" + token, {
        maxAge: 540,
        path: "/",
      });
      return { authToken: token, region: region };
    } catch (err) {
      return { authToken: null, region: null, error: err.response.data };
    }
  } else {
    const idx = speechToken.indexOf(":");
    return {
      authToken: speechToken.slice(idx + 1),
      region: speechToken.slice(0, idx),
    };
  }
};

export default class Request {
  constructor(headers) {
    cors();
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: false,
    });

    this.http.interceptors.request.use(async (config) => {
      // await eventEmitter
      let token = Auth.getToken();
      if (headers?.responseType) {
        config.responseType = headers?.responseType;
      }
      config.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        // "Content-Type": "application/json",
        ...headers,
      };
      return config;
    });

    this.http.interceptors.response.use(
      async (response) => {
        // check if there is some error message to display on UI, even http status is 200.
        if (
          response?.data?.success === false &&
          response?.data?.displayFailMessage?.length > 1
        ) {
          toast.error(response?.data?.displayFailMessage);
        } else if (
          response?.data?.success === false &&
          response?.data?.errorDetail?.length > 1
        ) {
          toast.error(response?.data?.displayFailMessage);
        }
        return response;
      },
      async (error) => {
        // console.log("error", error.config.url);
        Emitter.emit("HTTP_REQUEST_ERROR");
        const originalRequest = error.config;
        if (error.response) {
          // forbidden: 403
          // unauthrized: 401
          // not found: 400
          if (error.response.status && error.response.status === 403) {
            return Promise.reject(error.response.data);
          } else if (
            error.response.status &&
            (error.response.status === 401 || error.response.status === 415) &&
            !originalRequest._retry
          ) {
            console.log("invalid token, going to acquire token again");
            Emitter.emit("INVALID_TOKEN");
            originalRequest._retry = true;
            const tokenResp = await refreshAccessToken();
            if (tokenResp) {
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + tokenResp?.accessToken;
            }
            return this.http(originalRequest);
            // Emitter.emit("validateToken");
            // return Promise.reject(error.response.data);
          } else {
            let errorData = error?.response?.data;
            if (error?.request?.responseType === "blob") {
              errorData = JSON.parse(await error.response.data.text());
              if (error.response.status === 500) {
                errorData.displayFailMessage =
                  "We're sorry, but a program error has occurred. Please contact NTN support";
              }
            }
            // only for security api so that we can redirect to invalid-login page with message, that can be modified later for all requests
            if (
              (error.response.status === 0 || error.response.status === 500) &&
              errorData === undefined &&
              error.config.url === "Security"
            ) {
              errorData = {
                errorType: "custom",
                displayFailMessage:
                  "We're sorry, but a program error has occurred. Please contact NTN support",
              };
            }

            if (errorData) {
              if (errorData?.displayFailMessage?.length > 1) {
                toast.error(errorData.displayFailMessage);
              } else {
                toast.error(errorData.errorDetail);
              }
            }
            return Promise.reject(errorData);
          }
        } else if (error.request) {
          return Promise.reject(error.request);
        } else {
          return Promise.reject(error);
        }
      }
    );
    for (const method of ["get", "post", "put", "delete"]) {
      this[method] = this.http[method];
    }
  }
}
