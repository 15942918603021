import * as types from "./actionTypes";
const initialState = {
  loading: false,
  coppa: null,
};
const coppaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COPPA:
      return {
        ...state,
        coppa: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default coppaReducer;
