export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
export const SET_CLASS_LIST = "SET_CLASS_LIST";
export const SET_SCHOOL_LIST = "SET_SCHOOL_LIST";
export const SET_DISTRICT_LIST = "SET_DISTRICT_LIST";
export const SET_TEACHER_LIST = "SET_TEACHER_LIST";
export const SET_FILTER_LIST = "SET_FILTER_LIST";

// BULK UPLOADS
export const SET_CLASS_BULK_UPLOAD = "SET_CLASS_BULK_UPLOAD";
export const SET_TEACHER_BULK_UPLOAD = "SET_TEACHER_BULK_UPLOAD";
export const SET_STUDENT_BULK_UPLOAD = "SET_STUDENT_BULK_UPLOAD";
