import React, { useEffect, useState } from "react";
import Header, { PublicHeader } from "../components/Header";
import Footer from "../components/Footer/Footer";
import { Router, matchPath, Redirect } from "react-router-dom";
import history from "../routes/History";
import { CombinedRoutes } from "../routes/Routes";
import Loader from "../components/Loader/Loader";
import Container from "react-bootstrap/Container";
import Toastify from "../components/Toastify";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import { useAuth } from "../hooks";
import { isTokenAboutToExpire } from "../utils/Common";
import ImageDialogModel from "../components/Modals/ImageDialog";
import {
  commonComponents,
  componentRoutes,
} from "../utils/constants/component-routes";
import { PublicFooter } from "../components/Footer";
export function CombinedLayout(props) {
  const { getAccessToken } = useAuth();
  const [imageDialogData, setImageDialogData] = useState(null);
  useEffect(() => {
    const fetchToken = async () => {
      if (isTokenAboutToExpire()) {
        await getAccessToken();
      }
    };
    fetchToken();

    // const fetchSystemAlerts = async () => {
    //   //await handleGetSystemAlerts();
    // };
    // fetchSystemAlerts();
  }, []);

  const imageDialog = useSelector((state) => state.global.imageDialog);
  useEffect(() => {
    setImageDialogData(imageDialog);
  }, [imageDialog]);

  const loading = useSelector((state) => state.global.loading);
  // secondary loader to handle asynchronouse call
  const assessmentLoading = useSelector((state) => state.assessment.loading);

  const hasCommonComponent = () => {
    const currentPageRoute = commonComponents.find((p) => {
      return matchPath(history.location.pathname, {
        path: p.path,
        exact: true,
        strict: true,
      });
    });
    return currentPageRoute;
  };
  const currentComponent = hasCommonComponent();
  return (
    <Container fluid={true} className="p-0">
      {!loading && imageDialogData?.url ? (
        <ImageDialogModel {...imageDialogData}></ImageDialogModel>
      ) : null}
      {loading ? <Loader isLoading={loading} /> : null}
      {assessmentLoading ? <Loader isLoading={assessmentLoading} /> : null}
      <Router history={history}>
        <Toastify />
        {currentComponent &&
        currentComponent.public &&
        !(currentComponent.path === "/" && props?.authenticated) ? (
          currentComponent.header ? (
            <>
              <PublicHeader />
              <div className="public-wrapper">
                <Container>{<CombinedRoutes />}</Container>
              </div>
              <PublicFooter />
            </>
          ) : (
            <>
              <CombinedRoutes />
            </>
          )
        ) : (
          <>
            {currentComponent === undefined &&
            props?.authenticated === false ? (
              <Redirect to={componentRoutes.logIn} />
            ) : null}
            <Header />
            <div className="wrapper">
              <Sidebar />
              <Container id="content">{<CombinedRoutes />}</Container>
            </div>
            <Footer />
          </>
        )}
      </Router>
    </Container>
  );
}
export default CombinedLayout;
