import {
  rosterDeleteClassAction,
  rosterClassListAction,
  rosterSaveClassAction,
  rosterClassFilterAction,
  rosterStudentListAction,
  rosterClassListFilterAction,
  rosterTransferStudentAction,
  rosterDeleteStudentAction,
  rosterDeactivateStudentAction,
  rosterReactivateStudentAction,
  rosterGetStudentByIdAction,
  rosterClassBulkUploadAction,
  rosterStudentBulkUploadAction,
  rosterTeacherBulkUploadAction,
} from "../store/roster/actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
export const useRoster = () => {
  const rosterData = useSelector((state) => state.roster);
  const dispatch = useDispatch();
  const [classList, setClassList] = useState(null);
  const [schoolList, setSchoolList] = useState(null);
  const [studentsList, setStudentsList] = useState(null);
  const [districtList, setDistrictList] = useState(null);
  const [sortedStudentsList, setSortedStudentsList] = useState(null);

  useEffect(() => {
    if (rosterData.classList) {
      setClassList(rosterData.classList);
    }

    if (rosterData.schoolList) {
      setSchoolList(rosterData.schoolList);
    }

    if (rosterData.districtList) {
      setDistrictList(rosterData.districtList);
    }

    if (rosterData.studentList) {
      setStudentsList(rosterData.studentList);
      setSortedStudentsList(rosterData.studentList);
    }
  }, [rosterData]);

  const handleRosterDeleteClass = async (request) => {
    return await dispatch(rosterDeleteClassAction(request));
  };

  const handleRosterClassList = async (request) => {
    return await dispatch(rosterClassListAction(request));
  };

  const handleRosterClassFilters = async (request) => {
    return await dispatch(rosterClassFilterAction(request));
  };

  const handleRosterClassListFilter = async (request) => {
    return await dispatch(rosterClassListFilterAction(request));
  };

  const handleRosterStudentList = async (request) => {
    return await dispatch(rosterStudentListAction(request));
  };
  const handleRosterSaveClass = async (request) => {
    return await dispatch(rosterSaveClassAction(request));
  };

  const handleRosterTransferStudent = async (request) => {
    return await dispatch(rosterTransferStudentAction(request));
  };

  const handleRosterGetStudentById = async (request) => {
    return await dispatch(rosterGetStudentByIdAction(request));
  };

  const getClassById = (id) => {
    const classData = rosterData.classList?.find((a) => a.classId == id);
    return classData;
  };

  const getStudentById = (id) => {
    const studentData = rosterData.studentList?.find((a) => a.studentId == id);
    return studentData;
  };
  //roster student
  const handleRosterDeleteStudent = async (request) => {
    return await dispatch(rosterDeleteStudentAction(request));
  };

  const handleRosterDeactivateStudent = async (request) => {
    return await dispatch(rosterDeactivateStudentAction(request));
  };

  const handleRosterReactivateStudent = async (request) => {
    return await dispatch(rosterReactivateStudentAction(request));
  };

  const handleStudentSort = (params) => {
    let sortedStudents = studentsList;

    switch (params.sortBy) {
      case "studentFirstNameZA":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.studentFirstName.toLowerCase() < b.studentFirstName.toLowerCase()
            ? 1
            : -1
        );
        break;
      case "studentFirstNameAZ":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.studentFirstName.toLowerCase() > b.studentFirstName.toLowerCase()
            ? 1
            : -1
        );
        break;

      case "studentLastNameZA":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.studentLastName.toLowerCase() < b.studentLastName.toLowerCase()
            ? 1
            : -1
        );
        break;
      case "studentLastNameAZ":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.studentLastName.toLowerCase() > b.studentLastName.toLowerCase()
            ? 1
            : -1
        );
        break;

      case "userNameZA":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.userName.toLowerCase() < b.userName.toLowerCase() ? 1 : -1
        );
        break;
      case "userNameAZ":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1
        );
        break;

      case "gradeNameZA":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.gradeName.toLowerCase() < b.gradeName.toLowerCase() ? 1 : -1
        );
        break;
      case "gradeNameAZ":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.gradeName.toLowerCase() > b.gradeName.toLowerCase() ? 1 : -1
        );
        break;

      case "coppaStatusZA":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.coppaStatus.toLowerCase() < b.coppaStatus.toLowerCase() ? 1 : -1
        );
        break;
      case "coppaStatusAZ":
        sortedStudents = sortedStudents?.sort((a, b) =>
          a.coppaStatus.toLowerCase() > b.coppaStatus.toLowerCase() ? 1 : -1
        );
        break;

      default:
        break;
    }
    setSortedStudentsList(sortedStudents);
    return sortedStudents;
  };

  // BULK UPLOADS

  const handleClassesBulkUploads = async (request) => {
    return await dispatch(rosterClassBulkUploadAction(request));
  };

  const handleTeacherBulkUploads = async (request) => {
    return await dispatch(rosterTeacherBulkUploadAction(request));
  };

  const handleStudentBulkUploads = async (request) => {
    return await dispatch(rosterStudentBulkUploadAction(request));
  };

  return {
    handleRosterDeleteClass,
    handleRosterClassList,
    handleRosterSaveClass,
    getClassById,
    getStudentById,
    handleRosterGetStudentById,
    classList,
    schoolList,
    districtList,
    handleStudentSort,
    sortedStudentsList,
    handleRosterClassFilters,
    handleRosterStudentList,
    handleRosterClassListFilter,
    handleRosterTransferStudent,
    handleRosterDeleteStudent,
    handleRosterReactivateStudent,
    handleRosterDeactivateStudent,

    //BULK UPLOADS
    handleClassesBulkUploads,
    handleTeacherBulkUploads,
    handleStudentBulkUploads,
  };
};

export default useRoster;
