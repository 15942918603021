import {
  reportStrandListAction,
  reportAssessmentListAction,
  reportGradeListAction,
  reportAssessmentTypesListAction,
  reportClassListAction,
  reportSchoolListAction,
  reportStandardListAction,
  reportTeacherListAction,
  reportClassGroupListAction,
  setReportFilterDataAction,
  reportAssignmentTypeListAction,
  reportStudentListAction,
  setClassReportFilterDataAction,
  setStandardReportFilterDataAction,
  setReportsStepsAction,
  reportClassGroupOptionsListAction,
  downloadReportAction,
  getFilterDefaultsAction,
  getQuestionsHTMLAction,
  getReportEmbedTokenAction,
  getReportIdEmbedTokenAction,
} from "../store/report/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { reportEnumByReportSectionId } from "../utils/constants/reports";
export const useReport = () => {
  const reports = useSelector((state) => state.report);
  const [classList, setClassList] = useState(null);
  const [assessmentList, setAssessmentList] = useState(null);
  const [assignmentTypeList, setAssignmentTypeList] = useState(null);
  const [gradeList, setGradeList] = useState(null);
  const [assessmentTypesList, setAssessmentTypesList] = useState(null);
  const [schoolList, setSchoolList] = useState(null);
  const [standardList, setStandardList] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [reportFilterData, setReportFilterData] = useState(null);
  const [classReportFilterData, setClassReportFilterData] = useState(null);
  const [standardReportFilterData, setStandardReportFilterData] =
    useState(null);

  const [classGroupList, setClassGroupList] = useState(null);
  const [reportStepsData, setReportStepsData] = useState(null);
  useEffect(() => {
    setClassList(reports.classList);
    setAssessmentList(reports.assessmentList);
    setGradeList(reports.gradeList);
    setAssessmentTypesList(reports.assessmentTypesList);
    setSchoolList(reports.schoolList);
    setStandardList(reports.standardList);
    setTeacherList(reports.teacherList);
    setReportFilterData(reports.reportFilterData);
    setClassReportFilterData(reports.classReportFilterData);
    setStandardReportFilterData(reports.standardReportFilterData);
    setAssignmentTypeList(reports.assignmentTypeList);
    setClassGroupList(reports.classGroupList);
    setReportStepsData(reports.reportSteps);
  }, [reports]);

  const dispatch = useDispatch();

  const handleGetReportEmbedTokenAction = async (request) => {
    return await dispatch(getReportEmbedTokenAction(request));
  };

  const handleGetReportIdEmbedTokenAction = async (request) => {
    console.log("reportSectionId", request?.reportSectionId);
    const _request = {
      reportEnumId: reportEnumByReportSectionId[request?.reportSectionId] ?? 0,
    };
    return await dispatch(getReportIdEmbedTokenAction(_request));
  };

  const handleReportAssessmentListAction = async (request) => {
    return await dispatch(reportAssessmentListAction(request));
  };

  const handleReportStrandListAction = async (request) => {
    return await dispatch(reportStrandListAction(request));
  };

  const handleReportAssignmentTypeListAction = async (request) => {
    return await dispatch(reportAssignmentTypeListAction(request));
  };

  const handleReportGradeListAction = async (request) => {
    return await dispatch(reportGradeListAction(request));
  };

  const handleReportStudentListAction = async (request) => {
    return await dispatch(reportStudentListAction(request));
  };

  const handleReportAssessmentTypesListAction = async (request) => {
    return await dispatch(reportAssessmentTypesListAction(request));
  };

  const handleReportClassListAction = async (request) => {
    return await dispatch(reportClassListAction(request));
  };

  const handleReportClassGroupOptionsListAction = async (request) => {
    return await dispatch(reportClassGroupOptionsListAction(request));
  };

  const handleReportSchoolListAction = async (request) => {
    return await dispatch(reportSchoolListAction(request));
  };

  const handleReportStandardListAction = async (request) => {
    return await dispatch(reportStandardListAction(request));
  };

  const handleReportTeacherListAction = async (request) => {
    return await dispatch(reportTeacherListAction(request));
  };

  const handleReportClassGroupListAction = async (request) => {
    return await dispatch(reportClassGroupListAction(request));
  };

  const handleSetReportFilterDataAction = async (request) => {
    return await dispatch(setReportFilterDataAction(request));
  };

  const handleSetClassReportFilterDataAction = async (request) => {
    return await dispatch(setClassReportFilterDataAction(request));
  };

  const handleSetStandardReportFilterDataAction = async (request) => {
    return await dispatch(setStandardReportFilterDataAction(request));
  };

  const handleSetReportsStepAction = async (request) => {
    return await dispatch(setReportsStepsAction(request));
  };

  const handleDownloadreportAction = async (request) => {
    return await dispatch(downloadReportAction(request));
  };

  const handleGetFilterDefaultsAction = async (request) => {
    return await dispatch(getFilterDefaultsAction(request));
  };

  const handleGetQuestionsHTMLAction = async (request) => {
    return await dispatch(getQuestionsHTMLAction(request));
  };

  //getFilterDefaults
  return {
    handleGetReportEmbedTokenAction,
    handleGetReportIdEmbedTokenAction,
    handleReportAssessmentListAction,
    handleReportGradeListAction,
    handleReportStudentListAction,
    handleReportAssessmentTypesListAction,
    handleReportClassListAction,
    handleReportSchoolListAction,
    handleReportStandardListAction,
    handleReportStrandListAction,
    handleReportTeacherListAction,
    handleReportClassGroupListAction,
    handleSetReportFilterDataAction,
    handleReportAssignmentTypeListAction,
    handleSetClassReportFilterDataAction,
    handleSetStandardReportFilterDataAction,
    handleSetReportsStepAction,
    handleReportClassGroupOptionsListAction,
    handleDownloadreportAction,
    handleGetFilterDefaultsAction,
    handleGetQuestionsHTMLAction,
    classList,
    classGroupList,
    assessmentList,
    gradeList,
    assessmentTypesList,
    schoolList,
    standardList,
    teacherList,
    reportFilterData,
    assignmentTypeList,
    classReportFilterData,
    standardReportFilterData,
    reportStepsData,
  };
};
