import * as types from "./actionTypes";
const initialState = {
  loading: false,
  bundleList: null,
  filterList: null,
  bundleRequest: null,
};
const bundleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BUNDLE_LIST:
      return {
        ...state,
        bundleList: action.payload,
        loading: false,
      };
    case types.SET_BUNDLE_FILTER_LIST:
      return {
        ...state,
        filterList: action.payload,
        loading: false,
      };
    case types.SET_BUNDLE_REQUEST:
      return {
        ...state,
        bundleRequest: action.payload,
        loading: false,
      };
    case types.SET_BUNDLE:
      let bundleList = state.bundleList ? [...state.bundleList] : [];
      const selectedIndex = bundleList.findIndex(
        (b) => b.id === action.payload.id
      );
      if (selectedIndex > -1) {
        bundleList.splice(selectedIndex, 1, action.payload);
      }
      return {
        ...state,
        bundleList: bundleList,
        loading: false,
      };
    default:
      return state;
  }
};

export default bundleReducer;
