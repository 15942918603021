import React, { useEffect, useState } from "react";
import NavBar from "./Navbar";
import { natigations } from "./../../utils/constants/navigations";
import { useNtnRedirects, useUser } from "../../hooks";
import { useLocation } from "react-router-dom";
const Sidebar = () => {
  const location = useLocation();
  const { state } = location;
  const [menuItems, setMenuItems] = useState([]);
  const { userProfileData, sidebarActiveMenu } = useUser();
  const { getActiveRoute } = useNtnRedirects();
  const role = userProfileData?.role;
  const activeRoute = getActiveRoute();
  const prepareNavigations = (navItems) => {
    navItems.map((nav) => {
      if (nav.childTags && nav?.childTags?.length) {
        // set active
        nav.expanded = false;
        const parentNavId = state?.dataType
          ? state.dataType
          : activeRoute?.state?.parentNav
          ? activeRoute?.state?.parentNav
          : activeRoute?.state?.type;
        nav.active = nav.childTags.includes(parentNavId);
        if (nav?.subMenu && nav.active) {
          nav.expanded = true;
        }
      }
      if (nav?.subMenu) {
        //nav.subMenu.map((child) => {
        nav.subMenu = prepareNavigations(nav.subMenu);
        //});
      }
      return nav;
    });
    return navItems;
  };
  useEffect(() => {
    let navItems = natigations(role);
    navItems = prepareNavigations(navItems);
    setMenuItems(navItems);
  }, [role, location]);
  return <>{menuItems?.length ? <NavBar items={menuItems} /> : null}</>;
};

export default Sidebar;
