//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const questionsListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.questionsList(request)
      .then((response) => {
        dispatch({
          type: types.STORE_QUESTIONS_REQUEST,
          payload: response.data?.questions,
        });
        dispatch(apiLoadingStop());

        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

/// PURE REDUX ACTIONS

export const storeQuestions = (payload) => ({
  type: types.STORE_QUESTIONS_REQUEST,
  payload: payload,
});

export const _removeQuestions = (payload) => ({
  type: types.REMOVE_QUESTIONS_REQUEST,
});

export const _addUpdateSelectedQuestion = (payload) => ({
  type: types.ADD_UPDATE_SELECTED_QUESTIONS,
  payload: payload,
});

export const _addSelectedQuestion = (payload) => ({
  type: types.ADD_SELECTED_QUESTIONS,
  payload: payload,
});

export const _removeSelectedQuestion = (payload) => ({
  type: types.REMOVE_SELECTED_QUESTIONS,
  payload: payload,
});

export const _updateSelectedQuestion = (payload) => ({
  type: types.UPDATE_SELECTED_QUESTIONS,
  payload: payload,
});

export const _replaceAllSelectedQuestions = (payload) => ({
  type: types.REPLACE_ALL_SELECTED_QUESTIONS,
  payload: payload,
});
export const _clearQuestionStore = () => ({
  type: types.CLEAR_QUESTIONS_STORE,
});

export const _clearSelectedQuestions = (payload) => ({
  type: types.CLEAR_SELECTED_QUESTIONS,
});
