export const STORE_QUESTIONS_REQUEST = "STORE_QUESTIONS_REQUEST";
export const ADD_UPDATE_SELECTED_QUESTIONS = "ADD_UPDATE_SELECTED_QUESTIONS";
export const ADD_SELECTED_QUESTIONS = "ADD_SELECTED_QUESTIONS";
export const UPDATE_SELECTED_QUESTIONS = "UPDATE_SELECTED_QUESTIONS";
export const REMOVE_SELECTED_QUESTIONS = "REMOVE_SELECTED_QUESTIONS";
export const REPLACE_ALL_SELECTED_QUESTIONS = "REPLACE_ALL_SELECTED_QUESTIONS";
export const CLEAR_QUESTIONS_STORE = "CLEAR_QUESTIONS_STORE";
export const CLEAR_SELECTED_QUESTIONS = "CLEAR_SELECTED_QUESTIONS";
export const REMOVE_QUESTIONS_REQUEST = "REMOVE_QUESTIONS_REQUEST";
export const GET_QUESTIONS_REQUEST = "GET_QUESTIONS_REQUEST";
