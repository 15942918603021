import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import "./style.scss";
export default function TagInput(props) {
  const [tagsList, setTagsList] = useState([]);
  const clearAll = () => {
    setTagsList([]);
    props.eventHandlers.onClearTag([]);
  };
  const removeTag = (tag) => {
    const allTags = [...tagsList];
    const index = allTags.indexOf(tag);
    allTags.splice(index, 1);
    setTagsList(allTags);
    props.eventHandlers.onRemoveTag(tag);
  };

  useEffect(() => {
    setTagsList(props.tags);
  }, [props.tags]);
  return (
    <>
      <Col className="p-0" md={"auto"}>
        <span
          onClick={() => props.eventHandlers.onShowHide()}
          className="pseudo-link"
        >
          {props?.toggle ? `Hide` : `View`} Tags
        </span>
      </Col>
      <Col
        className={
          props?.toggle ? `showtags tag-border` : `hidetags tag-border`
        }
      >
        <Col className="tag-container">
          {tagsList?.map((t, index) => {
            return t.type === "spacer" ? (
              <div key={index} className="spacer-element">
                {""}
              </div>
            ) : (
              <Button key={index} size="sm">
                {props?.allowDelete ? (
                  <IoClose onClick={() => removeTag(t)} />
                ) : null}
                {typeof t === "string" ? t : t.label}
              </Button>
            );
          })}
        </Col>
        {props?.deleteAll && tagsList?.length ? (
          <Col className="flex-align-end-right mt-2">
            <Button onClick={clearAll} size="sm" variant="secondary">
              Delete All <BsTrash />
            </Button>
          </Col>
        ) : null}
      </Col>
    </>
  );
}
