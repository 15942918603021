import { getCoppaAction, submitCoppaAction } from "../store/coppa/actions";
import { useDispatch } from "react-redux";
export const useCoppa = () => {
  const dispatch = useDispatch();
  const getCoppa = async (request) => {
    return await dispatch(getCoppaAction(request));
  };
  const submitCoppa = async (request) => {
    return await dispatch(submitCoppaAction(request));
  };
  return {
    getCoppa,
    submitCoppa,
  };
};
