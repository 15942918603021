import React from "react";
// import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Auth from "../services/Auth";
import { componentRoutes } from "../utils/constants/component-routes";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is authrized
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuth() ? (
          <Component {...props} state={{ ...rest?.state }} />
        ) : (
          <Redirect to={componentRoutes.logIn} />
        )
      }
    />
  );
};

export default PrivateRoute;
