import { useState, useEffect } from "react";
export const useDeviceDetector = () => {
  const [deviceType, setDeviceType] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
      setIsDesktop(false);
      setIsMobile(true);
    } else {
      setDeviceType("Desktop");
      setIsMobile(false);
      setIsDesktop(true);
    }
  }, []);

  return {
    deviceType,
    isMobile,
    isDesktop,
  };
};

export const useWindowFocusHandler = () => {
  const [windowIsActive, setWindowIsActive] = useState(null);
  // User has switched back to the tab
  const onFocus = () => {
    setWindowIsActive(true);
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    setWindowIsActive(false);
  };

  const handleActivity = (forcedFlag) => {
    if (document.visibilityState === "visible") {
      setWindowIsActive(true);
    } else {
      setWindowIsActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    document.addEventListener("visibilitychange", handleActivity);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      document.removeEventListener("visibilitychange", handleActivity);
    };
  }, []);

  return {
    windowIsActive,
  };
};
