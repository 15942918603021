import {
  useHistory,
  generatePath,
  useLocation,
  matchPath,
} from "react-router-dom";
import { ComponentUrls } from "../utils/constants/component-names";
import { getPageRoutes } from "../utils/constants/pages";
import { useUser } from "./user-hooks";
export const useNtnRedirects = () => {
  const history = useHistory();
  const location = useLocation();
  const { userRole } = useUser();

  const getActiveRoute = () => {
    const allPageRoutes = getPageRoutes(userRole);
    let currentPageRoute = allPageRoutes.find((p) => {
      return matchPath(location.pathname, {
        path: p.path,
        exact: true,
        strict: true,
      });
    });
    return currentPageRoute;
  };

  const getActiveComponent = () => {
    const allPageRoutes = getPageRoutes(userRole);

    // console.log("allPageRoutes", allPageRoutes);

    let currentPageRoute = allPageRoutes.find((p) => {
      return matchPath(location.pathname, {
        path: p.path,
        exact: true,
        strict: true,
      });
    });

    // for home page only
    if (currentPageRoute && currentPageRoute.path === "/") {
      currentPageRoute = allPageRoutes[1];
    } else {
      //parentNav;
      // console.log("currentPageRoute.path", currentPageRoute);
      const parent = allPageRoutes.find((p) => {
        if (p.childTags && currentPageRoute?.state?.parentNav) {
          return p?.childTags.includes(currentPageRoute.state.parentNav);
        }
      });
      if (parent) {
        currentPageRoute = parent;
      }
      // console.log("parent.path", parent);
    }
    return currentPageRoute;
  };
  const ntnGeneratePath = (dynamicPath, params) => {
    return generatePath(`${dynamicPath}`, params);
  };

  const redirectEditAssessment = (assessmentId, fromPage) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.editAssessment}`, {
        assessmentId,
      }),
      params: { action: "edit" },
      state: { action: "edit", fromPage },
    });
  };

  const redirectCopyAssessment = (assessmentId, fromPage) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.copyAssessment}`, {
        assessmentId,
      }),
      params: { action: "copy" },
      state: { action: "copy", fromPage },
    });
  };

  const redirectViewAssessment = (assessmentId, dataType, fromPage) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.assessmentDetail}`, {
        assessmentId,
      }),
      params: { dataType: dataType },
      state: { dataType: dataType, fromPage },
    });
  };

  const redirectViewAssignment = (assignmentId, assessmentId = 0) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.assignedDetail}`, {
        assignmentId: assignmentId,
        assessmentId: assessmentId,
      }),
    });
  };

  const redirectStudentAssignedAssignment = () => {
    history.push({
      pathname: `/${ComponentUrls.assignedAssessments}`,
    });
  };

  const redirectStudentSubmittedAssignment = () => {
    history.push({
      pathname: `/${ComponentUrls.submittedAssessments}`,
    });
  };

  const redirectStudentGradedAssignment = () => {
    history.push({
      pathname: `/${ComponentUrls.gradedAssessments}`,
    });
  };

  const redirectAttemptTest = ({
    assignmentId,
    assessmentId,
    fromPage,
    regionCultureCode,
    data,
  }) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.attemptTest}`, {
        assignmentId,
        assessmentId,
        regionCultureCode,
      }),
      state: {
        assignmentId: assignmentId,
        assessmentId: assessmentId,
        fromPage: fromPage,
        regionCultureCode,
        data: data,
      },
    });
  };

  const redirectAssignedAssessment = () => {
    history.push({
      pathname: `/${ComponentUrls.assignedAssessments}`,
    });
  };

  const redirectViewScore = (assignmentId, fromPage, regionCultureCode) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.viewScore}`, {
        assignmentId,
      }),
      state: {
        fromPage: fromPage,
        regionCultureCode,
      },
    });
  };

  const redirectToAssessmentReport = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.assessmentReports}`),
      state: params,
    });
  };

  const redirectClassReportByStudent = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.classReportByStudent}`),
      state: params,
    });
  };

  const redirectSAStandardReportByGradeName = (params) => {
    history.push({
      pathname: generatePath(
        `/${ComponentUrls.saStandardReportByGrade}`,
        params
      ),
      state: params,
    });
  };

  const redirectDAStandardReportByGradeName = (params) => {
    history.push({
      pathname: generatePath(
        `/${ComponentUrls.daStandardReportByGrade}`,
        params
      ),
      state: params,
    });
  };

  const redirectStandardStudentReportByName = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.standardStudentReports}`),
      state: params,
    });
  };

  const redirectStandardReport = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.standardReports}`),
      state: params,
    });
  };

  const redirectStandardReportDetail = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.standardReportDetail}`),
      state: params,
    });
  };

  const redirectToBaselineReport = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.baselineReports}`),
      state: params,
    });
  };

  const redirectToBaselineAssessmentReport = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.baselineAssessmentReports}`),
      state: params,
    });
  };

  const redirectToPreviousPage = (backto) => {
    if (backto === null || backto === undefined) {
      history.goBack();
    } else {
      history.go(backto);
    }
  };

  const redirectToNextPage = () => {
    history.goForward();
  };

  // reload/refresh page
  const reloadPage = () => {
    window.location.reload();
  };

  const redirectAddClass = (classId) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.addClassRosters}`, {
        classId,
      }),
    });
  };

  const redirectToStudentRoster = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.studentRosters}`, params),
    });
  };
  const redirectAddStudent = () => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.addStudentRosters}`),
    });
  };
  const redirectAddTeacher = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.addTeacherRoster}`),
      state: params,
    });
  };
  const redirectTeacherList = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.teacherRoster}`),
      state: params,
    });
  };
  const redirectRosterClass = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.classRosters}`),
      state: params,
    });
  };

  const redirectToRosterStudents = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.studentRosters}`),
      state: params,
    });
  };

  const redirectEditClass = (classId) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.editClassRosters}`, {
        classId,
      }),
    });
  };
  const redirectReassignClass = (classId, params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.reassignClassRosters}`, {
        classId,
      }),
      state: params,
    });
  };

  const redirectEditTeacher = (teacherId) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.editTeacherRosters}`, {
        teacherId,
      }),
    });
  };
  const redirectToTransferTeacher = (teacherId) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.transferTeacherRoster}`, {
        teacherId,
      }),
    });
  };
  const redirectEditStudent = (studentId) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.editStudentRosters}`, {
        studentId,
      }),
    });
  };

  const redirectToBulkUploadClass = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.classBulkUploadRosters}`),
      state: params,
    });
  };

  const redirectToBulkUploadClassResult = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.classBulkUploadRostersResult}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToBulkUploadTeacherResult = (params) => {
    history.push({
      pathname: generatePath(
        `/${ComponentUrls.teacherBulkUploadRostersResult}`,
        {
          ...params,
        }
      ),
      state: params,
    });
  };

  const redirectToBulkUploadStudentResult = (params) => {
    history.push({
      pathname: generatePath(
        `/${ComponentUrls.studentBulkUploadRostersResult}`,
        {
          ...params,
        }
      ),
      state: params,
    });
  };

  const redirectToBulkUploadStudent = () => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.studentBulkUploadRosters}`),
    });
  };

  const redirectToBulkUploadTeacher = () => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.teacherBulkUploadRosters}`),
    });
  };

  const redirectToQuestionList = () => {
    history.push({
      pathname: generatePath(`${ComponentUrls.tagManagerQuestions}`),
    });
  };

  const redirectToEditQuestion = (params) => {
    const { questionId } = params;
    history.push({
      pathname: generatePath(`/${ComponentUrls.tagManagerEditQuestion}`, {
        questionId: questionId,
      }),
      state: params,
    });
  };

  const redirectToTTSEditQuestion = (params) => {
    const { questionId } = params;
    history.push({
      pathname: generatePath(`/${ComponentUrls.tagManagerTTSEditQuestion}`, {
        questionId: questionId,
      }),
      state: params,
    });
  };

  const redirectToCopyQuestion = (questionId) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.tagManagerCopyQuestion}`, {
        questionId: questionId,
      }),
    });
  };
  const redirectToViewQuestion = (questionId) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.tagManagerViewQuestion}`, {
        questionId: questionId,
      }),
    });
  };
  const redirectToCreateQuestion = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.tagManagerCreateQuestion}`),
      state: params,
    });
  };

  const redirectToCreateAssessment = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.tagManagerCreateAssessments}`),
      state: params,
    });
  };

  const redirectToCreateQuestionType = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.tagManagerCreateQuestionType}`),
      state: params,
    });
  };

  const redirectToAssessmentQuestions = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.tagManagerAssessmentQuestions}`),
      state: params,
    });
  };

  const redirectToEditAssessment = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.tagManagerEditAssessment}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToTagAssessment = () => {
    history.push({
      pathname: generatePath(`${ComponentUrls.tagManagerAssessments}`),
    });
  };

  const redirectToCopyAssessment = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.tagManagerCopyAssessment}`, {
        ...params,
      }),
      state: params,
    });
  };

  // bundle

  const redirectToBundleList = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.manageBundle}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateBundle = (params) => {
    history.push({
      pathname: generatePath(`${ComponentUrls.createBundle}`, {
        ...params,
      }),
      state: params,
    });
  };
  const redirectToEditBundle = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.editBundle}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToViewBundle = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.viewBundle}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToBundleQuestions = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.bundleQuestions}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToBundleAssessments = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.bundleAssessments}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToAssessmentList = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.assignedAssessments}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToManageLicense = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.manageLicense}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateLicense = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.createLicense}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateLicenseContent = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.createLicenseContent}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateLicenseTerm = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.createLicenseTerm}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateLicensePreview = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.createLicensePreview}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditLicense = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.editLicense}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditLicenseContent = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.editLicenseContent}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditLicenseTerm = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.editLicenseTerm}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditLicensePreview = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.editLicensePreview}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToManageLicensePreview = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.manageLicensePreview}`, {
        ...params,
      }),
      state: params,
    });
  };
  // organizations///

  const redirectToManageDistricts = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationsDistrict}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateDistrict = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationCreateDistrict}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditDistrict = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationEditDistrict}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToViewDistrict = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationViewDistrict}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToManageDistrictAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationsDistrictAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateDistrictAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgCreateDistrictAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditDistrictAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgEditDistrictAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToViewDistrictAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgViewDistrictAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };
  // schools
  const redirectToManageSchool = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationsSchool}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateSchool = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgCreateSchool}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditSchool = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgEditSchool}`, {
        ...params,
      }),
      state: params,
    });
  };

  // school Admin
  const redirectToManageSchoolAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.organizationsSchoolAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToCreateSchoolAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgCreateSchoolAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToEditSchoolAdmin = (params) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.orgEditSchoolAdmin}`, {
        ...params,
      }),
      state: params,
    });
  };

  const redirectToPromoteStudents = (schoolId) => {
    history.push({
      pathname: generatePath(`/${ComponentUrls.promoteStudents}`, {
        schoolId,
      }),
    });
  };

  // const redirectEditTeacher = (teacherId) => {
  //   history.push({
  //     pathname: generatePath(`${ComponentUrls.editTeacherRosters}`, {
  //       teacherId,
  //     }),
  //   });
  // };

  return {
    ntnGeneratePath,
    redirectEditAssessment,
    redirectCopyAssessment,
    redirectViewAssessment,
    redirectViewAssignment,
    redirectStudentAssignedAssignment,
    redirectStudentSubmittedAssignment,
    redirectStudentGradedAssignment,
    redirectAttemptTest,
    getActiveComponent,
    getActiveRoute,
    redirectAssignedAssessment,
    redirectToPreviousPage,
    redirectToNextPage,
    redirectViewScore,
    redirectClassReportByStudent,
    // redirectStudentReportByName,
    redirectAddClass,
    redirectAddTeacher,
    redirectEditClass,
    redirectReassignClass,
    redirectEditTeacher,
    redirectTeacherList,
    redirectRosterClass,
    redirectEditStudent,
    redirectStandardStudentReportByName,
    redirectStandardReport,
    redirectSAStandardReportByGradeName,
    redirectDAStandardReportByGradeName,
    redirectStandardReportDetail,
    redirectToBaselineReport,
    redirectToBaselineAssessmentReport,
    redirectToStudentRoster,
    redirectToAssessmentReport,
    redirectToRosterStudents,
    redirectToTransferTeacher,
    redirectAddStudent,
    redirectToBulkUploadClass,
    redirectToBulkUploadClassResult,
    redirectToBulkUploadStudent,
    redirectToBulkUploadStudentResult,
    redirectToBulkUploadTeacher,
    redirectToBulkUploadTeacherResult,
    redirectToCreateQuestion,
    redirectToCreateQuestionType,
    redirectToQuestionList,
    redirectToEditQuestion,
    redirectToTTSEditQuestion,
    redirectToCopyQuestion,
    redirectToViewQuestion,
    redirectToCreateAssessment,
    redirectToAssessmentQuestions,
    redirectToEditAssessment,
    redirectToTagAssessment,
    redirectToCopyAssessment,
    redirectToCreateBundle,
    redirectToEditBundle,
    redirectToViewBundle,
    redirectToBundleQuestions,
    redirectToBundleAssessments,
    redirectToBundleList,
    redirectToAssessmentList,
    redirectToCreateLicense,
    redirectToEditLicense,
    redirectToCreateLicenseContent,
    redirectToCreateLicenseTerm,
    redirectToCreateLicensePreview,
    redirectToEditLicenseContent,
    redirectToEditLicenseTerm,
    redirectToEditLicensePreview,
    redirectToManageLicense,
    redirectToManageDistricts,
    redirectToCreateDistrict,
    redirectToEditDistrict,
    redirectToViewDistrict,
    redirectToManageDistrictAdmin,
    redirectToCreateDistrictAdmin,
    redirectToEditDistrictAdmin,
    redirectToViewDistrictAdmin,
    redirectToManageSchool,
    redirectToCreateSchool,
    redirectToEditSchool,
    // school admin
    redirectToManageSchoolAdmin,
    redirectToCreateSchoolAdmin,
    redirectToEditSchoolAdmin,
    redirectToManageLicense,
    redirectToManageLicensePreview,
    reloadPage,
    redirectToPromoteStudents,
  };
};
