import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "../src/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/authConfig";
import "./index.css";
import "./scss/custom.scss";
import App from "./containers/App";
const msalInstance = new PublicClientApplication(msalConfig);
const persistor = persistStore(configureStore);
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={configureStore}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </MsalProvider>,
  document.querySelector("#root")
);
