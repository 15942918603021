import {
  bundleFilterListAction,
  editBundleAction,
  deleteBundleAction,
  createBundleAction,
  bundleRequestAction,
  listBundleAction,
  getBundleAction,
  createBundleFiltersAction,
  getBundleAssessmentListAction,
  getBundleQuestionListAction,
  checkBundleTitleExistsAction,
  cancelBundleHandlerAction,
} from "../store/bundle/actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export const useBundle = () => {
  const bundleData = useSelector((state) => state.bundle);
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState(null);
  const [bundleList, setBundleList] = useState(null);
  const [bundleRequest, setBundleRequest] = useState(null);

  useEffect(() => {
    if (bundleData?.bundleList) {
      setBundleList(bundleData.bundleList);
    }

    if (bundleData.filterList) {
      setFilterList(bundleData.filterList);
    }

    if (bundleData?.bundleRequest) {
      setBundleRequest(bundleData.bundleRequest);
    }
  }, [bundleData]);

  const handleBundleFilters = async (request) => {
    return await dispatch(bundleFilterListAction(request));
  };

  const handleBundleList = async (request) => {
    return await dispatch(listBundleAction(request));
  };

  const handleBundleEdit = async (request) => {
    return await dispatch(editBundleAction(request));
  };

  const handleBundleDelete = async (request) => {
    return await dispatch(deleteBundleAction(request));
  };

  const handleCreateBundle = async (request) => {
    return await dispatch(createBundleAction(request));
  };

  const handleBundleRequest = async (request) => {
    return await dispatch(bundleRequestAction(request));
  };

  const handleGetBundle = async (request) => {
    return await dispatch(getBundleAction(request));
  };

  const handleCreateBundleFilters = async (request) => {
    return await dispatch(createBundleFiltersAction(request));
  };

  const handleBundleAssessmentList = async (request) => {
    return await dispatch(getBundleAssessmentListAction(request));
  };

  const handleBundleQuestionList = async (request) => {
    return await dispatch(getBundleQuestionListAction(request));
  };

  const cancelBundleHandler = async (request) => {
    return await dispatch(cancelBundleHandlerAction(request));
  };

  const handleSortForAll = (params) => {
    let { items, column, direction } = params;
    switch (direction) {
      case "DESC":
        items = items?.sort((a, b) => (a[column] < b[column] ? 1 : -1));
        break;
      case "ASC":
        items = items?.sort((a, b) => (a[column] > b[column] ? 1 : -1));
        break;
      default:
        break;
    }
    return items;
  };

  const handleBundleSort = (params) => {
    let sortedBundles = bundleList;

    switch (params.sortBy) {
      case "titleZA":
        sortedBundles = sortedBundles?.sort((a, b) =>
          a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1
        );
        break;
      case "titleAZ":
        sortedBundles = sortedBundles?.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
        break;

      case "statusZA":
        sortedBundles = sortedBundles?.sort((a, b) =>
          a.status.toLowerCase() < b.status.toLowerCase() ? 1 : -1
        );
        break;
      case "statusAZ":
        sortedBundles = sortedBundles?.sort((a, b) =>
          a.status.toLowerCase() > b.status.toLowerCase() ? 1 : -1
        );
        break;

      case "updateDateZA":
        sortedBundles = sortedBundles?.sort((a, b) =>
          a.updateDate.toLowerCase() < b.updateDate.toLowerCase() ? 1 : -1
        );
        break;
      case "updateDateAZ":
        sortedBundles = sortedBundles?.sort((a, b) =>
          a.updateDate.toLowerCase() > b.updateDate.toLowerCase() ? 1 : -1
        );
        break;
      default:
        break;
    }
    return sortedBundles;
  };

  const getBundleById = async (id) => {
    let bundle = null;
    bundle = bundleData.bundleList?.find((a) => a.id == id);
    if (bundle) {
      const _bundle = await dispatch(
        getBundleAction({
          bundleId: id.toString(),
          gradeId: 0,
          statusId: -1,
          searchText: "",
        })
      );
      bundle = _bundle?.data?.bundle;
    }
    return bundle;
  };

  const handleCheckBundleTitleExists = async (request) => {
    return await dispatch(checkBundleTitleExistsAction(request));
  };

  return {
    handleBundleDelete,
    handleBundleEdit,
    handleBundleFilters,
    handleBundleList,
    handleBundleRequest,
    handleBundleSort,
    handleCreateBundle,
    bundleRequest,
    handleGetBundle,
    handleSortForAll,
    handleCreateBundleFilters,
    handleBundleAssessmentList,
    handleBundleQuestionList,
    getBundleById,
    handleCheckBundleTitleExists,
    cancelBundleHandler,
  };
};

export default useBundle;
