import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import React from "react";
import { ComponentUrls } from "../component-names";
import { GiTreasureMap } from "react-icons/gi";
import * as CiYoutube from "react-icons/ci";
export const Mine = React.lazy(() =>
  import("../../../pages/Assessments/DistrictAdmin/Mine")
);
export const Premade = React.lazy(() =>
  import("../../../pages/Assessments/DistrictAdmin/Premade")
);
export const Shared = React.lazy(() =>
  import("../../../pages/Assessments/DistrictAdmin/Shared")
);

export const StandardReport = React.lazy(() =>
  import("../../../pages/Reports/DistrictAdmin/Standard/StandardReport")
);

export const SchoolReport = React.lazy(() =>
  import("../../../pages/Reports/DistrictAdmin/School/SchoolReport")
);

export const StudentReport = React.lazy(() =>
  import("../../../pages/Reports/DistrictAdmin/Student/StudentReport")
);

export const BaselineReport = React.lazy(() =>
  import("../../../pages/Reports/DistrictAdmin/Baseline/BaselineReport")
);

export const BaselineAssessmentReport = React.lazy(() =>
  import(
    "../../../pages/Reports/DistrictAdmin/Baseline/BaselineAssessmentReport"
  )
);

export const MyClasses = React.lazy(() =>
  import("../../../pages/Rosters/DistrictAdmin/Classes/Classes")
);

export const StudentList = React.lazy(() =>
  import("../../../pages/Rosters/DistrictAdmin/Students/Students")
);

export const TeacherList = React.lazy(() =>
  import("../../../pages/Rosters/DistrictAdmin/Teacher/TeacherList")
);

export const PromoteStudents = React.lazy(() =>
  import("../../../pages/Rosters/StudentPromotion/PromoteStudents")
);

export const Settings = React.lazy(() => import("../../../pages/Settings"));
export const Help = React.lazy(() => import("../../../pages/Help"));
export const PDOnDemand = React.lazy(() => import("../../../pages/PDOnDemand"));

export const DistrictAdminNavbar = [
  {
    path: "/",
    state: {
      type: "standard-report",
    },
    component: StandardReport,
  },
  {
    title: "Performance Reports",
    path: "#",
    expanded: true,
    state: {
      type: null,
    },
    childTags: [
      "standard-report",
      "school-report",
      "student-report",
      "baseline-assessment-report",
    ],
    icons: <AiIcons.AiOutlineUnorderedList />,
    subMenu: [
      {
        title: "Standard Report",
        path: `/${ComponentUrls.standardReports}`,
        component: StandardReport,
        childTags: ["standard-report"],
        state: {
          type: "standard-report",
        },
      },
      {
        title: "School Report",
        path: `/${ComponentUrls.schoolReports}`,
        component: SchoolReport,
        childTags: ["school-report"],
        state: {
          type: "school-report",
        },
      },
      {
        title: "Student Report",
        path: `/${ComponentUrls.studentReports}`,
        component: StudentReport,
        childTags: ["student-report"],
        state: {
          type: "student-report",
        },
      },
      {
        title: "Baseline Report",
        path: `#`,
        // component: BaselineReport,
        childTags: ["baseline-assessment-report"],
        // state: {
        //   type: "baseline-report",
        // },
        subMenu: [
          {
            title: "Baseline Assessment Report",
            path: `/${ComponentUrls.baselineReports}`,
            childTags: ["baseline-assessment-report"],
            state: {
              type: "baseline-assessment-report",
            },
            component: BaselineReport,
          },
        ],
      },
    ],
  },
  {
    title: "Assessments",
    path: "#",
    state: {
      type: null,
    },
    childTags: [
      "created-by-me",
      "premade",
      "my-assessment",
      "premade-assessment",
      "shared-assessment",
      "shared-by-me",
    ],
    component: Premade,
    icons: <BsIcons.BsGrid3X3Gap />,
    // iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Premade Assessments",
        path: `/${ComponentUrls.premadeAssessments}`,
        state: {
          type: "premade-assessment",
        },
        childTags: ["premade-assessment", "premade"],
        component: Premade,
      },
      {
        title: "Created by Me",
        path: `/${ComponentUrls.createdByMeAssessment}`,
        state: {
          type: "my-assessment",
        },
        childTags: ["my-assessment", "created-by-me"],
        component: Mine,
      },
      {
        title: "Shared Assessments",
        path: `/${ComponentUrls.sharedAssessments}`,
        state: {
          type: "shared-assessment",
        },
        childTags: ["shared-assessment", "shared-by-me"],
        component: Shared,
      },
    ],
  },
  {
    title: "PD On Demand",
    path: `/${ComponentUrls.pdondemand}`,
    state: {
      type: "pdondemand",
    },
    icons: <CiYoutube.CiYoutube />,
    component: PDOnDemand,
  },
  {
    title: "Roster",
    path: `#`,
    icons: <FiIcons.FiUser />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: [
      "class-list",
      "teacher-list",
      "student-list",
      "promote-students",
    ],
    subMenu: [
      {
        title: "Classes",
        path: `/${ComponentUrls.classRosters}`,
        component: MyClasses,
        state: {
          type: "class-list",
        },
        childTags: ["class-list"],
      },
      {
        title: "Teachers",
        path: `/${ComponentUrls.teacherRoster}`,
        state: {
          type: "teacher-list",
        },
        childTags: ["teacher-list"],
        component: TeacherList,
      },
      {
        title: "Students",
        path: `/${ComponentUrls.studentRosters}`,
        state: {
          type: "student-list",
        },
        childTags: ["student-list"],
        component: StudentList,
      },
      {
        path: `/${ComponentUrls.promoteStudents}`,
        component: PromoteStudents,
        state: {
          parentNav: "promote-students",
        },
      },
    ],
  },
  {
    title: "Settings",
    path: "/Settings",
    icons: <IoIcons.IoMdSettings />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "LTI Setup",
        path: "/LTISetup",
      },
      {
        title: "Google Classroom V2",
        path: "/GoogleClassroomV2",
      },
    ],
  },
  {
    title: "Help",
    path: `/${ComponentUrls.help}`,
    state: {
      type: "help",
    },
    icons: <IoIcons.IoMdHelpCircleOutline />,
    component: Help,
  },
  // {
  //   title: "Assessments",
  //   path: "/Assessments",
  //   icons: <BsIcons.BsGrid3X3Gap />,
  //   iconsOpened: <RiIcons.RiArrowDownSLine />,
  //   subMenu: [
  //     {
  //       title: "Teacher Response",
  //       path: "/TeacherResponse",
  //     },
  //     {
  //       title: "District Performance",
  //       path: "/DistrictPerformance",
  //     },
  //     {
  //       title: "School Performance",
  //       path: "/SchoolPerformance",
  //     },
  //     {
  //       title: "Class Performance",
  //       path: "/ClassPerformance",
  //     },
  //     {
  //       title: "Baseline School Performance",
  //       path: "/BaselineSchoolPerformance",
  //     },
  //   ],
  // },
  // {
  //   title: "School",
  //   path: "/School",
  //   icons: <BiIcons.BiBusSchool />,
  //   iconsOpened: <RiIcons.RiArrowDownSLine />,
  //   subMenu: [
  //     {
  //       title: "School List",
  //       path: "/SchoolList",
  //     },
  //     {
  //       title: "School Admin",
  //       path: "/SchoolAdmin",
  //     },
  //   ],
  // },
  // {
  //   title: "Roster",
  //   path: "/Roster",
  //   icons: <FiIcons.FiUser />,
  //   iconsOpened: <RiIcons.RiArrowDownSLine />,
  //   subMenu: [
  //     {
  //       title: "Class",
  //       path: "/Class",
  //     },
  //     {
  //       title: "Teacher",
  //       path: "/Teacher",
  //     },
  //     {
  //       title: "Student",
  //       path: "/Student",
  //     },
  //   ],
  // },
  // {
  //   title: "Archived User",
  //   path: "/ArchivedUser",
  //   icons: <FiIcons.FiUser />,
  // },
  // {
  //   title: "License",
  //   path: "/License",
  //   icons: <LicenseIcon/>,
  // },
  // {
  //   title: "Settings",
  //   path: "/Settings",
  //   icons: <IoIcons.IoMdSettings />,
  //   iconsOpened: <RiIcons.RiArrowDownSLine />,
  //   subMenu: [
  //     {
  //       title: "LTI Setup",
  //       path: "/LTISetup",
  //     },
  //     {
  //       title: "OneRoster",
  //       path: "/OneRoster",
  //     },
  //   ],
  // },
];
