import * as types from "./actionTypes";

export const clearAllFilters = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_FILTER,
      payload: request,
    });
  };
};

export const saveAssessment = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ASSESSMENT_FILTER,
      payload: request,
    });
  };
};

export const saveCreatedAssessment = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.CREATED_ASSESSMENT_FILTER,
      payload: request,
    });
  };
};

export const savePremadeAssessment = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.PREMADE_ASSESSMENT_FILTER,
      payload: request,
    });
  };
};

export const saveSharedAssessment = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SHARED_ASSESSMENT_FILTER,
      payload: request,
    });
  };
};

export const saveAssignment = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ASSIGNMENT_FILTER,
      payload: request,
    });
  };
};

export const saveBundle = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.BUNDLE_FILTER,
      payload: request,
    });
  };
};

export const saveLicense = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.LICENSE_FILTER,
      payload: request,
    });
  };
};

export const saveOrgDistrict = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ORG_DISTRICT_FILTER,
      payload: request,
    });
  };
};

export const saveOrgDistrictAdmin = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ORG_DISTRICT_ADMIN_FILTER,
      payload: request,
    });
  };
};

export const saveOrgSchool = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ORG_SCHOOL_FILTER,
      payload: request,
    });
  };
};

export const saveOrgSchoolAdmin = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ORG_SCHOOL_ADMIN_FILTER,
      payload: request,
    });
  };
};

export const saveRoasterClass = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ROASTER_CLASS_FILTER,
      payload: request,
    });
  };
};
export const saveRoasterStudent = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ROASTER_STUDENT_FILTER,
      payload: request,
    });
  };
};

export const saveRoasterTeacher = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.ROASTER_TEACHER_FILTER,
      payload: request,
    });
  };
};

export const saveTagManager = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.TAG_MANAGER_FILTER,
      payload: request,
    });
  };
};
