import React from "react";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as CiYoutube from "react-icons/ci";
import { ReactComponent as AssignmentIcon } from "../../../assets/images/assignment-icon.svg";
import { ComponentUrls } from "../component-names";
export const Assigned = React.lazy(() =>
  import("../../../pages/Assessments/Assigned")
);
export const Mine = React.lazy(() => import("../../../pages/Assessments/Mine"));
export const Premade = React.lazy(() =>
  import("../../../pages/Assessments/Premade")
);
export const Shared = React.lazy(() =>
  import("../../../pages/Assessments/Shared")
);
export const Unpublished = React.lazy(() =>
  import("../../../pages/Assessments/Unpublished")
);

export const ClassReports = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Class/ClassReport")
);

export const AssessmentReports = React.lazy(() =>
  import("../../../pages/Reports/Teacher/AssessmentReport")
);

export const StandardReports = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Standard/StandardReport")
);

export const StandardStudentReportByName = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Standard/StandardStudentReportsByName")
);

export const StandardStudentAnalysisReport = React.lazy(() =>
  import(
    "../../../pages/Reports/Teacher/Standard/StandardStudentAnalysisReport"
  )
);

export const BaselineReport = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Baseline/BaselineReport")
);

export const BaselineAssessmentReport = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Baseline/AssessmentReport")
);

export const BaselineStudentReport = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Baseline/StudentReport")
);

export const MyClasses = React.lazy(() =>
  import("../../../pages/Rosters/Teacher/Classes")
);

export const MyStudents = React.lazy(() =>
  import("../../../pages/Rosters/Teacher/Students")
);
export const Settings = React.lazy(() => import("../../../pages/Settings"));
export const Help = React.lazy(() => import("../../../pages/Help"));
export const PDOnDemand = React.lazy(() => import("../../../pages/PDOnDemand"));
export const TeacherNavbar = [
  {
    path: "/",
    state: {
      type: "assignment",
    },
    component: Assigned,
  },
  {
    title: "Assignments",
    path: `/${ComponentUrls.assignedAssessments}`,
    state: {
      type: "assignment",
    },
    childTags: ["assignment"],
    component: Assigned,
    icons: <AssignmentIcon />,
    className: "assessment-menu",
  },
  {
    title: "Assessments",
    path: "#",
    state: {
      type: null,
    },
    childTags: [
      "created-by-me",
      "my-assessment",
      "premade-assessment",
      "shared-assessment",
      "premade",
      "shared-by-me",
    ],
    component: Premade,
    icons: <BsIcons.BsGrid3X3Gap />,
    // iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Premade Assessments",
        path: `/${ComponentUrls.premadeAssessments}`,
        state: {
          type: "premade-assessment",
        },
        childTags: ["premade", "premade-assessment"],
        component: Premade,
      },
      {
        title: "Created by Me",
        path: `/${ComponentUrls.createdByMeAssessment}`,
        state: {
          type: "my-assessment",
        },
        childTags: ["created-by-me", "my-assessment"],
        component: Mine,
      },
      {
        title: "Shared Assessments",
        path: `/${ComponentUrls.sharedAssessments}`,
        childTags: ["shared-by-me", "shared-assessment"],
        state: {
          type: "shared-assessment",
        },
        component: Shared,
      },
    ],
  },
  {
    title: "Performance Reports",
    path: "#",
    state: {
      type: null,
    },
    childTags: [
      "reports",
      "class-report-by-student",
      "class-report",
      "assessment-report",
      "student-standard-analysis-report",
      "standard-report",
      "student-report",
      "baseline-student-report",
      "baseline-assessment-report",
      "standard-report-detail",
    ],
    // component: ClassReports,
    icons: <AiIcons.AiOutlineUnorderedList />,
    // iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Class Report",
        path: `/${ComponentUrls.classReports}`,
        component: ClassReports,
        childTags: ["class-report", "class-report-by-student"],
        state: {
          type: "class-report",
        },
      },
      {
        title: "Assessment Report",
        path: `/${ComponentUrls.assessmentReports}`,
        component: AssessmentReports,
        state: {
          type: "assessment-report",
        },
        childTags: ["assessment-report"],
      },
      {
        title: "Student Standard Analysis Report",
        path: `/${ComponentUrls.studentStandardAnalysisReport}`,
        component: StandardStudentAnalysisReport,
        state: {
          type: "student-standard-analysis-report",
        },
        childTags: ["student-standard-analysis-report"],
      },
      {
        title: "Standard Reports",
        path: `#`,
        childTags: [
          "student-report",
          "standard-report",
          "standard-report-detail",
        ],
        // iconsOpened: <RiIcons.RiArrowDownSLine />,
        subMenu: [
          {
            title: "Standard Report",
            path: `/${ComponentUrls.standardReports}`,
            component: StandardReports,
            state: {
              type: "standard-report",
            },
            childTags: ["standard-report"],
          },
          {
            title: "Student Report",
            path: `/${ComponentUrls.standardStudentReports}`,
            component: StandardStudentReportByName,
            state: {
              type: "student-report",
            },
            childTags: ["student-report"],
          },
        ],
      },
      {
        title: "Baseline Report",
        path: `#`,
        // component: BaselineReport,
        state: {
          type: "baseline-report",
        },
        childTags: ["baseline-student-report", "baseline-assessment-report"],
        subMenu: [
          {
            title: "Student Report",
            path: `/${ComponentUrls.baselineStudentReports}`,
            component: BaselineStudentReport,
            state: {
              type: "baseline-student-report",
            },
            childTags: ["baseline-student-report"],
          },
          {
            title: "Assessment Report",
            path: `/${ComponentUrls.baselineAssessmentReports}`,
            component: BaselineAssessmentReport,
            state: {
              type: "baseline-assessment-report",
            },
            childTags: ["baseline-assessment-report"],
          },
        ],
      },
    ],
  },
  {
    title: "PD On Demand",
    path: `/${ComponentUrls.pdondemand}`,
    state: {
      type: "pdondemand",
    },
    icons: <CiYoutube.CiYoutube />,
    component: PDOnDemand,
  },
  {
    title: "Roster",
    path: "#",
    icons: <FiIcons.FiUser />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: [
      "my-classess",
      "my-students",
      "student-list",
      "my-student-rosters",
    ],
    component: MyClasses,
    subMenu: [
      {
        title: "My Classes",
        path: `/${ComponentUrls.classRosters}`,
        component: MyClasses,
        state: {
          type: "my-classess",
        },
        childTags: ["my-classess"],
      },
      {
        title: "My Students",
        path: `/${ComponentUrls.studentRosters}`,
        component: MyStudents,
        state: {
          type: "my-students",
        },
        childTags: ["my-student-rosters", "student-list", "my-students"],
      },
    ],
  },
  {
    title: "Settings",
    path: `/${ComponentUrls.settings}`,
    state: {
      type: "settings",
    },
    icons: <IoIcons.IoMdSettings />,
    component: Settings,
  },
  {
    title: "Help",
    path: `/${ComponentUrls.help}`,
    state: {
      type: "help",
    },
    icons: <IoIcons.IoMdHelpCircleOutline />,
    component: Help,
  },
];
