import * as types from "./actionTypes";
const initialState = {
  loading: false,
  filterList: null,
  licenseList: null,
  accessCodeList: null,
  createLicense: {},
  selectedLicense: [],
  districtSchoolList: null,
  schoolAndAdminList: null,
  licenseDetail: null,
  statelist: [],
  licenseFormData: null,
};

const LicenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LICENSE_FILTER_LIST:
      return {
        ...state,
        filterList: action.payload,
        loading: false,
      };
    case types.SET_DISTRICT_SCHOOL_FILTER_LIST:
      return {
        ...state,
        districtSchoolList: action.payload,
        loading: false,
      };
    case types.SET_SCHOOL_SCHOOLADMIN_LIST:
      return {
        ...state,
        schoolAndAdminList: action.payload,
        loading: false,
      };
    case types.SET_LICENSE_LIST:
      return {
        ...state,
        licenseList: action.payload,
        loading: false,
      };
    case types.CREATE_LICENSE:
      return {
        ...state,
        createLicense: action.payload,
        loading: false,
      };
    case types.EDIT_LICENSE:
      return {
        ...state,
        selectedLicense: action.payload,
        loading: false,
      };
    case types.DELETE_LICENSE:
      return {
        ...state,
        selectedLicense: [],
        loading: false,
      };
    case types.SET_LICENSE_DETAILS:
      return {
        ...state,
        licenseDetail: action.payload,
        loading: false,
      };
    case types.SET_STATE_LIST:
      return {
        ...state,
        statelist: action.payload,
        loading: false,
      };
    case types.SET_LICENSE_FORM:
      return {
        ...state,
        licenseFormData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default LicenseReducer;
