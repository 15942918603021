import {
  getAssignmentEvaluationAction,
  saveAssignmentEvaluationAction,
  saveStudentAssessmentAction,
  getStudentAssessmentAction,
  uploadStudentAssessmentAttachmentAction,
  deleteStudentAssessmentAttachmentAction,
  setAssignmentGridTypeAction,
  saveImageFromBase64Action,
  getFakeStudentAssignmentAction,
} from "../store/assignment/actions";
// import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
export const useAssignment = () => {
  const assignmentData = useSelector((state) => state.assignment);

  const [assigmentGridType, setAssignmentGridType] = useState("grid");

  useEffect(() => {
    setAssignmentGridType(assignmentData.gridType);
  }, [assignmentData]);
  const dispatch = useDispatch();

  const handleGetStudentAssignmentEvaluation = async (request) => {
    return await dispatch(getAssignmentEvaluationAction(request));
  };

  const handleSaveStudentAssignmentEvaluation = async (request) => {
    return await dispatch(saveAssignmentEvaluationAction(request));
  };

  const handleSaveStudentAssessment = async (request) => {
    return await dispatch(saveStudentAssessmentAction(request));
  };

  const handleGetStudentAssessment = async (request) => {
    return await dispatch(getStudentAssessmentAction(request));
  };

  const handleUploadStudentAssessmentAttachment = async (request) => {
    return await dispatch(uploadStudentAssessmentAttachmentAction(request));
  };

  const handleDeleteStudentAssessmentAttachment = async (request) => {
    return await dispatch(deleteStudentAssessmentAttachmentAction(request));
  };

  const handleAssignmentGridType = async (request) => {
    return await dispatch(setAssignmentGridTypeAction(request));
  };

  const handleSaveImageFromBase64 = async (request) => {
    return await dispatch(saveImageFromBase64Action(request));
  };

  const handleFakeStudentAssignment = async (request) => {
    return await dispatch(getFakeStudentAssignmentAction(request));
  };

  return {
    handleGetStudentAssignmentEvaluation,
    handleSaveStudentAssignmentEvaluation,
    handleSaveStudentAssessment,
    handleGetStudentAssessment,
    handleUploadStudentAssessmentAttachment,
    handleDeleteStudentAssessmentAttachment,
    handleAssignmentGridType,
    assigmentGridType,
    handleSaveImageFromBase64,
    handleFakeStudentAssignment,
  };
};
