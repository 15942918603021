import * as types from "./actionTypes";
export const getScannerDataAction = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.GET_SCANNER_DATA, payload: payload });
  };
};

export const setScannerDataAction = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.SET_SCANNER_DATA, payload: payload });
  };
};

export const removeScannerDataAction = () => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_SCANNER_DATA, payload: null });
  };
};

export const setWebcamCrashAction = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.SET_WEBCAM_CRASHED, payload: payload });
  };
};

export const setTestIdsAction = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.SET_TEST_IDS, payload: payload });
  };
};

export const setCurrentDocumentAction = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.SET_CURRENT_DOCUMENT, payload: payload });
  };
};
