import * as types from "./actionTypes";

const initialState = {
  isOpen: false,
  message: "",
  type: "",
};
const snackBarRducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        type: action.payload.snacknarType,
      };
    case types.HIDE_SNACKBAR:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default snackBarRducer;
