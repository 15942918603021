//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const bundleFilterListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.bundleFilterList(request)
      .then((response) => {
        dispatch({
          type: types.SET_BUNDLE_FILTER_LIST,
          payload: response?.data,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const listBundleAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.listBundle(request)
      .then((response) => {
        dispatch({
          type: types.SET_BUNDLE_LIST,
          payload: response.data?.bundles,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const editBundleAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.editBundle(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const deleteBundleAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.deleteBundle(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const createBundleAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.createBundle(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getBundleAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getBundle(request)
      .then((response) => {
        dispatch({
          type: types.SET_BUNDLE,
          payload: response.data?.bundle,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const createBundleFiltersAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.createBundleFilters(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getBundleAssessmentListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getBundleAssessmentList(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getBundleQuestionListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getBundleQuestionList(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const bundleRequestAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BUNDLE_REQUEST,
      payload: request,
    });
  };
};

export const checkBundleTitleExistsAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.checkBundleTitleExists(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const cancelBundleHandlerAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.cancelBundle(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};
