import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
export const getSystemAlertsAction = () => {
  return async (dispatch) => {
    await HttpApis.systemNotification()
      .then((response) => {
          dispatch({ type: types.GET_ALERT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: types.GET_ALERT_ERROR,
          payload: [],
        });
      });
  };
};
