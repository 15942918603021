import "./style.scss";
import React from "react";
import { ReactComponent as NtnLogoWhite } from "../../assets/images/logo/logo-white.svg";
import { Navbar, Container } from "react-bootstrap";

const PublicHeader = () => {
  return (
    <Navbar className="header" bg="light" expand={false}>
      <Container fluid>
        <div className="logo-container">
          <Navbar.Brand href="#" className="">
            <NtnLogoWhite className="logo-size" />
          </Navbar.Brand>
        </div>
      </Container>
    </Navbar>
  );
};
export default PublicHeader;
