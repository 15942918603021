import {
  tagFilterListAction,
  tagQuestionListAction,
  tagEditQuestionAction,
  tagCopyQuestionAction,
  tagGetEditQuestionDetailAction,
  tagPreviewEditQuestionDetailAction,
  tagDeleteQuestionAction,
  tagCreateQuestionAction,
  tagAssessmentFilterListsAction,
  tagAssessmentListAction,
  tagSaveAssessmentAction,
  tagGetAssessmentAction,
  tagDeleteAssessmentAction,
  createAssessmentRequestAction,
  tagEditAssessmentAction,
  tagPublishAssessmentAction,
  tagAssessmentGradesAction,
  tagRefreshSpeechTextAction,
  tagCopyAssessmentAction,
  saveQuestionFilterAction,
} from "../store/tagManager/actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export const useTagManager = () => {
  const tagData = useSelector((state) => state.tagManager);

  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState(null);
  const [questionList, setQuestionList] = useState(null);
  const [assessmentList, setAssessmentList] = useState(null);
  const [createAssessmentRequest, setCreateAssessmentRequest] = useState(null);
  const [questionSavedFilters, setQuestionSavedFilters] = useState(null);
  useEffect(() => {
    if (tagData?.questions) {
      setQuestionList(tagData.questions);
    }

    if (tagData.filterList) {
      setFilterList(tagData.filterList);
    }

    if (tagData?.assessments) {
      setAssessmentList(tagData.assessments);
    }
    if (tagData?.createAssessmentRequest) {
      setCreateAssessmentRequest(tagData.createAssessmentRequest);
    }
    if (tagData?.questionSavedFilters) {
      setQuestionSavedFilters(tagData?.questionSavedFilters);
    }
  }, [tagData]);

  const handleTagFilters = async (request) => {
    return await dispatch(tagFilterListAction(request));
  };

  const handleTagQuestions = async (request) => {
    return await dispatch(tagQuestionListAction(request));
  };

  const handleTagPreviewEditQuestionDetail = async (request) => {
    return await dispatch(tagPreviewEditQuestionDetailAction(request));
  };

  const handleTagGetEditQuestionDetail = async (request) => {
    return await dispatch(tagGetEditQuestionDetailAction(request));
  };

  const handleTagEditQuestion = async (request) => {
    return await dispatch(tagEditQuestionAction(request));
  };

  const handleTagCopyQuestion = async (request) => {
    return await dispatch(tagCopyQuestionAction(request));
  };

  const handleTagDeleteQuestion = async (request) => {
    return await dispatch(tagDeleteQuestionAction(request));
  };
  const handleTagCreateQuestion = async (request) => {
    return await dispatch(tagCreateQuestionAction(request));
  };

  // assessments
  const handleTagAssessmentFilterLists = async (request) => {
    return await dispatch(tagAssessmentFilterListsAction(request));
  };

  const handleTagAssessmentList = async (request) => {
    return await dispatch(tagAssessmentListAction(request));
  };

  const handleTagSaveAssessment = async (request) => {
    return await dispatch(tagSaveAssessmentAction(request));
  };

  const handleTagEditAssessment = async (request) => {
    return await dispatch(tagEditAssessmentAction(request));
  };

  const handleTagCopyAssessment = async (request) => {
    return await dispatch(tagCopyAssessmentAction(request));
  };

  const handleTagGetAssessment = async (request) => {
    return await dispatch(tagGetAssessmentAction(request));
  };

  const handleTagDeleteAssessment = async (request) => {
    return await dispatch(tagDeleteAssessmentAction(request));
  };

  const handleAssessmentSort = (params) => {
    let sortedAssessments = assessmentList;

    switch (params.sortBy) {
      case "assessmentNameZA":
        sortedAssessments = sortedAssessments?.sort((a, b) =>
          a.assessmentName.toLowerCase() < b.assessmentName.toLowerCase()
            ? 1
            : -1
        );
        break;
      case "assessmentNameAZ":
        sortedAssessments = sortedAssessments?.sort((a, b) =>
          a.assessmentName.toLowerCase() > b.assessmentName.toLowerCase()
            ? 1
            : -1
        );
        break;

      case "descriptionZA":
        sortedAssessments = sortedAssessments?.sort((a, b) =>
          a.description.toLowerCase() < b.description.toLowerCase() ? 1 : -1
        );
        break;
      case "descriptionAZ":
        sortedAssessments = sortedAssessments?.sort((a, b) =>
          a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1
        );
        break;

      case "updateDateZA":
        sortedAssessments = sortedAssessments?.sort((a, b) =>
          a.updateDate.toLowerCase() < b.updateDate.toLowerCase() ? 1 : -1
        );
        break;
      case "updateDateAZ":
        sortedAssessments = sortedAssessments?.sort((a, b) =>
          a.updateDate.toLowerCase() > b.updateDate.toLowerCase() ? 1 : -1
        );
        break;
      default:
        break;
    }
    return sortedAssessments;
  };

  const handleTagCreateAssessmentRequest = (request) => {
    return dispatch(createAssessmentRequestAction(request));
  };

  const handleTagPublishAssessment = async (request) => {
    return await dispatch(tagPublishAssessmentAction(request));
  };

  const handleTagAssessmentGrades = async (request) => {
    return await dispatch(tagAssessmentGradesAction(request));
  };

  const handleTagRefreshSpeechText = async (request) => {
    return await dispatch(tagRefreshSpeechTextAction(request));
  };

  const handleQuestionFilters = async (request) => {
    return await dispatch(saveQuestionFilterAction(request));
  };

  return {
    handleTagFilters,
    handleTagQuestions,
    handleTagPreviewEditQuestionDetail,
    handleTagGetEditQuestionDetail,
    handleTagEditQuestion,
    handleTagCopyQuestion,
    handleTagDeleteQuestion,
    handleTagCreateQuestion,
    filterList,
    questionList,
    createAssessmentRequest,
    questionSavedFilters,
    handleTagAssessmentFilterLists,
    handleTagAssessmentList,
    handleTagSaveAssessment,
    handleTagGetAssessment,
    handleTagDeleteAssessment,
    handleAssessmentSort,
    handleTagCreateAssessmentRequest,
    handleTagEditAssessment,
    handleTagPublishAssessment,
    handleTagAssessmentGrades,
    handleTagCopyAssessment,
    handleQuestionFilters,
    handleTagRefreshSpeechText,
  };
};

export default useTagManager;
