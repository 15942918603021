import { type } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.speech/RecognizerConfig";
import * as types from "./actionTypes";
const initialState = {
  loading: false,
  settigs: null,
  speechVoices: null,
  user: null,
};
const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SYSTEM_SETTING:
      return {
        ...state,
        settigs: action.payload,
        loading: false,
      };
    case types.SAVE_USER_SETTING:
      const userSettings = { ...state?.user };
      action?.payload?.map((e) => {
        userSettings[e.name] = e.value;
      });
      return {
        ...state,
        user: userSettings,
        loading: false,
      };
    case types.SET_SPEECH_VOICES:
      return {
        ...state,
        speechVoices: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default settingsReducer;
