import React, { useEffect, useState } from "react";
import "./App.scss";
import { useAlert, useAuth } from "../hooks";
import Emitter, { EventTypes } from "../libs/Emitter";
import Auth from "../services/Auth";
import { apiLoadingStop } from "../store/global";
import { useDispatch, useSelector } from "react-redux";
import { CombinedLayout } from "../layouts/CombinedLayout";
export function App(props) {
  const { handleGetSystemAlerts } = useAlert();
  const { checkAccountSecurity, handleLogout, handleClearUserData } = useAuth();
  const userData = useSelector((state) => state.user);
  const [authenticated, setAuthenticated] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // takecare of login from logger in case browser does not handle to events from msal
    Emitter.once(EventTypes.NTN_LOGIN_SUCCESS, (response) => {
      Auth.setTokenExpiresIn(response.expiresOn);
      Auth.setToken(response.secret);
      setAccessToken(response.secret);
    });
    // clear user data and return to login page
    Emitter.once(EventTypes.NTN_TOKEN_FAILURE, () => {
      Auth.signOut();
      handleClearUserData();
    });
  }, []);

  useEffect(() => {
    const fetchAcountSecurity = async () => {
      if (accessToken !== null) {
        try {
          console.log("Found access token, going to check security.");
          await checkAccountSecurity(accessToken);
          dispatch(apiLoadingStop());
          setTimeout(() => {
            console.log("waiting for redirect");
            ///window.location.reload();
          }, 100);
        } catch (error) {
          console.error("Something is wrong", error);
          dispatch(apiLoadingStop());
          handleLogout();
        }
      }
    };
    fetchAcountSecurity();
  }, [accessToken]);

  useEffect(() => {
    // console.log("userData.loginstatus", userData.loginstatus);
    const fetchSystemAlerts = async () => {
      await handleGetSystemAlerts();
      // wait for notification to resolve..
      setAuthenticated(userData.loginstatus);
    };
    // uncomment this in order to run system alerts api
    if (userData.loginstatus) {
      fetchSystemAlerts();
    } else {
      setAuthenticated(userData.loginstatus);
    }

    setAuthenticated(userData.loginstatus);
  }, [userData.loginstatus]);

  return <CombinedLayout authenticated={authenticated} />;
}
export default App;
