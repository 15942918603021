import React from "react";
import { ComponentUrls } from "../component-names.js";
import { dynamicComponentNames } from "../constants.js";
export const AssignedDetails = React.lazy(() =>
  import("../../../pages/Assessments/AssignedDetails.js")
);
export const AssessmentDetail = React.lazy(() =>
  import("../../../pages/Assessments/AssessmentDetail")
);
export const CreateAssessment = React.lazy(() =>
  import("../../../pages/Assessments/CreateAssessment")
);
export const EditAssessment = React.lazy(() =>
  import("../../../pages/Assessments/EditAssessment")
);
export const CopyAssessment = React.lazy(() =>
  import("../../../pages/Assessments/CopyAssessment")
);

export const TakeAssessment = React.lazy(() =>
  import("../../../pages/Assessments/Student/TakeAssessment")
);
export const ViewScore = React.lazy(() =>
  import("../../../pages/Assessments/Student/ViewScore")
);
export const TeacherList = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Teacher/TeacherList")
);
export const AttemptTest = React.lazy(() =>
  import("../../../pages/Assessments/AttemptTest")
);
export const ClassReportByStudent = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Class/ClassReportByStudent")
);
export const AddClass = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Classes/AddClass")
);
export const AddTeacherPage = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Teacher/AddTeacherPage")
);
export const AddStudentPage = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Students/AddStudentPage")
);
export const EditTeacher = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Teacher/EditTeacherRoster")
);
export const TransferTeacher = React.lazy(() =>
  import("../../../pages/Rosters/DistrictAdmin/Teacher/TransferTeacherPAge")
);
export const EditStudent = React.lazy(() =>
  import("../../../pages/Rosters/Teacher/EditStudentRoster")
);
export const EditClass = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Classes/EditClass")
);
export const ReassignClass = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Classes/ReassignClass")
);
export const SA_StandardReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/Standard/StandardReport")
);

export const DA_StandardReport = React.lazy(() =>
  import("../../../pages/Reports/DistrictAdmin/Standard/StandardReport")
);

export const StandardReportDetail = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Standard/StandardReportDetail")
);
export const MyStudents = React.lazy(() =>
  import("../../../pages/Rosters/Teacher/Students")
);
export const TransferStudent = React.lazy(() =>
  import("../../../pages/Rosters/Teacher/TransferStudent")
);

export const ClassBulkUpload = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Classes/BulkUpload")
);

export const ClassBulkUploadResult = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Classes/BulkUploadResult")
);

export const StudentBulkUploadResult = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Students/BulkUploadResult")
);

export const TeacherBulkUploadResult = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Teacher/BulkUploadResult")
);

export const StudentBulkUpload = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Students/BulkUpload")
);

export const TeacherBulkUpload = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Teacher/BulkUpload")
);

export const DynamicRoutes = [
  {
    path: ComponentUrls.assignedDetail,
    component: AssignedDetails,
    state: {
      parentNav: "assignment",
    },
  },
  {
    path: ComponentUrls.assessmentDetail,
    component: AssessmentDetail,
    state: {
      parentNav: "created-by-me",
    },
  },
  {
    path: ComponentUrls.createAssessment,
    component: CreateAssessment,
    state: {
      parentNav: "created-by-me",
    },
  },
  {
    path: ComponentUrls.editAssessment,
    component: EditAssessment,
    state: {
      parentNav: "created-by-me",
    },
  },
  {
    path: ComponentUrls.copyAssessment,
    component: CopyAssessment,
    state: {
      parentNav: "created-by-me",
    },
  },
  // {
  //   path: ComponentUrls.teacherList,
  //   component: TeacherList,
  //   state: {
  //     parentNav: "teacher",
  //   },
  // },
  {
    path: `/${ComponentUrls.addTeacherRoster}`,
    component: AddTeacherPage,
    state: {
      parentNav: "teacher-list",
    },
  },
  {
    path: ComponentUrls.editTeacherRosters,
    component: EditTeacher,
    state: {
      parentNav: "teacher-list",
    },
  },
  {
    path: ComponentUrls.transferTeacherRoster,
    component: TransferTeacher,
    state: {
      parentNav: "teacher-list",
    },
  },
  {
    path: ComponentUrls.editStudentRosters,
    component: EditStudent,
    state: {
      parentNav: "student-list",
    },
  },
  {
    path: `/${ComponentUrls.addStudentRosters}`,
    component: AddStudentPage,
    state: {
      parentNav: "student-list",
    },
  },
  {
    path: ComponentUrls.takeAssessment,
    component: TakeAssessment,
    state: {
      parentNav: `assigned-assessments`,
      name: dynamicComponentNames.TAKE_ASSESSMENT,
    },
  },
  {
    path: ComponentUrls.viewScore,
    component: ViewScore,
    state: {
      parentNav: `graded-assessments`,
      name: dynamicComponentNames.VIEW_SCORE,
    },
  },
  {
    path: ComponentUrls.attemptTest,
    component: AttemptTest,
    state: {
      parentNav: `attempt-test`,
      name: dynamicComponentNames.ATTEMPT_TEST,
    },
  },
  {
    path: ComponentUrls.classReportByStudent,
    component: ClassReportByStudent,
    state: {
      parentNav: `class-report-by-student`,
      name: dynamicComponentNames.CLASS_REPORT_BY_STUDENT,
    },
  },

  {
    path: ComponentUrls.standardReportDetail,
    component: StandardReportDetail,
    state: {
      parentNav: `standard-report-detail`,
      name: dynamicComponentNames.STANDARD_REPORT_DETAIL,
      type: "standard-report-detail",
    },
  },
  {
    path: ComponentUrls.addClassRosters,
    component: AddClass,
    state: {
      parentNav: "class-list",
    },
  },
  {
    path: ComponentUrls.editClassRosters,
    component: EditClass,
    state: {
      parentNav: "class-list",
    },
  },
  {
    path: ComponentUrls.reassignClassRosters,
    component: ReassignClass,
    state: {
      parentNav: "rosters",
    },
  },
  {
    title: "My Students",
    path: `/${ComponentUrls.studentRostersByClass}`,
    component: MyStudents,
    state: {
      parentNav: "my-student-rosters",
    },
  },
  {
    title: "Transfer Student",
    path: `/${ComponentUrls.transferStudentRosters}`,
    component: TransferStudent,
    state: {
      parentNav: "student-list",
    },
  },
  {
    title: "Class Bulk Upload",
    path: `/${ComponentUrls.classBulkUploadRosters}`,
    component: ClassBulkUpload,
    state: {
      parentNav: "class-list",
    },
  },
  {
    path: `/${ComponentUrls.classBulkUploadRostersResult}`,
    component: ClassBulkUploadResult,
    state: {
      parentNav: "class-list",
    },
  },
  {
    title: "Student Bulk Upload",
    path: `/${ComponentUrls.studentBulkUploadRosters}`,
    component: StudentBulkUpload,
    state: {
      parentNav: "student-list",
    },
  },
  {
    path: `/${ComponentUrls.studentBulkUploadRostersResult}`,
    component: StudentBulkUploadResult,
    state: {
      parentNav: "student-list",
    },
  },
  {
    title: "Teacher Bulk Upload",
    path: `/${ComponentUrls.teacherBulkUploadRosters}`,
    component: TeacherBulkUpload,
    state: {
      parentNav: "teacher-list",
    },
  },
  {
    path: `/${ComponentUrls.teacherBulkUploadRostersResult}`,
    component: TeacherBulkUploadResult,
    state: {
      parentNav: "teacher-list",
    },
  },
  {
    title: "SA Standard Report",
    path: `/${ComponentUrls.saStandardReportByGrade}`,
    component: SA_StandardReport,
    state: {
      type: "standard-report",
      parentNav: "standard-report",
    },
  },
  {
    title: "DA Standard Report",
    path: `/${ComponentUrls.daStandardReportByGrade}`,
    component: DA_StandardReport,
    state: {
      type: "standard-report",
      parentNav: "standard-report",
    },
  },
];

export const dynamicRoutesByRole = (role) => {
  // console.log("DynamicRoutesByRole", role);
  role = role && role.replace(/ /g, "").toUpperCase();
  switch (role) {
    case "DISTRICTADMIN":
      return createPagesByRole("DistrictAdmin");
    case "NTNADMIN":
      return createPagesByRole("NtnAdmin");
    case "SCHOOLADMIN":
      return createPagesByRole("SchoolAdmin");
    case "STUDENT":
      return [];
    case "TEACHER":
      return [];
    default:
      return [];
  }
};

const createPagesByRole = (role) => {
  //console.log("role", role);

  const AddClass = React.lazy(() =>
    import(`../../../pages/Rosters/${role}/Classes/AddClass`)
  );
  const AddTeacherPage = React.lazy(() =>
    import(`../../../pages/Rosters/${role}/Teacher/AddTeacherPage`)
  );
  const AddStudentPage = React.lazy(() =>
    import(`../../../pages/Rosters/${role}/Students/AddStudentPage`)
  );
  const EditTeacher = React.lazy(() =>
    import(`../../../pages/Rosters/${role}/Teacher/EditTeacherRoster`)
  );
  const EditClass = React.lazy(() =>
    import(`../../../pages/Rosters/${role}/Classes/EditClass`)
  );
  const ReassignClass = React.lazy(() =>
    import(`../../../pages/Rosters/${role}/Classes/ReassignClass`)
  );

  return [
    {
      path: `/${ComponentUrls.addStudentRosters}`,
      component: AddStudentPage,
      state: {
        parentNav: "student-list",
      },
    },
    {
      path: ComponentUrls.addClassRosters,
      component: AddClass,
      state: {
        parentNav: "class-list",
      },
    },
    {
      path: ComponentUrls.editClassRosters,
      component: EditClass,
      state: {
        parentNav: "class-list",
      },
    },
    {
      path: ComponentUrls.reassignClassRosters,
      component: ReassignClass,
      state: {
        parentNav: "rosters",
      },
    },
    {
      path: ComponentUrls.editTeacherRosters,
      component: EditTeacher,
      state: {
        parentNav: "teacher-list",
      },
    },
    {
      path: `/${ComponentUrls.addTeacherRoster}`,
      component: AddTeacherPage,
      state: {
        parentNav: "rosters",
      },
    },
  ];
};
