import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import React from "react";
import { ComponentUrls } from "../component-names";
import * as CiYoutube from "react-icons/ci";
export const Mine = React.lazy(() =>
  import("../../../pages/Assessments/SchoolAdmin/Mine")
);
export const Premade = React.lazy(() =>
  import("../../../pages/Assessments/SchoolAdmin/Premade")
);
export const Shared = React.lazy(() =>
  import("../../../pages/Assessments/SchoolAdmin/Shared")
);
export const SA_StandardReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/Standard/StandardReport")
);

export const ClassReports = React.lazy(() =>
  import("../../../pages/Reports/Teacher/Class/ClassReport")
);

export const SchoolReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/School/SchoolReport")
);

export const AssessmentReports = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/Assessment/AssessmentReport")
);

export const StudentReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/Student/StudentReport")
);

export const StudentClassReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/ClassReport/ClassReport")
);

export const BaselineReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/Baseline/BaselineReport")
);

export const BaselineStudentReport = React.lazy(() =>
  import("../../../pages/Reports/SchoolAdmin/Baseline/BaselineStudentReport")
);

export const MyClasses = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Classes/Classes")
);

export const StudentList = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Students/Students")
);

export const TeacherList = React.lazy(() =>
  import("../../../pages/Rosters/SchoolAdmin/Teacher/TeacherList")
);

export const PromoteStudents = React.lazy(() =>
  import("../../../pages/Rosters/StudentPromotion/PromoteStudents")
);

export const Settings = React.lazy(() => import("../../../pages/Settings"));
export const Help = React.lazy(() => import("../../../pages/Help"));
export const PDOnDemand = React.lazy(() => import("../../../pages/PDOnDemand"));

export const SchoolAdminNavbar = [
  {
    path: "/",
    state: {
      type: "standard-report",
    },
    component: SA_StandardReport,
  },
  {
    title: "Performance Reports",
    path: "#",
    state: {
      type: null,
    },
    childTags: [
      "standard-report",
      "school-report",
      "assessment-report",
      "student-report",
      "baseline-assessment-report",
      "baseline-student-report",
    ],
    icons: <AiIcons.AiOutlineUnorderedList />,
    subMenu: [
      {
        title: "Standard Report",
        path: `#`,
        childTags: ["standard-student-report", "standard-report"],
        state: {
          type: "standard-report",
        },
        subMenu: [
          {
            title: "Standard Report",
            path: `/${ComponentUrls.standardReports}`,
            component: SA_StandardReport,
            state: {
              type: "standard-report",
            },
            childTags: ["standard-report"],
          },
          {
            title: "Student Report",
            path: `/${ComponentUrls.standardStudentReports}`,
            component: StudentReport,
            state: {
              type: "standard-student-report",
            },
            childTags: ["standard-student-report"],
          },
        ],
      },
      {
        title: "School Report",
        path: `/${ComponentUrls.schoolReports}`,
        component: SchoolReport,
        childTags: ["school-report"],
        state: {
          type: "school-report",
        },
      },
      {
        title: "Assessment Report",
        path: `/${ComponentUrls.assessmentReports}`,
        component: AssessmentReports,
        childTags: ["assessment-report"],
        state: {
          type: "assessment-report",
        },
      },
      {
        title: "Class Report",
        path: `/${ComponentUrls.studentReports}`,
        component: StudentClassReport,
        childTags: ["student-report"],
        state: {
          type: "student-report",
        },
      },
      {
        title: "Baseline Report",
        path: `#`,
        // component: BaselineReport,
        childTags: ["baseline-assessment-report", "baseline-student-report"],
        // state: {
        //   type: "baseline-report",
        // },
        subMenu: [
          {
            title: "Baseline Assessment Report",
            path: `/${ComponentUrls.baselineReports}`,
            state: {
              type: "baseline-assessment-report",
            },
            childTags: ["baseline-assessment-report"],
            component: BaselineReport,
          },
          {
            title: "Baseline Student Report",
            path: `/${ComponentUrls.baselineStudentReports}`,
            state: {
              type: "baseline-student-report",
            },
            childTags: ["baseline-student-report"],
            component: BaselineStudentReport,
          },
        ],
      },
    ],
  },
  {
    title: "Assessments",
    path: "#",
    state: {
      type: null,
    },
    childTags: [
      "created-by-me",
      "my-assessment",
      "premade-assessment",
      "shared-assessment",
      "shared-by-me",
    ],
    component: Premade,
    icons: <BsIcons.BsGrid3X3Gap />,
    // iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "Premade Assessments",
        path: `/${ComponentUrls.premadeAssessments}`,
        state: {
          type: "premade-assessment",
        },
        childTags: ["premade-assessment"],
        component: Premade,
      },
      {
        title: "Created by Me",
        path: `/${ComponentUrls.createdByMeAssessment}`,
        state: {
          type: "my-assessment",
        },
        childTags: ["my-assessment", "created-by-me"],
        component: Mine,
      },
      {
        title: "Shared Assessments",
        path: `/${ComponentUrls.sharedAssessments}`,
        state: {
          type: "shared-assessment",
        },
        childTags: ["shared-assessment", "shared-by-me"],
        component: Shared,
      },
    ],
  },
  {
    title: "PD On Demand",
    path: `/${ComponentUrls.pdondemand}`,
    state: {
      type: "pdondemand",
    },
    icons: <CiYoutube.CiYoutube />,
    component: PDOnDemand,
  },
  {
    title: "Roster",
    path: `#`,
    icons: <FiIcons.FiUser />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    childTags: [
      "class-list",
      "teacher-list",
      "student-list",
      "promote-students",
      "rosters",
    ],
    subMenu: [
      {
        title: "Classes",
        path: `/${ComponentUrls.classRosters}`,
        component: MyClasses,
        state: {
          type: "class-list",
        },
        childTags: ["class-list"],
      },
      {
        title: "Teachers",
        path: `/${ComponentUrls.teacherRoster}`,
        state: {
          type: "teacher-list",
        },
        childTags: ["teacher-list"],
        component: TeacherList,
      },
      {
        title: "Students",
        path: `/${ComponentUrls.studentRosters}`,
        state: {
          type: "student-list",
        },
        childTags: ["student-list"],
        component: StudentList,
      },
      {
        path: `/${ComponentUrls.promoteStudents}`,
        component: PromoteStudents,
        state: {
          parentNav: "promote-students",
        },
      },
    ],
  },
  {
    title: "Settings",
    path: "/Settings",
    icons: <IoIcons.IoMdSettings />,
    iconsOpened: <RiIcons.RiArrowDownSLine />,
    subMenu: [
      {
        title: "LTI Setup",
        path: "/LTISetup",
      },
      {
        title: "Google Classroom V2",
        path: "/GoogleClassroomV2",
      },
    ],
  },
  {
    title: "Help",
    path: `/${ComponentUrls.help}`,
    state: {
      type: "help",
    },
    icons: <IoIcons.IoMdHelpCircleOutline />,
    component: Help,
  },
];
