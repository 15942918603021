export const CREATE_ASSESSMENT_REQUEST = "CREATE_ASSESSMENT_REQUEST";
export const REMOVE_CREATE_ASSESSMENT_REQUEST =
  "REMOVE_CREATE_ASSESSMENT_REQUEST";
export const CREATE_ASSESSMENT_REQUEST_STEP = "CREATE_ASSESSMENT_REQUEST_STEP";

export const GET_ASSESSMENT_REQUEST = "GET_ASSESSMENT_REQUEST";
export const GET_ASSESSMENT_SUCCESS = "GET_ASSESSMENT_SUCCESS";
export const GET_ASSESSMENT_ERROR = "GET_ASSESSMENT_ERROR";
export const GET_ASSESSMENT_DETAIL = "GET_ASSESSMENT_DETAIL";
export const GET_ASSESSMENT_BY_ID = "GET_ASSESSMENT_BY_ID";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
