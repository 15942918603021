import * as types from "./actionTypes";
const initialState = {
  loading: false,
  assessmentList: null,
  assignmentList: null,
  assignmentTypeList: null,
  gradeList: null,
  assessmentTypesList: null,
  studentList: null,
  classList: null,
  schoolList: null,
  standardList: null,
  teacherList: null,
  reportFilterData: null,
  standardReportFilterData: null,
  classReportFilterData: null,
  classGroupList: null,
  reportSteps: null,
};
const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GRADE_LIST:
      return {
        ...state,
        gradeList: action.payload,
        loading: false,
      };
    case types.SET_STANDARD_LIST:
      return {
        ...state,
        standardList: action.payload,
        loading: false,
      };
    case types.SET_ASSESSMENT_LIST:
      return {
        ...state,
        assessmentList: action.payload,
        loading: false,
      };
    case types.SET_ASSIGNMENT_TYPE_LIST:
      return {
        ...state,
        assignmentTypeList: action.payload,
        loading: false,
      };
    case types.SET_REPORT_FILTER:
      return {
        ...state,
        reportFilterData: action.payload,
        loading: false,
      };
    case types.SET_CLASS_LIST:
      return {
        ...state,
        classList: action.payload,
        loading: false,
      };

    case types.SET_SCHOOL_LIST:
      return {
        ...state,
        schoolList: action.payload,
        loading: false,
      };
    case types.SET_TEACHER_LIST:
      return {
        ...state,
        teacherList: action.payload,
        loading: false,
      };
    case types.SET_STUDENT_LIST:
      return {
        ...state,
        studentList: action.payload,
        loading: false,
      };
    case types.SET_CLASS_REPORT_FILTER:
      return {
        ...state,
        classReportFilterData: action.payload,
        loading: false,
      };
    case types.SET_STANDARD_REPORT_FILTER:
      return {
        ...state,
        standardReportFilterData: action.payload,
        loading: false,
      };
    case types.SET_TEACHER_LIST:
      return {
        ...state,
        teacherList: action.payload,
        loading: false,
      };

    case types.SET_CLASS_GROUP_LIST:
      return {
        ...state,
        classGroupList: action.payload,
        loading: false,
      };
    case types.SET_REPORTS_STEPS:
      return {
        ...state,
        reportSteps: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default questionReducer;
