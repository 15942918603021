import React, { useEffect, useState } from "react";
import "./navbar.scss";
import SubMenu from "./SubMenu";
import { useSelector, useDispatch } from "react-redux";
import { loadSidebar } from "../../store/global";
import { useNtnRedirects } from "../../hooks";
import { dynamicComponentNames } from "../../utils/constants/constants";
const NavBar = (props) => {
  const sidebar = useSelector((state) => state.global.sidebar);
  const [hideSidebar, setHideSidebar] = useState(false);
  const { getActiveRoute } = useNtnRedirects();
  const dispatch = useDispatch();
  const handleSidebar = () => {
    dispatch(loadSidebar());
  };
  const activeRoute = getActiveRoute();
  useEffect(() => {
    if (activeRoute) {
      const isHidden =
        (activeRoute?.state?.name &&
          activeRoute?.state?.name === dynamicComponentNames.TAKE_ASSESSMENT) ||
        (activeRoute?.state?.name &&
          activeRoute?.state?.name === dynamicComponentNames.ATTEMPT_TEST)
          ? true
          : false;
      setHideSidebar(isHidden);
    }
  }, [activeRoute]);
  return (
    <>
      {!hideSidebar ? (
        <nav id="sidebar" className={`nav-menu ` + (sidebar ? `active` : ``)}>
          <div className="nav-div">
            <ul className="list-unstyled components">
              {props.items?.map((item, index) => {
                return (
                  <SubMenu handler={handleSidebar} item={item} key={index} />
                );
              })}
            </ul>
          </div>
        </nav>
      ) : null}
    </>
  );
};

export default NavBar;
