import * as types from "./actionTypes";

const initialState = {
  webcamCrashed: null,
  documentData: null,
  testIds: null,
  currentDocument: null,
};

const scannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SCANNER_DATA:
      return {
        ...state,
        documentData: action.payload,
      };
    case types.SET_WEBCAM_CRASHED:
      return {
        ...state,
        webcamCrashed: action.payload,
      };
    case types.SET_TEST_IDS:
      return {
        ...state,
        testIds: action.payload,
      };
    case types.SET_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload,
      };
    default:
      return state;
  }
};

export default scannerReducer;
