//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const tagFilterListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagFilterList(request)
      .then((response) => {
        dispatch({
          type: types.SET_TAG_FILTER_LIST,
          payload: response?.data,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagQuestionListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagQuestionList(request)
      .then((response) => {
        dispatch({
          type: types.SET_TAG_QUESTIONS,
          payload: response.data?.questions,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagPreviewEditQuestionDetailAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagPreviewEditQuestionDetail(request)
      .then((response) => {
        // dispatch({
        //   type: types.SET_TAG_QUESTIONS,
        //   payload: response.data?.questions,
        // });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagGetEditQuestionDetailAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagGetEditQuestionDetail(request)
      .then((response) => {
        // dispatch({
        //   type: types.SET_TAG_QUESTIONS,
        //   payload: response.data?.questions,
        // });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagEditQuestionAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagEditQuestion(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagCopyQuestionAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagCopyQuestion(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagDeleteQuestionAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagDeleteQuestion(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagCreateQuestionAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagCreateQuestion(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

// assessments

export const tagAssessmentFilterListsAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagAssessmentFilterLists(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagAssessmentListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagAssessmentList(request)
      .then((response) => {
        dispatch({
          type: types.SET_TAG_ASSESSMENT_LIST,
          payload: response?.data?.assessments,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagSaveAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagSaveAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagEditAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagEditAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagCopyAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagCopyAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagGetAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagGetAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagDeleteAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagDeleteAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagPublishAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagPublishAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const tagAssessmentGradesAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.tagAssessmentGrades(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

//tagRefreshSpeechText
export const tagRefreshSpeechTextAction = (request) => {
  return async (dispatch) => {
    // dispatch(apiLoadingStart());
    return await HttpApis.tagRefreshSpeechText(request)
      .then((response) => {
        // dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        // dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const createAssessmentRequestAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_TAG_CREATE_ASSESSMENT_REQUEST,
      payload: request,
    });
  };
};

export const saveQuestionFilterAction = (request) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_TAG_QUESTION_SAVED_FILTER,
      payload: request,
    });
  };
};
