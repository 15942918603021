import * as types from "./actionTypes";
const initialState = {
  loading: false,
  questionList: null,
  gradeList: null,
  strandList: null,
  standardList: null,
  skillList: null,
  filterList: null,
  assessments: null,
  createAssessmentRequest: null,
  questionSavedFilters: null,
};
const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TAG_GRADE_LIST:
      return {
        ...state,
        gradeList: action.payload,
        loading: false,
      };
    case types.SET_TAG_STRAND_LIST:
      return {
        ...state,
        strandList: action.payload,
        loading: false,
      };
    case types.SET_TAG_STANDARD_LIST:
      return {
        ...state,
        standardList: action.payload,
        loading: false,
      };
    case types.SET_TAG_SKILL_LIST:
      return {
        ...state,
        skillList: action.payload,
        loading: false,
      };
    case types.SET_TAG_FILTER_LIST:
      return {
        ...state,
        filterList: action.payload,
        loading: false,
      };
    case types.SET_TAG_QUESTIONS:
      return {
        ...state,
        questionList: action.payload,
        loading: false,
      };
    case types.SET_TAG_ASSESSMENT_LIST:
      return {
        ...state,
        assessments: action.payload,
        loading: false,
      };

    case types.SET_TAG_CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        createAssessmentRequest: action.payload,
        loading: false,
      };

    case types.SET_TAG_QUESTION_SAVED_FILTER:
      return {
        ...state,
        questionSavedFilters: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default tagReducer;
