export const SET_GRADE_LIST = "SET_GRADE_LIST";
export const SET_CLASS_LIST = "SET_CLASS_LIST";
export const SET_SCHOOL_LIST = "SET_SCHOOL_LIST";
export const SET_TEACHER_LIST = "SET_TEACHER_LIST";
export const SET_ASSESSMENT_LIST = "SET_ASSESSMENT_LIST";
export const SET_STRAND_LIST = "SET_STRAND_LIST";
export const SET_ASSIGNMENT_TYPE_LIST = "SET_ASSIGNMENT_TYPE_LIST";
export const SET_ASSESSMENT_TYPE_LIST = "SET_ASSESSMENT_TYPE_LIST";
export const SET_STANDARD_LIST = "SET_STANDARD_LIST";
export const SET_REPORT_FILTER = "SET_REPORT_FILTER";
export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
export const SET_CLASS_REPORT_FILTER = "SET_CLASS_REPORT_FILTER";
export const SET_STANDARD_REPORT_FILTER = "SET_STANDARD_REPORT_FILTER";
export const SET_CLASS_GROUP_LIST = "SET_CLASS_GROUP_LIST";
export const SET_REPORTS_STEPS = "SET_REPORTS_STEPS";
