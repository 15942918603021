import "./style.scss";
import React, { useState } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import { helpfulLinks } from "../../utils/constants/constants";
const PublicFooter = () => {
  return (
    <Container className="public-footer-container" fluid>
      <Row xs={1} md={3} className="footer-container">
        <Col className="align-center-center align-start">
          <Row>
            <Col>
              <h6>NTN CONTACT INFO</h6>
              <span className="footer-contact-text">
                PO Box 36
                <br /> Summerfield, NC 27358
                <br /> Phone: 855.686.6284 <br />
                <a className="text-white" href="mailto:info@ntnmath.com">
                  Email: info@ntnmath.com
                </a>
              </span>
            </Col>
          </Row>
        </Col>
        <Col className="align-center-center align-start">
          <Row className="flex-direction-coulmn">
            <Col className="align-center-center">
              <FaFacebookSquare className="size-32" />
              <FaTwitterSquare className="size-32" />
              <FaLinkedin className="size-32" />
            </Col>
            <Col className="pt-4">
              <Button className="light-green-btn" size="sm">
                <MdContactPhone /> CONTACT US
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="align-center-center flex-direction-coulmn">
          <h6 className="helpful-links-heading">Helpful links</h6>
          <Row className="helpful-links-container">
            <Col className="flex-direction-coulmn ntn-list-navigations">
              <ListGroup as={"ul"} variant="flush" bsPrefix="ntn-list-group">
                {helpfulLinks.map((l, key) => {
                  return (
                    <>
                      <ListGroup.Item
                        key={key}
                        action
                        bsPrefix="ntn-list-group-item"
                        as={"li"}
                      >
                        <a
                          target={l.target}
                          className="text-white"
                          href={l.url}
                        >
                          {l.label}
                        </a>
                      </ListGroup.Item>
                      <hr key={`ntn-devider`} className="ntn-devider" />
                    </>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default PublicFooter;
