import * as types from "./actionTypes";
const initialState = {
  count: 0,
  data: null,
};
const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALERT_SUCCESS:
      return {
        ...state,
        data: { ...action.payload },
      };
    case types.GET_ALERT_ERROR:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default alertReducer;
