import * as types from "./actionTypes";

const initialState = {
  loading: false,
  loginstatus: false,
  message: null,
  userData: null,
  userDetail: null,
  sessionData: null,
  activeMenu: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loginstatus: true,
        userData: action.payload,
        loading: false,
        message: null,
      };
    case types.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        loginstatus: false,
        message: action.payload,
        userData: null,
        userDetail: null,
      };
    case types.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loginstatus: false,
        loading: false,
        userData: null,
        userDetail: null,
      };
    case types.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
      };
    case types.GET_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ACCOUNT_SESSION:
      return {
        ...state,
        sessionData: action.payload,
      };
    case types.CONSTANT_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      };
    case types.CLEAR_USER_ACCOUNT:
      return {
        ...state,
        loading: false,
        loginstatus: false,
        message: null,
        userData: null,
        userDetail: null,
        sessionData: null,
        activeMenu: null,
      };

    default:
      return state;
  }
};

export default userReducer;
