export const SET_LICENSE_FILTER_LIST = "SET_LICENSE_FILTER_LIST";
export const SET_LICENSE_LIST = "SET_LICENSE_LIST";
export const CREATE_LICENSE = "CREATE_LICENSE";
export const EDIT_LICENSE = "EDIT_LICENSE";
export const DELETE_LICENSE = "DELETE_LICENSE";
export const GET_LICENSE_DETAIL = "GET_LICENSE_DETAIL";
export const SET_DISTRICT_SCHOOL_FILTER_LIST =
  "SET_DISTRICT_SCHOOL_FILTER_LIST";
export const SET_SCHOOL_SCHOOLADMIN_LIST = "SET_SCHOOL_SCHOOLADMIN_LIST";
export const SET_LICENSE_DETAILS = "SET_LICENSE_DETAILS";
export const SET_STATE_LIST = "SET_STATE_LIST";
//FORMS
export const SET_LICENSE_FORM = "SET_LICENSE_FORM";
