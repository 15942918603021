import {
  createDistrictAction,
  deactivateDistrictAction,
  deleteDistrictAction,
  editDistrictAction,
  getDistrictDetailAction,
  listOrganizationDistrictAdminsAction,
  listOrganizationDistrictsAction,
  orgRequestAction,
  organizationFilterListAction,
  reactivateDistrictAction,
  getDistrictListAction,
  getStateListAction,
  editDistrictAdminAction,
  deleteDistrictAdminAction,
  createDistrictAdminAction,
  deactivateDistrictAdminAction,
  reactivateDistrictAdminAction,
  orgGetAccountTypeAction,
  orgGetParentDistrictAction,
  orgGetDistrictAdminDetailAction,
  // schools
  orgSchoolsAction,
  createSchoolAction,
  deactivateSchoolAction,
  deleteSchoolAction,
  editSchoolAction,
  reactivateSchoolAction,
  getSchoolDetailAction,

  // school admins
  orgSchoolAdminsAction,
  createSchoolAdminAction,
  deactivateSchoolAdminAction,
  deleteSchoolAdminAction,
  editSchoolAdminAction,
  reactivateSchoolAdminAction,
  getSchoolAdminDetailAction,
  orgGetSchoolByDistrictAction,
  orgGetStateStandardAction,
} from "../store/organization/actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export const useOrg = () => {
  const orgData = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState(null);
  const [districtList, setDistrictList] = useState(null);
  const [orgRequest, setOrgRequest] = useState(null);

  useEffect(() => {
    if (orgData?.districtList) {
      setDistrictList(orgData.districtList);
    }

    if (orgData.filterList) {
      setFilterList(orgData.filterList);
    }

    if (orgData?.orgRequest) {
      setOrgRequest(orgData.orgRequest);
    }
  }, [orgData]);

  const handleOrgFilters = async (request) => {
    return await dispatch(organizationFilterListAction(request));
  };

  const handleDistrictList = async (request) => {
    return await dispatch(listOrganizationDistrictsAction(request));
  };

  const handleDistrictEdit = async (request) => {
    return await dispatch(editDistrictAction(request));
  };

  const handlDistrictDelete = async (request) => {
    return await dispatch(deleteDistrictAction(request));
  };

  const handleCreateDistrict = async (request) => {
    return await dispatch(createDistrictAction(request));
  };

  const handleOrgRequest = async (request) => {
    return await dispatch(orgRequestAction(request));
  };

  const handleGetDistrict = async (request) => {
    return await dispatch(getDistrictDetailAction(request));
  };

  const handleDistrictDeactivate = async (request) => {
    return await dispatch(deactivateDistrictAction(request));
  };

  const handlDistrictReactivate = async (request) => {
    return await dispatch(reactivateDistrictAction(request));
  };

  const handleGetDistrictList = async (request) => {
    return await dispatch(getDistrictListAction(request));
  };

  const handleGetStateList = async (request) => {
    return await dispatch(getStateListAction(request));
  };

  const getDistrictById = (id) => {
    const district = orgData.districtList?.find((a) => a.id == id);
    return district;
  };

  const handleDistrictAdminList = async (request) => {
    return await dispatch(listOrganizationDistrictAdminsAction(request));
  };

  const handleDistrictAdminEdit = async (request) => {
    return await dispatch(editDistrictAdminAction(request));
  };

  const handleDistrictAdminDelete = async (request) => {
    return await dispatch(deleteDistrictAdminAction(request));
  };

  const handleCreateAdminDistrict = async (request) => {
    return await dispatch(createDistrictAdminAction(request));
  };

  const handleDistrictAdminDeactivate = async (request) => {
    return await dispatch(deactivateDistrictAdminAction(request));
  };

  const handleDistrictAdminReactivate = async (request) => {
    return await dispatch(reactivateDistrictAdminAction(request));
  };

  const handleGetAccountType = async (request) => {
    return await dispatch(orgGetAccountTypeAction(request));
  };

  const handleGetStateStandards = async (request) => {
    return await dispatch(orgGetStateStandardAction(request));
  };

  const handleGetSchoolByDistrict = async (request) => {
    return await dispatch(orgGetSchoolByDistrictAction(request));
  };

  const handleParentDistrict = async (request) => {
    return await dispatch(orgGetParentDistrictAction(request));
  };

  const handleGetDistrictAdmin = async (request) => {
    return await dispatch(getDistrictDetailAction(request));
  };

  const handleGetDistrictAdminDetail = async (request) => {
    return await dispatch(orgGetDistrictAdminDetailAction(request));
  };

  // schools

  const handleSchoolList = async (request) => {
    return await dispatch(orgSchoolsAction(request));
  };

  const handleSchoolEdit = async (request) => {
    return await dispatch(editSchoolAction(request));
  };

  const handleSchoolDelete = async (request) => {
    return await dispatch(deleteSchoolAction(request));
  };

  const handleSchoolCreate = async (request) => {
    return await dispatch(createSchoolAction(request));
  };

  const handleSchoolDeactivate = async (request) => {
    return await dispatch(deactivateSchoolAction(request));
  };

  const handleSchoolReactivate = async (request) => {
    return await dispatch(reactivateSchoolAction(request));
  };

  const handleGetSchoolDetail = async (request) => {
    return await dispatch(getSchoolDetailAction(request));
  };

  // school Admin

  const handleSchoolAdminList = async (request) => {
    return await dispatch(orgSchoolAdminsAction(request));
  };

  const handleSchoolAdminEdit = async (request) => {
    return await dispatch(editSchoolAdminAction(request));
  };

  const handleSchoolAdminDelete = async (request) => {
    return await dispatch(deleteSchoolAdminAction(request));
  };

  const handleSchoolAdminCreate = async (request) => {
    return await dispatch(createSchoolAdminAction(request));
  };

  const handleSchoolAdminDeactivate = async (request) => {
    return await dispatch(deactivateSchoolAdminAction(request));
  };

  const handleSchoolAdminReactivate = async (request) => {
    return await dispatch(reactivateSchoolAdminAction(request));
  };

  const handleGetSchoolAdminDetail = async (request) => {
    return await dispatch(getSchoolAdminDetailAction(request));
  };

  return {
    filterList,
    districtList,
    orgRequest,
    handleOrgFilters,
    handleDistrictList,
    handleDistrictEdit,
    handlDistrictDelete,
    handleCreateDistrict,
    handleOrgRequest,
    handleGetDistrict,
    getDistrictById,
    handleDistrictDeactivate,
    handlDistrictReactivate,
    handleGetDistrictList,
    handleGetStateList,
    handleDistrictAdminList,
    handleDistrictAdminEdit,
    handleDistrictAdminDelete,
    handleCreateAdminDistrict,
    handleDistrictAdminDeactivate,
    handleDistrictAdminReactivate,
    handleGetAccountType,
    handleGetStateStandards,
    handleGetSchoolByDistrict,
    handleParentDistrict,
    handleGetDistrictAdmin,
    handleGetDistrictAdminDetail,
    //schools
    handleSchoolList,
    handleSchoolEdit,
    handleSchoolDelete,
    handleSchoolCreate,
    handleSchoolDeactivate,
    handleSchoolReactivate,
    handleGetSchoolDetail,
    // school admin
    handleSchoolAdminList,
    handleSchoolAdminEdit,
    handleSchoolAdminDelete,
    handleSchoolAdminCreate,
    handleSchoolAdminDeactivate,
    handleSchoolAdminReactivate,
    handleGetSchoolAdminDetail,
  };
};

export default useOrg;
