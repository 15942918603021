import {
  assessmentAction,
  premadeAssessmentAction,
  myAssessmentAction,
  getShareTeachersAction,
  saveShareTeachersAction,
  getShareListAction,
  acceptAssessmentAction,
  getAssessmentDetailAction,
  deleteSharedAssessmentAction,
  questionFiltersListAction,
  questionsListAction,
  createAssessmentAction,
  saveCreateAssessmentRequest,
  removeCreateAssessmentRequest,
  createAssessmentRequestStep,
  deleteAssessmentAction,
  getAssessmentByIdAction,
  getStudentAssignedAssessmentsAction,
  cancelAssessmentAction,
  getDocumentAction,
  getDocumentFiltersAction,
  submitAnswerImageAction,
  getMyAssessmentFilterAction,
  autoGradeAction,
  unassignAssessmentAction,
  createFakeAssessmentRequestAction,
} from "../store/assessment/actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
export const useAssessment = () => {
  const assessmentData = useSelector((state) => state.assessment);

  const dispatch = useDispatch();

  const [assessmentRequest, setAssessmentRequest] = useState({});

  const handleAssessment = (request) => {
    return dispatch(assessmentAction(request));
  };

  const handlePremadeAssessment = (request) => {
    return dispatch(premadeAssessmentAction(request));
  };

  const handleMyAssessment = (request) => {
    return dispatch(myAssessmentAction(request));
  };

  const handleGetShareTeachers = (request) => {
    return dispatch(getShareTeachersAction(request));
  };

  const handleSaveShareTeachers = (request) => {
    return dispatch(saveShareTeachersAction(request));
  };

  const handleGetShareList = (request) => {
    return dispatch(getShareListAction(request));
  };

  const handleAcceptAssessment = (request) => {
    return dispatch(acceptAssessmentAction(request));
  };

  const handleGetAssessmentDetail = async (request) => {
    return await dispatch(getAssessmentDetailAction(request));
  };

  const handleDeleteSharedAssessment = async (request) => {
    return await dispatch(deleteSharedAssessmentAction(request));
  };

  const handleQuestionFiltersList = async (request) => {
    return await dispatch(questionFiltersListAction(request));
  };

  const handleQuestionsList = async (request) => {
    return await dispatch(questionsListAction(request));
  };

  const handleCreateAssessment = async (request) => {
    return await dispatch(createAssessmentAction(request));
  };

  const handleDeleteAssessment = async (request) => {
    return await dispatch(deleteAssessmentAction(request));
  };

  const handleCancelAssessment = async (request) => {
    return await dispatch(cancelAssessmentAction(request));
  };

  const handleGetStudentAssignedAssessments = async (request) => {
    return await dispatch(getStudentAssignedAssessmentsAction(request));
  };

  const getAssessmentById = (id) => {
    const assessments = assessmentData.assessmentList?.filter(
      (a) => a.id == id
    );
    if (assessments && assessments.length) {
      return assessments[0];
    }
    return null;
    //return dispatch(getAssessmentByIdAction(id));
  };
  useEffect(() => {
    if (assessmentData?.createAssessment) {
      setAssessmentRequest(assessmentData.createAssessment);
    }
  }, [assessmentData]);

  const handleSetAssessmentRequest = (data) => {
    setAssessmentRequest(data);
  };
  const saveAssessmentRequest = (request) => {
    dispatch(saveCreateAssessmentRequest(request));
  };

  const removeAssessmentRequest = (request) => {
    dispatch(removeCreateAssessmentRequest(request));
  };

  const assessmentRequestStep = (request) => {
    dispatch(createAssessmentRequestStep(request));
  };

  const handleGetDocumentFilters = async (request) => {
    return await dispatch(getDocumentFiltersAction(request));
  };

  const handleGetDocument = async (request) => {
    return await dispatch(getDocumentAction(request));
  };

  const handleSubmitAnswerImage = async (request) => {
    return await dispatch(submitAnswerImageAction(request));
  };

  const handleAutoGrade = async (request) => {
    return await dispatch(autoGradeAction(request));
  };

  const handleGetMyAssessmentFilter = async (request) => {
    return await dispatch(getMyAssessmentFilterAction(request));
  };

  const handleUnassignAssessment = async (request) => {
    return await dispatch(unassignAssessmentAction(request));
  };

  const createFakeAssessmentRequest = async (request) => {
    return await dispatch(createFakeAssessmentRequestAction(request));
  };

  return {
    handleAssessment,
    handlePremadeAssessment,
    handleMyAssessment,
    handleGetShareTeachers,
    handleSaveShareTeachers,
    handleGetShareList,
    handleAcceptAssessment,
    handleGetAssessmentDetail,
    handleDeleteSharedAssessment,
    handleQuestionFiltersList,
    handleQuestionsList,
    handleCreateAssessment,
    saveAssessmentRequest,
    removeAssessmentRequest,
    assessmentRequestStep,
    assessmentRequest,
    handleDeleteAssessment,
    handleCancelAssessment,
    getAssessmentById,
    handleGetStudentAssignedAssessments,
    handleGetDocumentFilters,
    handleGetDocument,
    handleSubmitAnswerImage,
    handleGetMyAssessmentFilter,
    handleAutoGrade,
    handleUnassignAssessment,
    createFakeAssessmentRequest,
    handleSetAssessmentRequest,
  };
};
