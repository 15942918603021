import { StudentNavbar } from "./StudentNavbar";
import { DistrictAdminNavbar } from "./DistrictAdminNavbar";
import { NTNAdminNavbar } from "./NTNAdminNavbar";
import { SchoolAdminNavbar } from "./SchoolAdminNavbar";
import { TeacherNavbar } from "./TeacherNavbar";
const natigations = (role) => {
  role = role && role.replace(/ /g, "").toUpperCase();
    switch (role) {
      case "DISTRICTADMIN":
        return DistrictAdminNavbar;
      case "NTNADMIN":
        return NTNAdminNavbar;
      case "SCHOOLADMIN":
        return SchoolAdminNavbar;
      case "STUDENT":
        return StudentNavbar;
      case "TEACHER":
        return TeacherNavbar;
        default:
        return [];
  };
}

export {natigations};