import React from "react";

export const Login = React.lazy(() => import("../pages/Auth/Login"));
export const InvalidLogin = React.lazy(() =>
  import("../pages/Auth/InvalidLogin")
);
export const Welcome = React.lazy(() => import("../pages/Welcome"));

export const CoppaApproval = React.lazy(() =>
  import("../pages/PublicPages/CoppaApproval")
);
// export const MsalSignin = React.lazy(() => import("../pages/Auth/MsalSignin"));

// export const Welcome = React.lazy(() => import("../pages/Welcome"));
// export const Dashboard = React.lazy(() => import("../pages/Dashboard"));
// export const Assigned = React.lazy(() =>
//   import("../pages/Assessments/Assigned")
// );
// export const Premade = React.lazy(() => import("../pages/Assessments/Premade"));
// export const Shared = React.lazy(() => import("../pages/Assessments/Shared"));
// export const Unpublished = React.lazy(() =>
//   import("../pages/Assessments/Unpublished")
// );

// export const NotFound = React.lazy(() =>
//   import("../components/NotFound/NotFound")
// );
