// import store from "../store";
//import { showSnackbarAction } from "../store/Snackbar/SnackbarAction";
import moment from "moment";
import Auth from "../services/Auth";
// To show error message that returned from backend
export function dispatchSnackbarError(data) {
  if (data) {
    // const errorMsg = data.error.message;
    // store.dispatch( showSnackbarAction(errorMsg, "error"));
  }
}
// To show success message after any success request if needed and rendered from locale files
export function dispatchSnackbarSuccess(message) {
  // const lang = store.getState().lang;
  // store.dispatch(
  // showSnackbarAction(messages[lang].snackbar[message] , "success")
  // );
}
export const groupBy = (keys) => (array) =>
  array.reduce((callBackFn, initialValue) => {
    const value = keys.map((key) => initialValue[key]).join("-");
    callBackFn[value] = (callBackFn[value] || []).concat(initialValue);
    return callBackFn;
  }, {});

export const numberOnly = (e) => {
  return e.target.value.replace(/\D/g, "");
};
export const allowOnlyNumber = (evt) => {
  console.log("called");
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};
export const getInitials = (string) => {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const isTokenAboutToExpire = () => {
  const now = new Date();
  const expireTime = new Date(Auth.getTokenExpiresIn());
  const isWaiting = Auth.getTokenWaiting();
  const diff = expireTime.getTime() - now.getTime();
  const minuts = diff / 60000;
  // console.log("minuts", minuts, "isWaiting", isWaiting);
  if (minuts <= 15 && !isWaiting) {
    return true;
  }
  return false;
};

export const htmlParser = (html) => {
  var doc = new DOMParser().parseFromString(html, "text/html");
  return doc.documentElement.textContent;
};

export const calendarDateFormat = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const htmlEnDeCode = (function () {
  var charToEntityRegex, entityToCharRegex, charToEntity, entityToChar;

  function resetCharacterEntities() {
    charToEntity = {};
    entityToChar = {};
    // add the default set
    addCharacterEntities({
      "&amp;": "&",
      "&gt;": ">",
      "&lt;": "<",
      "&quot;": '"',
      "&#39;": "'",
    });
  }

  function addCharacterEntities(newEntities) {
    var charKeys = [],
      entityKeys = [],
      key,
      echar;
    for (key in newEntities) {
      echar = newEntities[key];
      entityToChar[key] = echar;
      charToEntity[echar] = key;
      charKeys.push(echar);
      entityKeys.push(key);
    }
    charToEntityRegex = new RegExp("(" + charKeys.join("|") + ")", "g");
    entityToCharRegex = new RegExp(
      "(" + entityKeys.join("|") + "|&#[0-9]{1,5};" + ")",
      "g"
    );
  }

  function htmlEncode(value) {
    var htmlEncodeReplaceFn = function (match, capture) {
      return charToEntity[capture];
    };

    return !value
      ? value
      : String(value).replace(charToEntityRegex, htmlEncodeReplaceFn);
  }

  function htmlDecode(value) {
    var htmlDecodeReplaceFn = function (match, capture) {
      return capture in entityToChar
        ? entityToChar[capture]
        : String.fromCharCode(parseInt(capture.substr(2), 10));
    };

    return !value
      ? value
      : String(value).replace(entityToCharRegex, htmlDecodeReplaceFn);
  }

  resetCharacterEntities();

  return {
    htmlEncode: htmlEncode,
    htmlDecode: htmlDecode,
  };
})();

export const hasChildNode = (path, currentNode) => {
  if (currentNode?.subMenu?.length) {
    return currentNode.subMenu.find((f) => {
      // console.log("f.path", f.path, path);
      if (f?.subMenu?.length) {
        return hasChildNode(path, f);
      }
      return f.path === path;
    });
    //console.log("found", found);
  } else {
    return false;
  }
};

export const findRecursiveNode = (path, currentNode) => {
  var i, currentChild, result;

  if (path == currentNode.path) {
    return currentNode;
  } else {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (i = 0; i < currentNode?.subMenu?.length; i += 1) {
      currentChild = currentNode.subMenu[i];

      // Search in the current child
      result = findRecursiveNode(path, currentChild);

      // Return the result if the node has been found
      if (result !== false) {
        return result;
      }
    }

    // The node has not been found and we have no more options
    return false;
  }
};

export const base64ToFile = (base64, filename) => {
  return fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], filename ? filename : "filename.jpeg");
      return file;
    });
};

export const chunkItems = (items, chunkSize) => {
  var R = [];
  for (var i = 0; i < items.length; i += chunkSize)
    R.push(items.slice(i, i + chunkSize));
  return R;
};

export const removeDuplicates = (items) => {
  let jsonObject = items.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  return uniqueArray;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
