//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const getCoppaAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getCoppa(request)
      .then((response) => {
        dispatch({
          type: types.SET_COPPA,
          payload: response?.data,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const submitCoppaAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.submitCoppa(request)
      .then((response) => {
        // dispatch({
        //   type: types.SET_SYSTEM_SETTING,
        //   payload: response?.data,
        // });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};
