import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const getSystemSettingAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.systemSettings(request)
      .then((response) => {
        dispatch({
          type: types.SET_SYSTEM_SETTING,
          payload: response?.data,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const saveUserSettingAction = (request) => {
  return async (dispatch) => {
    // dispatch(apiLoadingStart());
    return await HttpApis.saveUserSettings(request)
      .then((response) => {
        // console.log("response", response);
        dispatch({
          type: types.SAVE_USER_SETTING,
          payload: request,
        });
        // dispatch(apiLoadingStop());
        return response;
      })
      .catch(() => {
        // dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getSpeechVoicesAction = (request) => {
  return async (dispatch) => {
    // dispatch(apiLoadingStart());
    return await HttpApis.getSpeechVoices(request)
      .then((response) => {
        dispatch({
          type: types.SET_SPEECH_VOICES,
          payload: response?.data?.voices,
        });
        // dispatch(apiLoadingStop());
        return response;
      })
      .catch(() => {
        // dispatch(apiLoadingStop());
        return null;
      });
  };
};
