import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loginAction,
  logoutAction,
  checkAccountSecurityAction,
  clearLoginErrorMessage,
  setSessionAction,
  clearUserStore,
} from "../store/user/actions";
import { /*apiLoadingStart,*/ apiLoadingStop } from "../store/global";
import { useMsal } from "@azure/msal-react";
import { msalConfig, logoutRequest } from "../utils/authConfig";
import Auth from "../services/Auth";
import History from "../routes/History";
import { componentRoutes } from "../utils/constants/component-routes";
import { clearAllFilters } from "../store/filters/actions";

export const useAuth = () => {
  const { instance, accounts } = useMsal();
  const userDetail = useSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState(null);

  const [sessionData, setSessionData] = useState(null);

  const dispatch = useDispatch();
  const handleLogin = (request) => {
    dispatch(loginAction(request));
  };

  const handelSetErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const handleClearLoginErrorMessage = () => {
    dispatch(clearLoginErrorMessage());
  };
  useEffect(() => {
    handelSetErrorMessage(userDetail.message);
    setSessionData(userDetail.sessionData);
  }, [userDetail]);

  const handleLogout = () => {
    if (accounts[0]) {
      console.log("msal account found, clearing store and redirecting...");
      const homeAccountId = accounts[0].homeAccountId;
      logoutRequest.account = instance.getAccountByHomeId(homeAccountId);
      dispatch(clearAllFilters()); // clear all preserved filters
      dispatch(logoutAction());
      Auth.signOut();
      //instance.logoutPopup(logoutRequest);
      instance.logoutRedirect(logoutRequest).catch((e) => {
        console.error(e);
      });
    } else {
      console.log("msal account not found, clearing store and redirecting...");
      dispatch(clearAllFilters()); // clear all preserved filters
      dispatch(logoutAction());
      Auth.signOut();
      History.push(componentRoutes.logIn);
    }
  };

  const checkAccountSecurity = async (token) => {
    try {
      return await dispatch(checkAccountSecurityAction(token, instance));
    } catch (error) {
      // console.log("error", error);
      dispatch(apiLoadingStop());
    }
  };

  const acquireMsalToken = async () => {
    try {
      const token = await getAccessToken();
      return await dispatch(checkAccountSecurityAction(token, instance));
    } catch (error) {
      dispatch(apiLoadingStop());
    }
  };

  const getAccessToken = async () => {
    Auth.setTokenWaiting();
    const request = {
      ...msalConfig,
      account: accounts[0],
    };
    try {
      // console.log("Acquiring token Silently",msalConfig);
      const token = await instance.acquireTokenSilent(request);
      Auth.setTokenExpiresIn(token.expiresOn);
      Auth.setToken(token.accessToken);
      Auth.removeTokenWaiting();
      return token.accessToken;
    } catch (error) {
      console.error("error", error.response);
      return instance
        .acquireTokenRedirect(request)
        .then((resp) => {
          Auth.setTokenExpiresIn(resp.expiresOn);
          Auth.setToken(resp.accessToken);
          Auth.removeTokenWaiting();
          return resp.accessToken;
        })
        .catch((err) => {
          console.log(err);
          Auth.removeTokenWaiting();
          History.push("/");
        });
    }
  };

  const handleLoginSession = (request) => {
    dispatch(setSessionAction(request));
  };

  const handleClearUserData = () => {
    dispatch(clearUserStore()); // clear user session
    dispatch(clearAllFilters()); // clear all preserved filters
  };

  return {
    errorMessage,
    sessionData,
    handleLogin,
    handelSetErrorMessage,
    handleClearLoginErrorMessage,
    acquireMsalToken,
    getAccessToken,
    handleLogout,
    checkAccountSecurity,
    handleLoginSession,
    handleClearUserData,
  };
};
