//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";
export const getAssignedAssessmentsAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.assignedAssessments(request)
      .then((response) => {
        dispatch(storeAssignmentList(response.data.assignedAssessments));
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getAssignmentEvaluationAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getAssignmentEvaluation(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const saveAssignmentEvaluationAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.saveAssignmentEvaluation(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const saveStudentAssessmentAction = (request) => {
  return async (dispatch) => {
    if (request?.loading !== false) {
      dispatch(apiLoadingStart());
    }

    return await HttpApis.saveStudentAssessment(request)
      .then((response) => {
        if (request?.loading !== false) {
          dispatch(apiLoadingStop());
        }
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        if (request?.loading !== false) {
          dispatch(apiLoadingStop());
        }
        return null;
      });
  };
};

export const getStudentAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getStudentAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const uploadStudentAssessmentAttachmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.uploadStudentAssessmentAttachment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const saveImageFromBase64Action = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.saveImageFromBase64(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const deleteStudentAssessmentAttachmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.deleteStudentAssessmentAttachment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getFakeStudentAssignmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.studentFakeAssignment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getAssignmentByIdAction = (id) => {
  return (dispatch) => {
    return dispatch({
      type: types.GET_ASSIGNMENT_BY_ID,
      id: id,
    });
  };
};

export const storeAssignmentList = (payload) => ({
  type: types.GET_ASSIGNMENT_SUCCESS,
  payload: payload,
});

export const storeAssignmentDetail = (payload) => ({
  type: types.GET_ASSIGNMENT_DETAIL,
  payload: payload,
});

export const setAssignmentGridTypeAction = (payload) => ({
  type: types.SET_ASSIGNMENT_GRID_TYPE,
  payload: payload,
});
