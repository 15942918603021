import { componentRoutes } from "./component-routes";
import { natigations } from "./navigations";
import {
  DynamicRoutes,
  dynamicRoutesByRole,
} from "./navigations/DynamicRoutes";
const getPageRoutes = (role) => {
  const _pages = natigations(role);
  // let _dynamicRoutesByRole = [];
  // if (role) {
  //   _dynamicRoutesByRole = dynamicRoutesByRole(role);
  // }

  // const _dynamicRoutes = [...DynamicRoutes, ..._dynamicRoutesByRole];
  // console.log(_dynamicRoutes);
  let pages = [];
  if (_pages && _pages.length) {
    _pages.map((page) => {
      if (page.component) {
        pages.push(page);
      }
      if (page.subMenu) {
        page.subMenu.map((page) => {
          if (page.component) {
            pages.push(page);
          }
          // third level
          if (page.subMenu) {
            page.subMenu.map((page) => {
              if (page.component) {
                pages.push(page);
              }
            });
          }
        });
      }
      return page;
    });
    pages = [...pages, ...DynamicRoutes];
    return pages;
  }
  return pages;
};

const getNavigationByRole = (role) => {
  const _pages = natigations(role);
  return _pages;
};
const publicPagesWithNoHeader = [
  componentRoutes.welcome,
  componentRoutes.logIn,
  componentRoutes.invalidLogin,
];
export { getPageRoutes, getNavigationByRole, publicPagesWithNoHeader };
