import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import global from "./global";
import snackbar from "./snackbar";
import user from "./user";
import modal from "./modal";
import assessment from "./assessment";
import alert from "./alert";
import question from "./question";
import assignment from "./assignment";
import report from "./report";
import roster from "./roster";
import tagManager from "./tagManager";
import bundle from "./bundle";
import license from "./license";
import organization from "./organization";
import scanner from "./scanner";
import settings from "./settings";
import coppa from "./coppa";
import filters from "./filters";

const config = {
  key: "root",
  debug: false,
  storage,
};

const AppReducers = combineReducers({
  global,
  snackbar,
  user,
  modal,
  assessment,
  alert,
  question,
  assignment,
  report,
  roster,
  tagManager,
  bundle,
  license,
  organization,
  scanner,
  settings,
  coppa,
  filters,
});

const rootReducer = (state, action) => {
  return AppReducers(state, action);
};

const pReducer = persistReducer(config, rootReducer);

export default pReducer;
