import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";

export const getLessons = (payload) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getMediaList(payload)
      .then((response) => {
        dispatch({
          type: types.GET_LESSON_REQUEST,
          payload: payload,
        });
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};
