import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu } from "../store/user/actions";
import { ntnUserRoles, userRoles } from "../utils/constants/role";
export const useUser = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [userProfile, setUserProfile] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState(null);
  const [userTypeId, setUserTypeId] = useState(null);
  const [studentId, setUserStudentId] = useState(null);
  const [teacherId, setUseTeacherId] = useState(null);
  const [schoolId, setUserSchoolId] = useState(null);
  const [districtId, setUserDistrictId] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [isAuthenticated, setUserIsAuthenticated] = useState(null);
  const [isTeacher, setIsTeacher] = useState(null);
  const [isStudent, setIsStudent] = useState(null);
  const [isDistrictAdmin, setIsDistrictAdmin] = useState(null);
  const [isSchoolAdmin, setIsSchoolAdmin] = useState(null);
  const [isNtnAdmin, setIsNtnAdmin] = useState(null);

  const [totalNotificationsCount, setTotalNotificationsCount] = useState(null);
  const [userProfileData, setUserProfileData] = useState();
  const [sidebarActiveMenu, setSidebarActiveMenu] = useState(null);

  const handleNotificationsCount = () => {
    if (userData.totalNotificationsCount) {
      setTotalNotificationsCount(userData.totalNotificationsCount);
    }
  };

  useEffect(() => {
    handleNotificationsCount();
  }, []);
  useEffect(() => {
    handleNotificationsCount();
    if (userData.userDetail) {
      setUserProfileData(userData.userDetail);
      setUserProfile(userData.userDetail);
      setUserRole(userData.userDetail.role);
      setIsStudent(userData.userDetail.role === ntnUserRoles.STUDENT);
      setIsTeacher(userData.userDetail.role === ntnUserRoles.TEACHER);
      setIsSchoolAdmin(userData.userDetail.role === ntnUserRoles.SCHOOL_ADMIN);
      setIsDistrictAdmin(
        userData.userDetail.role === ntnUserRoles.DISTRICT_ADMIN
      );
      setIsNtnAdmin(userData.userDetail.role === ntnUserRoles.NTN_ADMIN);
      setUserId(userData.userDetail.id);
      setUserDisplayName(userData.userDetail.displayName);
      setUserTypeId(userData.userDetail.userTypeId);
      setUserStudentId(userData.userDetail.studentId);
      setUseTeacherId(userData.userDetail.teacherId);
      setUserSchoolId(userData.userDetail.schoolId);
      setUserDistrictId(userData.userDetail.districtId);
      setUserStatus(userData.userDetail.status);
      setUserIsAuthenticated(userData.userDetail.isAuthenticated);
      setSidebarActiveMenu(userData?.activeMenu);
    }
  }, [userData]);

  const handleActiveMenu = (request) => {
    dispatch(setActiveMenu(request));
  };

  return {
    totalNotificationsCount,
    userProfileData,
    userProfile,
    userRole,
    userId,
    userDisplayName,
    userTypeId,
    studentId,
    teacherId,
    schoolId,
    districtId,
    userStatus,
    isAuthenticated,
    isTeacher,
    isStudent,
    isDistrictAdmin,
    isNtnAdmin,
    isSchoolAdmin,
    handleActiveMenu,
    sidebarActiveMenu,
  };
};
