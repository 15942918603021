export const ComponentNames = {
  welcome: "Welcome",
  dashboard: "Dashboard",
  login: "Login",
  signUp: "SignUp",
};

export const ComponentUrls = {
  welcome: "",
  dashboard: "dashboard",
  coppaApproval: "/coppa-approval/:confirmGuid",
  login: "login",
  invalidLogin: "invalid-login",
  // msalSignin: "auth/signin/:slug?",
  assessments: "assessments",
  assignedAssessments: "assessments/assigned",
  premadeAssessments: "assessments/premade",
  createdByMeAssessment: "assessments/mine",
  unpublishedAssessments: "/assessments/unpublished",
  sharedAssessments: "assessments/shared",
  submittedAssessments: "assessments/submitted",
  gradedAssessments: "assessments/graded",
  reports: "reports",
  classReports: "reports/class",
  classReportByStudent: "/reports/class/by-student/:classId?",
  assessmentReports: "reports/assessment",
  studentStandardAnalysisReport: "reports/student-standard-analysis-report",
  standardReports: "reports/standard",
  saStandardReportByGrade: "reports/standard/sa/:reportGradeName?",
  daStandardReportByGrade: "reports/standard/da/:reportGradeName?",
  standardReportDetail: "/reports/standard-detail",
  standardStudentReports: "reports/standard/student",
  schoolReports: "reports/school",
  studentReports: "reports/student",
  studentBulkUploadRosters: "rosters/student/bulk-upload",
  baselineReports: "reports/baseline",
  baselineStudentReports: "reports/baseline/student",
  baselineAssessmentReports: "reports/baseline/assessment",
  rosters: "rosters",
  classRosters: "rosters/class",
  classBulkUploadRosters: "rosters/class/bulk-upload",
  classBulkUploadRostersResult: "rosters/class/bulk-upload/result",
  teacherBulkUploadRostersResult: "rosters/teacher/bulk-upload/result",
  studentBulkUploadRostersResult: "rosters/student/bulk-upload/result",
  addClassRosters: "/rosters/class/add",
  editClassRosters: "/rosters/class/edit/:classId",
  reassignClassRosters: "/rosters/class/reassign/:classId",
  editTeacherRosters: "/rosters/teacher/edit/:teacherId",
  transferTeacherRoster: "/rosters/teacher/transfer/:teacherId",
  editStudentRosters: "/rosters/student/edit/:studentId/:districtId?",
  teacherRosters: "rosters/teacher",
  teacherBulkUploadRosters: "rosters/teacher/bulk-upload",
  addTeacherRoster: "rosters/teacher/add",
  studentRosters: "rosters/students",
  addStudentRosters: "rosters/students/add",
  studentRostersByClass: "rosters/class-students/:classId",
  transferStudentRosters:
    "rosters/transfer-student/:studentId/:districtId?/:schoolId?",
  teacherRoster: "roster/teachers/",
  groupRosters: "rosters/groups",
  settings: "settings",
  pdondemand: "pdondemand",
  help: "help",
  createAssessment: "/assessments/create",
  editAssessment: "/assessments/edit/:assessmentId",
  copyAssessment: "/assessments/copy/:assessmentId",
  assessmentDetail: "/assessments/assessment/:assessmentId",
  assignedDetail: "/assessments/assignment/:assignmentId/:assessmentId",
  takeAssessment: "/assessments/take-assessment/:assessmentId",
  viewScore: "/assessments/view-score/:assignmentId",
  attemptTest: "/assessments/attempt-test/:assignmentId/:assessmentId",
  tagManager: "/tag-manager",
  createTag: "/tag-manager/create-a-tag",
  tagEditor: "/tag-manager/tag-editor",
  tagManagerQuestions: "/tag-manager/questions",
  tagManagerCreateQuestion: "/tag-manager/questions/create",
  tagManagerCreateQuestionType: "/tag-manager/questions/type",
  tagManagerEditQuestion: "tag-manager/questions/edit/:questionId",
  tagManagerTTSEditQuestion: "tag-manager/questions/edit-tts/:questionId",
  tagManagerViewQuestion: "tag-manager/questions/view/:questionId",
  tagManagerCopyQuestion: "tag-manager/questions/copy/:questionId",
  tagManagerAssessments: "/tag-manager/assessments",
  tagManagerCreateAssessments: "/tag-manager/assessments/create",
  tagManagerViewAssessments: "tag-manager/assessments/view/:assessmentId",
  tagManagerEditAssessment: "tag-manager/assessments/edit/:assessmentId",
  tagManagerCopyAssessment: "tag-manager/assessments/copy/:assessmentId",
  tagManagerAssessmentQuestions: "/tag-manager/assessments/questions",
  manageBundle: "/bundles",
  createBundle: "/bundles/create",
  editBundle: "bundles/edit/:bundleId",
  viewBundle: "bundles/view/:bundleId",
  bundleQuestions: "bundles/questions",
  bundleAssessments: "bundles/assessments",
  manageLicense: "license",
  manageLicensePreview: "license/preview/:licenseId?",
  createLicense: "license/create",
  createLicenseContent: "license/create/content",
  createLicenseTerm: "license/create/term",
  createLicensePreview: "license/create/preview",
  manageAccessCode: "license/access-code",
  generateAccessCode: "license/generate-access-code",
  organizationsDistrict: "organization/district",
  organizationsDistrictAdmin: "organization/district-admin",
  organizationsSchool: "organization/school",
  orgCreateSchool: "organization/school/create",
  orgEditSchool: "organization/school/edit/:schoolId",
  organizationsSchoolAdmin: "organization/school-admin",
  orgCreateSchoolAdmin: "organization/school-admin/create",
  orgEditSchoolAdmin: "organization/school-admin/edit/:userId",
  organizationCreateDistrict: "organization/district/create",
  organizationViewDistrict: "organization/district/view/:districtId",
  organizationEditDistrict: "organization/district/edit/:districtId",
  editLicenseTerm: "license/edit/:term",
  editLicense: "license/edit/:licenseId",
  editLicenseContent: "license/edit/content/:licenseId",
  editLicenseTerm: "license/edit/term/:licenseId",
  editLicensePreview: "license/edit/preview/:licenseId",
  orgCreateDistrictAdmin: "organization/district-admin/create",
  orgViewDistrictAdmin: "organization/district-admin/view/:userId",
  orgEditDistrictAdmin: "organization/district-admin/edit/:userId",
  dataArchive: "data-archive",
  promoteStudents: "promote-students/:schoolId",
};
