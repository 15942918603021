export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

export const UPDATEPROFILE_SUCCESS = "UPDATEPROFILE_SUCCESS";
export const UPDATEPROFILE_ERROR = "UPDATEPROFILE_ERROR";

export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";
export const ACCOUNT_SESSION = "ACCOUNT_SESSION";
export const CLEAR_USER_ACCOUNT = "CLEAR_USER_ACCOUNT";
export const CONSTANT_ACTIVE_MENU = "CONSTANT_ACTIVE_MENU";
