import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import { IoDocumentText } from "react-icons/io5";

import React from "react";
import { ComponentUrls } from "../component-names";
export const Assigned = React.lazy(() =>
  import("../../../pages/Assessments/Student/Assigned")
);
export const Submitted = React.lazy(() =>
  import("../../../pages/Assessments/Submitted")
);
export const Graded = React.lazy(() =>
  import("../../../pages/Assessments/Graded")
);
export const Archived = React.lazy(() =>
  import("../../../pages/Assessments/Archived")
);
export const Settings = React.lazy(() => import("../../../pages/Settings"));
export const Help = React.lazy(() => import("../../../pages/Help"));
export const StudentNavbar = [
  {
    path: "/",
    state: {
      type: "assignment",
    },
    component: Assigned,
  },
  {
    title: "Assigned Assessments",
    path: `/${ComponentUrls.assignedAssessments}`,
    state: {
      type: "assignment",
    },
    childTags: ["assignment", "assigned-assessments", "assigned-assessment"],
    component: Assigned,
    icons: <BsIcons.BsGrid3X3Gap />,
    className: "assessment-menu",
  },
  {
    title: "Submitted Assessments",
    path: `/${ComponentUrls.submittedAssessments}`,
    icons: <IoDocumentText />,
    component: Submitted,
    state: {
      type: "submitted-assessments",
    },
    childTags: ["submitted-assessments"],
  },
  {
    title: "Graded Assessments",
    path: `/${ComponentUrls.gradedAssessments}`,
    icons: <AiIcons.AiOutlineTrophy />,
    component: Graded,
    state: {
      type: "graded-assessments",
    },
    childTags: ["graded-assessments", "graded-assessment"],
  },
  {
    title: "Settings",
    path: `/${ComponentUrls.settings}`,
    icons: <IoIcons.IoMdSettings />,
    component: Settings,
  },
  {
    title: "Help",
    path: `/${ComponentUrls.help}`,
    icons: <IoIcons.IoMdHelpCircleOutline />,
    component: Help,
  },
];
