// Service to check authentication for user and to signOut
const Auth = {
  signOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("waiting_for_token");
    localStorage.removeItem("token_expires_in");
  },
  isAuth() {
    return localStorage.getItem("token");
  },
  setToken(token) {
    try {
      localStorage.setItem("token", JSON.stringify(token));
    } catch (error) {
      console.log(error);
    }
  },
  setTokenExpiresIn(token) {
    try {
      localStorage.setItem("token_expires_in", JSON.stringify(token));
    } catch (error) {
      console.log(error);
    }
  },
  getTokenExpiresIn() {
    try {
      const token_expires_in = localStorage.getItem("token_expires_in");
      if (!token_expires_in) {
        return "";
      }
      return JSON.parse(token_expires_in);
    } catch (error) {
      console.log(error);
    }
  },
  getToken() {
    try {
      const authTokenString = localStorage.getItem("token");
      if (!authTokenString) {
        return "";
      }
      return JSON.parse(authTokenString);
    } catch (error) {
      console.log(error);
    }
  },
  setTokenWaiting() {
    try {
      localStorage.setItem("waiting_for_token", true);
    } catch (error) {
      console.log(error);
    }
  },
  getTokenWaiting() {
    try {
      const waitingForToken = localStorage.getItem("waiting_for_token");
      if (!waitingForToken) {
        return false;
      }
      return waitingForToken;
    } catch (error) {
      console.log(error);
    }
  },
  removeTokenWaiting() {
    localStorage.removeItem("waiting_for_token");
  },
  clearStorage() {
    localStorage.clear();
  },
};
export default Auth;
