export const SET_TAG_QUESTIONS = "SET_TAG_QUESTIONS";
export const SET_TAG_GRADE_LIST = "SET_TAG_GRADE_LIST";
export const SET_TAG_STRAND_LIST = "SET_TAG_STRAND_LIST";
export const SET_TAG_STANDARD_LIST = "SET_TAG_STANDARD_LIST";
export const SET_TAG_SKILL_LIST = "SET_TAG_SKILL_LIST";
export const SET_TAG_FILTER_LIST = "SET_TAG_FILTER_LIST";
export const SET_TAG_ASSESSMENT_LIST = "SET_TAG_ASSESSMENT_LIST";
export const SET_TAG_CREATE_ASSESSMENT_REQUEST =
  "SET_TAG_CREATE_ASSESSMENT_REQUEST";
export const SET_TAG_QUESTION_SAVED_FILTER = "SET_TAG_QUESTION_SAVED_FILTER";
