//import React from "react";
import * as types from "./actionTypes";
import HttpApis from "../../services/HttpApis";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { getSystemAlertsAction } from "../alert";
export const assessmentAction = (request) => {
  return async (dispatch) => {
    //dispatch(apiLoadingStart());
    return await HttpApis.getAssessment(request)
      .then((response) => {
        //dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        // console.log("error:", error);
        //dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const premadeAssessmentAction = (request) => {
  return async (dispatch) => {
    return await HttpApis.getPremadeAssessment(request)
      .then((response) => {
        try {
          dispatch(storeAssessmentList(response.data?.assessments));
        } catch (error) {
          console.error("error", error);
        }
        return response;
      })
      .catch((error) => {
        console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const myAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getMyAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        try {
          dispatch(storeAssessmentList(response.data?.assessments));
        } catch (error) {
          console.error("error", error);
        }

        return response;
      })
      .catch((error) => {
        // console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getShareTeachersAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getShareTeachers(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        // console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const saveShareTeachersAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.saveShareTeachers(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        // console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getShareListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getShareList(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        // console.log("error:", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const acceptAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.acceptAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        dispatch(getSystemAlertsAction());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getAssessmentDetailAction = (request) => {
  return async (dispatch) => {
    dispatch({
      type: types.SHOW_LOADER,
    });

    return await HttpApis.getAssessmentDetail(request)
      .then((response) => {
        const assessment = response.data?.assessment;
        assessment.assessmentQuestions = response.data?.assessmentQuestions;
        dispatch(storeAssessmentDetail(assessment));
        dispatch({
          type: types.HIDE_LOADER,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: types.HIDE_LOADER,
        });
        return null;
      });
  };
};

export const deleteSharedAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.deleteSharedAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const questionFiltersListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.questionFiltersList(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const questionsListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.questionsList(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const createAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.createAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const deleteAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.deleteAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const cancelAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.cancelAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getStudentAssignedAssessmentsAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getStudentAssignedAssessments(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

// print assessment
export const getDocumentFiltersAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.documentFilters(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getDocumentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getDocument(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const submitAnswerImageAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.submitAnswerImage(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const getMyAssessmentFilterAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.getMyAssessmentFilter(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const autoGradeAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.AutoGrade(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const unassignAssessmentAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.unassignAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};

export const createFakeAssessmentRequestAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    return await HttpApis.createFakeAssessment(request)
      .then((response) => {
        dispatch(apiLoadingStop());
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        dispatch(apiLoadingStop());
        return null;
      });
  };
};
/// PURE REDUX ACTIONS

export const getAssessmentByIdAction = (id) => {
  return (dispatch) => {
    return dispatch({
      type: types.GET_ASSESSMENT_BY_ID,
      id: id,
    });
  };
};

export const storeAssessmentList = (payload) => ({
  type: types.GET_ASSESSMENT_SUCCESS,
  payload: payload,
});

export const storeAssessmentDetail = (payload) => ({
  type: types.GET_ASSESSMENT_DETAIL,
  payload: payload,
});

export const saveCreateAssessmentRequest = (payload) => ({
  type: types.CREATE_ASSESSMENT_REQUEST,
  payload: payload,
});

export const removeCreateAssessmentRequest = (payload) => ({
  type: types.REMOVE_CREATE_ASSESSMENT_REQUEST,
});

export const createAssessmentRequestStep = (payload) => ({
  type: types.CREATE_ASSESSMENT_REQUEST_STEP,
  payload: payload,
});

export const hideAssessmentLoader = () => ({
  type: types.HIDE_LOADER,
});
