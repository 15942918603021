import React from 'react';
import "./style.scss";
import { getInitials } from '../../utils/Common';
const UserInitials = ({value="John Doglle"}) =>
{
    return (
        <div className='user-initials-container'>
            <span className='user-initials'>{getInitials(value)}</span>
            <span className='user-fullname desktop-only'>{value}</span>
        </div>
    )
}
export default UserInitials;