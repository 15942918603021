import React from "react";
import { Form } from "react-bootstrap";
export default function TextArea(props) {
  return (
    <>
      <Form.Group className={`mb-0 ${props?.className}`}>
        {props?.label ? <Form.Label>{props.label}</Form.Label> : null}
        <Form.Control
          required={props?.required}
          as="textarea"
          rows={props?.rows}
          defaultValue={props?.value}
          onChange={(e) => props?.onChange(e, props)}
          onBlur={(e) => (props.onBlur ? props?.onBlur(e, props) : () => {})}
          placeholder={props?.placeHolder}
        />
      </Form.Group>
    </>
  );
}
